<template>
    <div id="evaluation-results">

        <Header />

        <div class="banner-top">
            <div>
                <Logo />
            </div>
        </div>

        <!-- Score -->
        <div id="score" :class="mode === 'validation' ? (userScore.percent >= scoreToAchieve ? 'good' : 'bad') : ''">
            <div>
                <div>
                    <div>
                        <p v-if="mode === 'validation'" class="status">
                            {{ userScore.percent >= scoreToAchieve ? $t("message.quiz_success", evaluation.lang) : $t("message.quiz_fail", evaluation.lang) }}
                        </p>
                        <span class="score">{{ userScore.percent ? userScore.percent : 0 }}%</span>
                    </div>
                </div>

                <div>
                    <p v-if="mode === 'validation'" v-html="userScore.percent >= scoreToAchieve ? $t('message.msg_success', evaluation.lang) : $t('message.msg_fail', evaluation.lang)"></p>
                    <p v-if="mode === 'training'" v-html="$t('message.msg_success_training', evaluation.lang)"></p>

                    <div>
                        <button type="button" @click="reset">{{ $t("message.create_new_quiz") }}</button>
                        <button type="button" @click="reloadQuestions">{{ $t("message.redo") }}</button>
                        <button type="button" @click="newQuestions" v-if="this.evaluation.isGenerated" v-html="$t('message.new_quiz_from_the_same_file')"></button>
                        <button type="button" @click="share" :disabled="!$store.state.app.shareURL.url">{{ $t("message.share") }}</button>
                    </div>
                </div>
            </div>
            <div>
                <img src="@/assets/good.png" alt="" v-if="mode === 'training' || (mode === 'validation' && userScore.percent >= scoreToAchieve)" />
                <img src="@/assets/bad.png" alt="" v-if="mode === 'validation' && userScore.percent < scoreToAchieve" />
            </div>
        </div>
        <!-- End : Score -->

        <!-- Correction de l'évaluation -->
        <div v-for="(q, idxQ) in questions" :key="idxQ" class="item">
            <!-- Header Question -->
            <div class="question">
                <div>
                    <span class="title" v-if="idxQ === 0">{{ $t("message.correction", evaluation.lang) }}</span>
                    <span class="question-idx">{{ $t("message.question", evaluation.lang) }} {{ idxQ + 1 }}/{{ numberOfQuestions }}</span>
                    <span class="question-title">{{ q.question }}</span>
                </div>
            </div>
            <!-- End : Header Question -->

            <!-- Propositions QCM -->
            <template v-if="q.type === 'QCM'">
                <div class="propositions">
                    <div
                        v-for="(p, idxP) in q.propositions"
                        :key="idxP"
                        :class="{
                            good: q.answers.indexOf(p) !== -1 && userResponses[idxQ] !== undefined && userResponses[idxQ].responses.indexOf(p) !== -1,
                            bad: q.answers.indexOf(p) === -1 && userResponses[idxQ] !== undefined && userResponses[idxQ].responses.indexOf(p) !== -1,
                        }"
                    >
                        <span class="caret caret-right" v-if="q.answers.indexOf(p) !== -1"><FontAwesomeIcon icon="caret-right" /></span>
                        <div>
                            <input type="checkbox" :checked="q.answers.indexOf(p) !== -1" disabled />
                            <label>
                                {{ p }}
                                <span class="check" v-if="q.answers.indexOf(p) === -1 && userResponses[idxQ] !== undefined && userResponses[idxQ].responses.indexOf(p) !== -1">
                                    <FontAwesomeIcon icon="xmark" />
                                </span>
                                <span class="check" v-if="q.answers.indexOf(p) !== -1">
                                    <FontAwesomeIcon icon="check" />
                                </span>
                            </label>
                        </div>
                        <span class="caret caret-left" v-if="q.answers.indexOf(p) !== -1"><FontAwesomeIcon icon="caret-left" /></span>
                    </div>
                </div>
            </template>
            <!-- End : Propositions QCM -->

            <!-- Propositions QCU -->
            <template v-if="q.type === 'QCU'">
                <div class="propositions">
                    <div
                        v-for="(p, idxP) in q.propositions"
                        :key="idxP"
                        :class="{
                            good: q.answers.indexOf(p) !== -1 && userResponses[idxQ] !== undefined && userResponses[idxQ].responses.indexOf(p) !== -1,
                            bad: q.answers.indexOf(p) === -1 && userResponses[idxQ] !== undefined && userResponses[idxQ].responses.indexOf(p) !== -1,
                        }"
                    >
                        <span class="caret caret-right" v-if="q.answers.indexOf(p) !== -1"><FontAwesomeIcon icon="caret-right" /></span>
                        <div>
                            <input type="radio" :checked="q.answers.indexOf(p) !== -1" disabled />
                            <label>
                                {{ p }}
                                <span class="check" v-if="q.answers.indexOf(p) === -1 && userResponses[idxQ] !== undefined && userResponses[idxQ].responses.indexOf(p) !== -1">
                                    <FontAwesomeIcon icon="xmark" />
                                </span>
                                <span class="check" v-if="q.answers.indexOf(p) !== -1">
                                    <FontAwesomeIcon icon="check" />
                                </span>
                            </label>
                        </div>
                        <span class="caret caret-left" v-if="q.answers.indexOf(p) !== -1"><FontAwesomeIcon icon="caret-left" /></span>
                    </div>
                </div>
            </template>
            <!-- End : Propositions QCU -->

            <!-- Info -->
            <div v-if="q.info" class="info">
                <span class="result">{{ $t("message.answer", evaluation.lang) }}</span>
                <span class="content">{{ q.info }}</span>
            </div>
            <!-- End : Info -->
        </div>
    </div>
</template>

<script>
import axios from "axios";
import Header from "../components/Header.vue";
import Logo from "../components/SVG/Logo.vue";
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { faCheck, faXmark, faCaretRight, faCaretLeft } from "@fortawesome/free-solid-svg-icons";
library.add(faCheck, faXmark, faCaretRight, faCaretLeft);

export default {
    name: "EvaluationResults",
    data: () => {},
    components: {
        FontAwesomeIcon,
        Logo,
        Header,
    },
    computed: {
        // Questions
        questions: function () {
            return this.$store.state.app.evaluation.questions;
        },
        // Mode (entrainement ou validation)
        mode: function () {
            return this.$store.state.app.evaluation.mode;
        },
        // Réponses utilisateur
        userResponses: function () {
            return this.$store.state.app.userResponses;
        },
        // Nombre de questions
        numberOfQuestions: function () {
            return this.$store.state.app.evaluation.numberOfQuestions;
        },
        // Score à atteindre
        scoreToAchieve: function () {
            return this.$store.state.app.evaluation.scoreToAchieve;
        },
        // Score utilisateur
        userScore: function () {
            return this.$store.state.app.userScore;
        },
        // Evaluation
        evaluation: function () {
            return this.$store.state.app.evaluation;
        },
        // User
        user: function () {
            return this.$store.state.app.user;
        },
    },
    beforeMount() {
        if (!this.$store.state.app.shareURL.url) {
            let data = { ...this.evaluation, user: this.user["@id"] };

            // Sauvegarde de l'évaluation en BDD
            axios({
                method: "post",
                url: `${this.$store.state.app.api_platform_url}/api/evaluations`,
                data: data,
            }).then(
                (response) => {
                    if (response && response.data) {
                        // Mise à jour de l'évaluation
                        this.$store.dispatch("app/setEvaluation", { id: response.data.id, urlKey: response.data.urlKey });
                        // Mise à jour de l'URL de partage
                        this.$store.dispatch("app/setShareURL", { url: `${this.$store.state.app.base_url}/evaluation/${this.evaluation.id}/${this.evaluation.urlKey}` });
                    }
                },
                (error) => {
                    this.error = true;
                    console.log("error >> ", error);
                }
            );
        }
    },
    methods: {
        /**
         * Partager une évaluation
         */
        share() {
            this.$store.dispatch("app/setShareURL", { modal: true });
        },
        /**
         * Recharger l'évaluation avec de nouvelles questions
         */
        newQuestions() {
            // Suppression de l'URL de partage
            this.$store.dispatch("app/setShareURL", { url: null });
            delete this.evaluation.id;
            delete this.evaluation.urlKey;
            this.$store.dispatch("app/setEvaluation", this.evaluation);

            // Remise à zéro des réponses utilisateurs
            this.$store.dispatch("app/resetUserReponses");

            // Retour à la première page parametrage
            this.$store.dispatch("app/setPage", { current: "EVALUATION_SETTINGS" });

            // Recherche de nouvelles questions
            this.$store.dispatch("app/setNewQuestions", true);
        },
        /**
         * Recharger l'évaluation avec les mêmes questions
         */
        reloadQuestions() {
            // Remise à zéro des réponses utilisateurs
            this.$store.dispatch("app/resetUserReponses");

            // Retour à la page de l'évaluation
            this.$store.dispatch("app/setPage", { current: "EVALUATION" });
        },
        /**
         * Retour à la page de configuration
         */
        reset() {
            this.$store.dispatch("app/resetApp");
        },
    },
};
</script>

<style scoped lang="scss">
#evaluation-results {
    .banner-top {
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
        align-items: center;
        padding: 1rem 2rem;
        box-sizing: border-box;
        border-bottom: 3px solid $base-color;

        .logo-svg {
            width: 300px;
            display: block;

            @media (max-width: $xl) {
                width: 250px;
            }
            @media (max-width: $lg) {
                width: 200px;
            }
        }

        @media (max-width: $lg) {
            border-bottom: 2px solid $base-color;
            padding: 1rem;
        }
        @media (max-width: $md) {
            flex-flow: column;
            align-items: flex-start;

            a {
                margin-top: 1rem;
            }
        }
    }

    #score {
        display: flex;
        flex-flow: row nowrap;
        align-items: stretch;
        justify-content: space-between;
        border-bottom: 3px solid $base-color;

        @media (max-width: $lg) {
            border-bottom: 2px solid $base-color;
        }

        > div {
            &:first-child {
                flex: 1;
                display: flex;
                flex-flow: column;

                @media (max-width: $xxl) {
                    flex: 2;
                }
                @media (max-width: $lg) {
                    flex: 0 0 100%;
                }

                > div {
                    &:first-child {
                        &:after {
                            content: "";
                            display: block;
                            width: 20%;
                            border-bottom: 3px solid $base-color;

                            @media (max-width: $lg) {
                                border-bottom: 2px solid $base-color;
                            }
                        }

                        > div {
                            padding: 2rem;
                            box-sizing: border-box;

                            @media (max-width: $lg) {
                                padding: 1rem;
                            }

                            .status {
                                text-transform: uppercase;
                                font-weight: 800;
                                font-size: 2rem;
                                margin: 0;

                                @media (max-width: $xl) {
                                    font-size: 1.8rem;
                                }
                                @media (max-width: $lg) {
                                    font-size: 1.6rem;
                                }
                                @media (max-width: $md) {
                                    font-size: 1.4rem;
                                }
                                @media (max-width: $sm) {
                                    font-size: 1.2em;
                                }
                            }

                            .score {
                                text-transform: uppercase;
                                font-weight: 800;
                                font-size: 3rem;

                                @media (max-width: $xl) {
                                    font-size: 2.5rem;
                                }
                                @media (max-width: $lg) {
                                    font-size: 2.2rem;
                                }
                                @media (max-width: $md) {
                                    font-size: 2rem;
                                }
                                @media (max-width: $sm) {
                                    font-size: 1.6rem;
                                }
                            }
                        }
                    }

                    &:last-child {
                        padding: 2rem;
                        box-sizing: border-box;
                        flex: 1;
                        display: flex;
                        flex-flow: column;
                        justify-content: center;

                        @media (max-width: $lg) {
                            padding: 1rem;
                        }

                        p {
                            font-weight: 600;
                            margin: 0 0 1rem 0;
                            font-size: 1.4rem;

                            @media (max-width: $xxl) {
                                font-size: 1.2rem;
                            }
                            @media (max-width: $lg) {
                                font-size: 1rem;
                            }
                        }

                        > div {
                            display: flex;
                            flex-flow: row wrap;

                            @media (max-width: $sm) {
                                flex-flow: column;
                            }
                        }
                    }
                }
            }

            &:last-child {
                margin: 2rem;
                position: relative;
                flex: 1;

                @media (max-width: $lg) {
                    margin: 1rem;
                }
                @media (max-width: $lg) {
                    flex: 0;
                }
                @media (max-width: $sm) {
                    margin: 0.5rem 1rem;
                }

                img {
                    max-height: 100%;
                    display: block;
                    position: absolute;
                    top: 0;
                    right: 0;

                    @media (max-width: $xl) {
                        max-width: 250px;
                    }
                    @media (max-width: $lg) {
                        max-width: 150px;
                        right: 2rem;
                    }
                    @media (max-width: $md) {
                        max-width: 100px;
                    }
                    @media (max-width: $sm) {
                        max-width: 60px;
                    }
                }
            }
        }

        &.good {
            .score {
                color: #009640;
            }
        }

        &.bad {
            .score {
                color: #ff0000;
            }
        }

        .button,
        button {
            margin-right: 1rem;
            margin-bottom: 1rem;
            box-sizing: border-box;

            &:last-child {
                margin-right: 0;
            }

            @media (max-width: $sm) {
                margin-right: 0;
                margin-bottom: 0.4rem;
                padding: 0.5rem 0;
            }
        }
    }

    .item {
        &:after {
            content: "";
            display: block;
            border-bottom: 3px solid $base-color;
            padding-bottom: 2rem;

            @media (max-width: $lg) {
                border-bottom: 2px solid $base-color;
            }
        }

        &:last-child {
            &:after {
                content: none;
            }
        }
    }

    .question {
        &:after {
            content: "";
            display: block;
            width: 20%;
            border-bottom: 3px solid $base-color;

            @media (max-width: $lg) {
                border-bottom: 2px solid $base-color;
            }
        }

        > div {
            padding: 2rem;
            box-sizing: border-box;
            display: flex;
            flex-flow: column;

            @media (max-width: $lg) {
                padding: 1rem;
            }

            .title {
                text-transform: uppercase;
                font-weight: 800;
                font-size: 2rem;
                margin-bottom: 0.5rem;
                color: $base-color;

                @media (max-width: $xxl) {
                    font-size: 1.4rem;
                }

                @media (max-width: $lg) {
                    font-size: 1.2rem;
                }
            }

            .question-idx {
                text-transform: uppercase;
                font-weight: 800;
                font-size: 1.4rem;
                margin-bottom: 1rem;

                @media (max-width: $xxl) {
                    font-size: 1.2rem;
                }
                @media (max-width: $lg) {
                    font-size: 1rem;
                    margin-bottom: 0.5rem;
                }
            }

            .question-title {
                font-weight: 600;
                font-size: 1.4rem;

                @media (max-width: $xxl) {
                    font-size: 1.2rem;
                }
                @media (max-width: $lg) {
                    font-size: 1rem;
                }
            }
        }
    }

    .propositions {
        > div {
            display: flex;
            flex-flow: row nowrap;
            align-items: center;
            justify-content: center;
            margin-bottom: 0.5rem;
            position: relative;
            overflow: hidden;

            .caret {
                font-size: 2rem;
                position: absolute;
                color: #009640;

                @media (max-width: $lg) {
                    font-size: 1.8rem;
                }

                &.caret-right {
                    left: -7px;
                }

                &.caret-left {
                    right: -7px;
                }
            }

            > div {
                padding: 0 2rem;
                box-sizing: border-box;
                flex: 1;

                @media (max-width: $lg) {
                    padding: 0 1rem;
                }
            }

            &:first-child {
                margin-top: 2rem;
            }

            &:last-child {
                margin-bottom: 2rem;

                @media (max-width: $md) {
                    margin-bottom: 1rem;
                }
            }

            label {
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 1.4rem;
                padding: 0.5rem 1rem;
                box-sizing: border-box;
                transition: all 0.3s;
                position: relative;
                background-color: transparent;
                border: 4px solid #e5e5e5;
                color: #a5a6a6;
                text-align: center;

                @media (max-width: $lg) {
                    border: 2px solid $base-color;
                }

                .check {
                    position: absolute;
                    right: 1rem;
                    border-radius: 50px;
                    height: 40px;
                    width: 40px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    color: #fff;
                    box-shadow: 0 0 4px 0px rgb(0 0 0 / 28%);

                    @media (max-width: $lg) {
                        height: 30px;
                        width: 30px;
                    }
                    @media (max-width: $sm) {
                        height: 25px;
                        width: 25px;
                    }
                }

                @media (max-width: $xxl) {
                    font-size: 1.2rem;
                }
                @media (max-width: $lg) {
                    font-size: 1rem;
                }
            }

            input {
                opacity: 0;
                position: absolute;
                left: -99999px;

                &:checked + label {
                    background-color: $base-color;
                    color: #fff;
                }
            }

            &.good {
                input + label {
                    border: 4px solid #009640;
                    background-color: #009640;
                    color: #fff;

                    .check {
                        background: rgb(150, 223, 140);
                        background: linear-gradient(127deg, rgba(150, 223, 140, 1) 0%, rgba(22, 174, 68, 1) 100%);
                    }
                }

                @media (max-width: $lg) {
                    input + label {
                        border: 2px solid #009640;
                    }
                }
            }

            &.bad {
                input + label {
                    border: 4px solid #e30613;
                    background-color: #e30613;
                    color: #fff;

                    .check {
                        background: rgb(251, 91, 85);
                        background: linear-gradient(127deg, rgba(251, 91, 85, 1) 0%, rgba(231, 19, 29, 1) 100%);
                    }
                }

                @media (max-width: $lg) {
                    input + label {
                        border: 2px solid #e30613;
                    }
                }
            }

            &:not(.bad):not(.good) {
                input:checked + label {
                    background-color: transparent;
                    border: 4px solid #009640;
                    color: #009640;

                    .check {
                        background: rgb(150, 223, 140);
                        background: linear-gradient(127deg, rgba(150, 223, 140, 1) 0%, rgba(22, 174, 68, 1) 100%);
                    }
                }

                @media (max-width: $lg) {
                    input:checked + label {
                        border: 2px solid #009640;
                    }
                }
            }
        }
    }

    .info {
        padding: 0 2rem;
        box-sizing: border-box;
        display: flex;
        flex-flow: column;

        @media (max-width: $lg) {
            padding: 0 1rem;
        }

        .result {
            text-transform: uppercase;
            font-size: 1.4rem;
            font-weight: 800;
            color: $base-color;

            @media (max-width: $xxl) {
                font-size: 1.2rem;
            }
            @media (max-width: $lg) {
                font-size: 1rem;
            }
        }

        .content {
            font-size: 1.4rem;

            @media (max-width: $xxl) {
                font-size: 1.2rem;
            }
            @media (max-width: $lg) {
                font-size: 1rem;
            }
        }
    }
}
</style>
