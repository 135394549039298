<template>
    <div class="overlay" v-if="$store.state.app.moderation.modal">
        <div id="modal-moderation">
            <div>
                <div>
                    <FontAwesomeIcon icon="exclamation-triangle" />
                </div>
                <div v-html="$t('message.moderation', 'fr')"></div>
                <div>
                    <button @click="cancel">{{ $t("message.close", "fr") }}</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { faXmark, faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
library.add(faXmark, faExclamationTriangle);

export default {
    name: "ModalModeration",
    data: () => {
        return {
            url: null,
            copiedText: null,
        };
    },
    components: {
        FontAwesomeIcon,
    },
    methods: {
        cancel() {
            this.$store.dispatch("app/setModeration", { modal: false });
        },
    },
};
</script>

<style scoped lang="scss">
#modal-moderation {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-flow: column;
    max-width: 600px;
    margin: auto;

    @media (max-width: $xl) {
        width: 500px;
    }
    @media (max-width: $sm) {
        width: 100%;
    }

    > div {
        background-color: #fff;
        background: no-repeat url(@/assets/bg.jpg) center / cover;
        position: relative;

        > div {
            text-align: center;

            &:first-child {
                padding: 1rem 2rem;
                box-sizing: border-box;
                background-color: #ff6b46;

                @media (max-width: $lg) {
                    padding: 1rem;
                }

                svg {
                    color: #fff;
                    font-size: 4rem;

                    @media (max-width: $md) {
                        font-size: 3rem;
                    }
                }
            }

            &:nth-child(2) {
                padding: 2rem 2rem 0;
                box-sizing: border-box;
                font-weight: 500;

                @media (max-width: $lg) {
                    padding: 1rem 1rem 0;
                }
            }

            &:last-child {
                padding: 2rem;
                box-sizing: border-box;

                @media (max-width: $lg) {
                    padding: 1rem;
                }

                button {
                    margin: auto;
                }
            }
        }
    }
}
</style>
