import { createRouter, createWebHashHistory } from "vue-router";
import SettingsView from "./views/SettingsView.vue";
import EvaluationView from "./views/EvaluationView.vue";
import ErrorView from "./views/ErrorView.vue";

const routes = [
    {
        path: "/",
        name: "SettingsView",
        component: SettingsView,
    },
    {
        path: "/evaluation/:id?/:key?",
        name: "EvaluationView",
        component: EvaluationView,
    },
    {
        path: "/error",
        name: "ErrorView",
        component: ErrorView,
    },
];

export default createRouter({
    history: createWebHashHistory(),
    routes,
});