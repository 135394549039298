import { createApp } from "vue";
import { createI18n } from 'vue-i18n'
import App from "./App.vue";
import store from "./store";
import router from "./router";
import messages from "./i18n/messages";

const i18n = createI18n({
    locale: "fr",
    messages,
});

createApp(App).use(i18n).use(router).use(store).mount("#app");
