<template>
    <div id="terms-service">
        <span @click="cancel" class="close">
            <FontAwesomeIcon icon="xmark" />
        </span>
        <div v-html="$t('message.terms_service')"></div>
    </div>
</template>

<script>
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
library.add(faXmark);

export default {
    name: "TermsService",
    data: () => {
        return {
            content: null,
        };
    },
    components: {
        FontAwesomeIcon,
    },
    watch: {
        "$store.state.app.page.current": {
            handler() {},
        },
    },
    mounted() {
        let _this = this;
        let linkPrivacyPolicy = this.$el.querySelector(".link-privacy-policy");
        let linkCancel = this.$el.querySelector(".link-cancel");

        if (linkPrivacyPolicy)
            linkPrivacyPolicy.addEventListener("click", function () {
                _this.$store.dispatch("app/setPage", { current: "PRIVACY_POLICY", savePrev: false });
            });

        if (linkCancel)
            linkCancel.addEventListener("click", function () {
                _this.cancel();
            });
    },
    methods: {
        cancel() {
            this.$store.dispatch("app/setPage", { current: this.$store.state.app.page.prev });
        },
    },
};
</script>

<style lang="scss">
#terms-service {
    position: relative;
    padding: 2rem;
    
    @media (max-width: $lg) {
        padding: 1rem;
    }

    .close {
        background-color: #000;
        color: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 40px;
        height: 40px;
        font-size: 2rem;
        position: absolute;
        right: 1rem;
        top: 1rem;
        cursor: pointer;

        @media (max-width: $lg) {
            width: 35px;
            height: 35px;
            font-size: 1.6rem;
        }
        @media (max-width: $md) {
            width: 30px;
            height: 30px;
            font-size: 1.2rem;
        }
    }

    h1 {
        color: #ff6b46;

        @media (max-width: $lg) {
            font-size: 1.6rem;
        }
        @media (max-width: $sm) {
            font-size: 1.4rem;
        }
    }

    > ol {
        padding: 0;
    }

    > div {
        ol {
            counter-reset: item;

            @media (max-width: $lg) {
                padding-left: 2rem;
            }
            @media (max-width: $sm) {
                padding-left: 1.4rem;
            }

            > li {
                display: block;
                font-weight: 600;
                margin: 1rem 0;

                > p {
                    font-weight: 400;
                    margin: 0;

                    span,
                    a {
                        color: #ff6b46;
                        text-decoration: none;
                        cursor: pointer;
                    }
                }

                ul {
                    font-weight: 400;
                    list-style-type: circle;
                }
            }

            > li:before {
                content: counters(item, ".") " ";
                counter-increment: item;
            }
        }

        > ol {
            padding: 0;
        }
    }
}
</style>
