<template>
    <div>
        <h1>Erreur lors de la récupration de l'évaluation</h1>
    </div>
</template>

<script>
export default {
    name: "ErrorView",
};
</script>

<style scoped lang="scss"></style>
