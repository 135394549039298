<template>
    <div id="loadergame" v-if="isVisible">
        <ul v-show="!displayResults" class="foreground">
            <li v-for="n in numberTargetsFront" :key="n">
                <input type="checkbox" name="" :id="'target' + n" :class="'target target' + n" v-model="targetsChecked[n]" @click="updateTargetsChecked" />
                <label :for="'target' + n">
                    <img :src="require('@/assets/loading/0' + (Math.floor(Math.random() * 6) + 1) + '.png')" alt="" />
                </label>
            </li>
        </ul>

        <ul v-show="!displayResults" class="background">
            <li v-for="n in numberTargetsBack" :key="n">
                <label :class="'label' + n">
                    <img :src="require('@/assets/loading/0' + (Math.floor(Math.random() * 6) + 1) + '.png')" alt="" />
                </label>
            </li>
        </ul>

        <div class="content">
            <div class="count">{{ Object.keys(targetsChecked).length }}</div>
            <div v-show="!displayResults">
                <span>{{ $t("message.game_capture") }}</span>
                <span class="generation-progress">{{ $t("message.game_generation_quiz") }}</span>
            </div>
            <div v-show="displayResults">
                <span>{{ $t("message.game_over") }}</span>
                <span>{{ $t("message.game_quiz_ready") }}</span>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "GameLoader",
    data: () => {
        return {
            isVisible: false,
            displayResults: false,
            numberTargetsFront: 15,
            numberTargetsBack: 8,
            targetsChecked: {},
        };
    },
    watch: {
        "$store.state.app.loaderGame": {
            handler(status) {
                if (!status) this.closeLoader();
                else {
                    this.displayResults = false;
                    this.isVisible = status;
                    this.targetsChecked = {};
                }
            },
        },
    },
    methods: {
        closeLoader() {
            this.displayResults = true;

            setTimeout(() => {
                this.isVisible = false;
            }, 3000);
        },
    },
};
</script>

<style scoped lang="scss">
$numberTargetsFront: 15;
$numberTargetsBack: 8;

#loadergame {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    height: 100vh;
    background: #fff;
    z-index: 999;
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    padding: 2rem;
    box-sizing: border-box;
    overflow: hidden;
    user-select: none;

    .content {
        z-index: 2;
        background-color: rgba(255, 255, 255, 0.6);
        padding: 1rem;
        box-sizing: border-box;
        text-align: center;

        @media (max-width: $lg) {
            padding: 0.5rem;
        }

        > div:not(.count) {
            display: flex;
            flex-flow: column;

            span {
                text-transform: uppercase;

                &:first-child {
                    font-weight: 800;
                    color: #ff6b46;
                    font-size: 2.4rem;

                    @media (max-width: $xxl) {
                        font-size: 2.2rem;
                    }
                    @media (max-width: $xl) {
                        font-size: 2rem;
                    }
                    @media (max-width: $lg) {
                        font-size: 1.8rem;
                    }
                    @media (max-width: $md) {
                        font-size: 1.4rem;
                    }
                    @media (max-width: $sm) {
                        font-size: 1.2rem;
                    }
                }

                &:last-child {
                    font-weight: 400;
                    font-size: 1.6rem;

                    @media (max-width: $xxl) {
                        font-size: 1.4rem;
                    }
                    @media (max-width: $xl) {
                        font-size: 1.2rem;
                    }
                    @media (max-width: $md) {
                        font-size: 1rem;
                    }
                }
            }
        }

        .count {
            font-size: 4rem;
            font-weight: 800;

            @media (max-width: $xxl) {
                font-size: 3.6rem;
            }
            @media (max-width: $xl) {
                font-size: 3.4rem;
            }
            @media (max-width: $lg) {
                font-size: 3.2rem;
            }
            @media (max-width: $md) {
                font-size: 2.2rem;
            }
            @media (max-width: $sm) {
                font-size: 1.6rem;
            }
        }
    }

    .foreground {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        flex: 1;
        list-style: none;
        padding: 0;
        margin: 0;
        z-index: 1;

        .target:checked + label {
            display: none;
        }

        .target {
            opacity: 0;

            + label {
                position: absolute;
                left: 0;
                cursor: crosshair;
                width: 100px;
                height: 100px;
                z-index: 2;
                display: block;
                transition: 0.3s cubic-bezier(0, 0.43, 1, 0);

                @media (max-width: $xl) {
                    width: 90px;
                    height: 90px;
                }
                @media (max-width: $lg) {
                    width: 80px;
                    height: 80px;
                }
                @media (max-width: $md) {
                    width: 70px;
                    height: 70px;
                }
                @media (max-width: $sm) {
                    width: 60px;
                    height: 60px;
                }

                img {
                    width: 100%;
                    display: block;
                }
            }
        }
    }

    .background {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        flex: 1;
        order: 2;
        list-style: none;
        padding: 0;
        margin: 0;

        label {
            position: absolute;
            left: 0;
            cursor: crosshair;
            width: 100px;
            height: 100px;
            z-index: -50;
            display: block;
            transition: 0.3s cubic-bezier(0, 0.43, 1, 0);
            filter: blur(4px);

            @media (max-width: $xl) {
                width: 90px;
                height: 90px;
            }
            @media (max-width: $lg) {
                width: 80px;
                height: 80px;
            }
            @media (max-width: $md) {
                width: 70px;
                height: 70px;
            }
            @media (max-width: $sm) {
                width: 60px;
                height: 60px;
            }

            img {
                width: 100%;
                display: block;
            }
        }
    }

    @for $i from 1 through $numberTargetsFront {
        .foreground .target#{$i} {
            + label {
                animation: movefront#{$i} 8s infinite alternate;
                top: #{$i * 10} + "%";
                animation-delay: -(random(20)) + s !important;

                img {
                    transform: scale(random(1));
                }
            }
        }
    }

    @for $i from 1 through $numberTargetsBack {
        .background .label#{$i} {
            animation: moveback#{$i} 8s infinite alternate;
            top: #{$i * 10} + "%";
            animation-delay: -(random(20)) + s !important;

            img {
                transform: scale(random(1));
            }
        }
    }

    @for $i from 1 through $numberTargetsFront {
        @keyframes movefront#{$i} {
            0% {
                left: 0%;
                transform: rotate(-10deg);
            }
            10% {
                top: calc(random(90) / 100 * 100%);
            }
            20% {
                left: calc(random(90) / 100 * 100%);
            }
            30% {
                top: calc(random(90) / 100 * 100%);
            }
            40% {
                left: calc(random(90) / 100 * 100%);
            }
            50% {
                top: calc(random(90) / 100 * 100%);
                transform: rotate(0);
            }
            60% {
                left: calc(random(90) / 100 * 100%);
            }
            70% {
                top: calc(random(90) / 100 * 100%);
            }
            80% {
                left: calc(random(90) / 100 * 100%);
            }
            90% {
                top: calc(random(90) / 100 * 100%);
            }
            100% {
                left: calc(random(90) / 100 * 100%);
                transform: rotate(10deg);
            }
        }
    }

    @for $i from 1 through $numberTargetsBack {
        @keyframes moveback#{$i} {
            0% {
                left: 0%;
                transform: rotate(-10deg);
            }
            15% {
                top: calc(random(90) / 100 * 100%);
            }
            25% {
                left: calc(random(90) / 100 * 100%);
            }
            35% {
                top: calc(random(90) / 100 * 100%);
            }
            45% {
                left: calc(random(90) / 100 * 100%);
            }
            55% {
                top: calc(random(90) / 100 * 100%);
                transform: rotate(0);
            }
            65% {
                left: calc(random(90) / 100 * 100%);
            }
            75% {
                top: calc(random(90) / 100 * 100%);
            }
            85% {
                left: calc(random(90) / 100 * 100%);
            }
            90% {
                top: calc(random(90) / 100 * 100%);
            }
            100% {
                left: calc(random(90) / 100 * 100%);
                transform: rotate(10deg);
            }
        }
    }

    .generation-progress:after {
        overflow: hidden;
        display: inline-block;
        vertical-align: bottom;
        animation: ellipsis steps(4, end) 1900ms infinite;
        content: "\2026";
        width: 0px;
        position: absolute;
    }

    @keyframes ellipsis {
        to {
            width: 1.25em;
        }
    }

    @-webkit-keyframes ellipsis {
        to {
            width: 1.25em;
        }
    }
}
</style>
