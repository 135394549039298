<template>
    <header>
        <span>{{ $t("message.header") }}</span>
        <LogoHaikara />
        <a href="https://haikara.fr/contact/" target="_blank" class="button" v-html="$t('message.contact_us')"></a>
    </header>
</template>

<script>
import LogoHaikara from "../components/SVG/LogoHaikara.vue";

export default {
    name: "Header",
    components: {
        LogoHaikara,
    },
};
</script>

<style scoped lang="scss">
header {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
    padding: 1rem 2rem;
    border-bottom: 3px solid $base-color;

    @media (max-width: $lg) {
        border-bottom: 2px solid $base-color;
        padding: 1rem;
        flex-flow: column;
    }
    @media (max-width: $sm) {
        align-items: flex-start;
    }

    > span {
        font-size: 1.4rem;
        font-weight: 500;
        flex: 1;

        @media (max-width: $xxl) {
            font-size: 1.2rem;
        }
        @media (max-width: $lg) {
            font-size: 1rem;
            margin: 1rem 0 1.5rem;
            order: 1;
        }
        @media (max-width: $sm) {
            margin: 0.5rem 0 1rem;
        }
    }

    > svg {
        max-width: 200px;
        width: 100%;
        margin-right: 2rem;

        @media (max-width: $xl) {
            max-width: 170px;
            margin-right: 1rem;
        }
        @media (max-width: $lg) {
            margin-right: 0;
            order: 0;
        }
    }

    > a {
        white-space: nowrap;

        @media (max-width: $xl) {
            padding: 0.5rem 1rem;
        }
        @media (max-width: $lg) {
            order: 3;
        }
    }
}
</style>
