<template>
    <footer>
        <div>
            <span v-html="$t('message.footer_intro')"></span>
            <a href="https://haikara.fr/contact/" target="_blank">{{ $t("message.footer_contact") }}</a>
        </div>
        <div>
            <span @click="privacyPolicy">{{ $t("message.footer_privacy_policy") }}</span>
            <span @click="termsService">{{ $t("message.footer_terms_service") }}</span>
        </div>
    </footer>
</template>

<script>
export default {
    name: "Footer",
    computed: {
        page: function () {
            return this.$store.state.app.page;
        },
    },
    methods: {
        /**
         * Ouvrir la page "Politique de confidentialité"
         */
        privacyPolicy() {
            this.$store.dispatch("app/setPage", { current: "PRIVACY_POLICY", savePrev: ["PRIVACY_POLICY", "TERMS_SERVICE"].indexOf(this.page.current) !== -1 ? false : true });
        },
        /**
         * Ouvrir la page "Conditions de service"
         */
        termsService() {
            this.$store.dispatch("app/setPage", { current: "TERMS_SERVICE", savePrev: ["PRIVACY_POLICY", "TERMS_SERVICE"].indexOf(this.page.current) !== -1 ? false : true });
        },
    },
};
</script>

<style scoped lang="scss">
footer {
    background-color: #ff6b46;
    color: #fff;
    text-align: center;
    padding: 0.2rem 0;
    margin-top: 2rem;

    > div {
        &:first-child {
            margin: 0 0 1rem;

            a {
                color: #fff;
                text-decoration: none;
                font-weight: 600;
            }

            @media (max-width: $lg) {
                display: flex;
                flex-flow: column;
            }
        }

        &:last-child {
            display: flex;
            flex-flow: column;

            span {
                cursor: pointer;
            }
        }
    }
}
</style>
