<template>
    <div class="overlay" v-if="$store.state.app.notFound.modal">
        <div id="modal-not-found">
            <div>
                <Logo />
                <img src="@/assets/bad.png" alt="" />

                <div class="not-found">{{ $t("message.evaluation_not_found") }}</div>
                <div class="info">{{ $t("message.evaluation_not_found_info") }}</div>

                <div>
                    <button @click="close">{{ $t("message.evaluation_not_found_btn") }}</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Logo from "../components/SVG/Logo.vue";

export default {
    name: "ModalNotFound",
    data: () => {
        return {
            url: null,
            copiedText: null,
        };
    },
    components: {
        Logo,
    },
    methods: {
        close() {
            this.$store.dispatch("app/setNotFound", { modal: false });
            this.$store.dispatch("app/resetApp");
        },
    },
};
</script>

<style scoped lang="scss">
#modal-not-found {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-flow: column;
    width: 700px;
    margin: auto;

    @media (max-width: $lg) {
        width: 500px;
    }
    @media (max-width: $sm) {
        width: 100%;
    }

    > div {
        background-color: #fff;
        background: no-repeat url(@/assets/bg.jpg) center / cover;
        position: relative;
        padding: 2rem;

        @media (max-width: $lg) {
            padding: 1rem;
        }

        .logo-svg {
            width: 250px;
            display: block;
            margin: auto;

            @media (max-width: $lg) {
                width: 200px;
            }
            @media (max-width: $md) {
                width: 180px;
            }
            @media (max-width: $sm) {
                width: 170px;
            }
        }

        > img {
            width: 200px;
            display: block;
            margin: 1rem auto 0;

            @media (max-width: $lg) {
                width: 180px;
            }
            @media (max-width: $md) {
                width: 150px;
            }
            @media (max-width: $sm) {
                width: 130px;
            }
        }

        > .not-found {
            margin: 1rem auto 0;
            text-align: center;
            font-size: 1.4rem;
            font-weight: 600;
            color: $base_color;

            @media (max-width: $xxl) {
                font-size: 1.2rem;
            }
            @media (max-width: $lg) {
                font-size: 1rem;
            }
        }

        > .info {
            text-align: center;
            margin: 0.5rem auto 2rem;
            font-size: 1.2rem;
            
            @media (max-width: $xxl) {
                font-size: 1rem;
                margin: 0.5rem auto 2rem;
            }
            @media (max-width: $lg) {
                margin: 0.5rem auto 1rem;
            }
        }

        > div {
            button {
                margin: auto;
            }
        }
    }
}
</style>
