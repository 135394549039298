<template>
    <router-view />
</template>

<script>
export default {
    name: "App",
    watch: {
        "$store.state.app.overflow": {
            handler(display) {
                let frame = document.getElementById("frame");
                if (frame) {
                    if (display) {
                        frame.style.top = `${window.scrollY * -1}px`;
                        frame.style.position = 'fixed';
                    } else {
                        const scrollY = frame.style.top;
                        frame.style.position = '';
                        frame.style.top = '';
                        window.scrollTo(0, parseInt(scrollY || '0') * -1);
                    }
                }
            },
        },
    },
};
</script>

<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Jost:ital,wght@0,100..900;1,100..900&display=swap");

html,
body {
    font-family: "Jost", sans-serif;
    font-weight: 400;
    margin: 0;
    padding: 0;
    font-size: 16px;
    color: #101415;
    user-select: none;
}

h1,
h2,
h3 {
    margin: 0;
    padding: 0;
}

#frame {
    background-color: #fff;
    background: no-repeat url(@/assets/bg.jpg) center / cover;
    min-height: 100vh;
    display: flex;
    flex-flow: row nowrap;
    width: 100%;

    > div {
        flex: 1;
    }

    > .col-left,
    > .col-right {
        background-color: $base-color;
        flex: 0 0 8vw;

        > span {
            transform: rotate(180deg);
            writing-mode: vertical-lr;
            text-orientation: mixed;
            display: flex;
            align-items: center;
            width: 100%;
            text-transform: uppercase;
            font-size: 5rem;
            font-weight: 800;
            color: #ff9074;
            padding: 2rem 0;
            box-sizing: border-box;

            @media (max-width: $xxl) {
                font-size: 4rem;
            }
            @media (max-width: $xl) {
                font-size: 3.6rem;
            }
            @media (max-width: $lg) {
                font-size: 2.6rem;
            }
            @media (max-width: $md) {
                display: none;
            }
        }

        @media (max-width: $md) {
            flex: 0 0 3vw;
        }
        @media (max-width: $sm) {
            flex: 0 0 1vw;
        }
    }

    > .col-center {
        display: flex;
        flex-flow: column;
        position: relative;
        justify-content: flex-end;

        > div:not(#loadergame) {
            flex: 1;
        }
    }
}

/**
INPUTS
*/
label {
    font-family: "Jost", sans-serif;
}

input {
    font-size: 1rem;
    color: #101415;
    font-family: "Jost", sans-serif;
}

textarea {
    font-family: "Jost", sans-serif;
}

input {
    border: 1px solid #c6d5e3;
    background-color: transparent;
    font-weight: 600;
    text-align: center;
}

select {
    font-size: 1rem;
    color: #101415;
    border: 1px solid #c6d5e3;
    background-color: transparent;
    text-transform: uppercase;
    font-weight: 400;
    height: 46px;
    padding: 0 1rem;
    box-sizing: border-box;
    cursor: pointer;
    transition: all 0.4s;
    outline: none;

    @media (max-width: $lg) {
        height: 36px;
    }
    @media (max-width: $sm) {
        width: 100%;
    }

    &:checked,
    &:hover {
        border: 1px solid $base-color;
    }
}

/* Chrome, Safari, Edge, Opera */
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type="number"] {
    -moz-appearance: textfield;
}

.input-number {
    display: flex;
    flex-flow: row nowrap;

    input {
        border-right: 1px solid transparent;
        border-radius: 0;
        outline: none;
        height: 42px;
        width: 50px;

        @media (max-width: $lg) {
            height: 32px;
        }
    }

    > div {
        display: flex;
        flex-flow: column;

        button {
            border: 1px solid #c6d5e3;
            position: relative;
            left: -1px;
            box-shadow: 0 0 0;
            background-color: transparent;
            color: #c6d5e3;
            height: 50%;
            cursor: pointer;
            transition: all 0.4s;
            padding: 0;
            width: 40px;

            &:first-child {
                border-bottom: 0.5px solid #c6d5e3;
            }

            &:last-child {
                border-top: 0.5px solid #c6d5e3;
            }

            &:hover {
                background-color: $base-color;
                color: #fff;

                &:first-child {
                    border-bottom: 0.5px solid $base-color;
                }

                &:last-child {
                    border-top: 0.5px solid $base-color;
                }
            }
        }
    }
}

.radio {
    position: relative;
    overflow: hidden;
    box-shadow: 0 0 0;
    border: 0;
    background-color: transparent;
    color: #101415;
    cursor: pointer;
    transition: all 0.4s;

    @media (max-width: $sm) {
        width: 100%;
    }

    label {
        border: 1px solid #c6d5e3;
        background-color: transparent;
        font-weight: 400;
        text-align: center;
        height: 46px;
        padding: 0 1rem;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        cursor: pointer;
        text-transform: uppercase;
        transition: all 0.4s;
        font-size: 1rem;

        @media (max-width: $lg) {
            height: 36px;
        }

        &:checked,
        &:hover {
            border: 1px solid $base-color;
            background-color: $base-color;
            color: #fff;
        }
    }

    input {
        opacity: 0;
        position: absolute;
        left: -99999px;

        &:checked + label {
            border: 1px solid $base-color;
            background-color: $base-color;
            color: #fff;
        }
    }
}

.button,
button {
    box-shadow: 0 0 0;
    border: 1px solid $base-color;
    background-color: $base-color;
    color: #fff;
    cursor: pointer;
    transition: all 0.4s;
    text-transform: uppercase;
    font-weight: 600;
    box-sizing: border-box;
    font-family: "Jost", sans-serif;
    outline: none;
    text-decoration: none;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: 1rem;
    padding: 0.5rem 2rem;

    &.btn-black {
        color: #fff;
        border: 1px solid #000;
        background-color: #000;

        &:not([disabled]) {
            &:hover {
                background-color: transparent;
                color: #000;
            }
        }
    }

    &:not([disabled]) {
        &:hover {
            background-color: transparent;
            color: $base-color;
        }
    }

    &:disabled {
        opacity: 0.5;
        cursor: not-allowed;
    }
}

/**
Transition
*/
.v-enter-active {
    transition: all 0.5s ease;
}

.v-leave-active {
    transition: all 0.2s ease;
}

.v-enter-from,
.v-leave-to {
    opacity: 0;
}

/**
Scrollbar
*/
*::-webkit-scrollbar {
    height: 10px;
    width: 10px;
}
*::-webkit-scrollbar-track {
    border-radius: 0px;
    background-color: #ebf1f3;
}

*::-webkit-scrollbar-track:hover {
    background-color: #ebf1f3;
}

*::-webkit-scrollbar-track:active {
    background-color: #ebf1f3;
}

*::-webkit-scrollbar-thumb {
    border-radius: 0px;
    background-color: #ff6b46;
}

*::-webkit-scrollbar-thumb:hover {
    background-color: #ff6b46;
}

*::-webkit-scrollbar-thumb:active {
    background-color: #ff6b46;
}

/**
Overlay modal
*/
.overlay {
    position: fixed;
    height: 100%;
    width: 100%;
    z-index: 8;
    background-color: rgba(255, 107, 70, 0.7);
    left: 0;
    right: 0;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2rem;
    box-sizing: border-box;
    overflow-y: auto;

    @media (max-width: $lg) {
        padding: 1rem;
    }
}
</style>
