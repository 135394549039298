<template>
    <Transition>
        <div class="overlay" v-if="$store.state.app.shareURL.modal">
            <div id="modal-shareurl">
                <div>
                    <span @click="cancel" class="close"><FontAwesomeIcon icon="xmark" /></span>
                    <div class="content">
                        <div class="status-copied-url">{{ copiedURL }}</div>
                        <div class="url">
                            <input type="text" :value="$store.state.app.shareURL.url" readonly id="input-url" />
                            <span @click="copyURL"><FontAwesomeIcon icon="copy" /></span>
                        </div>
                        <div class="active-duration">{{ $t("message.active_duration") }}</div>
                        <div class="conditions-service" v-html="$t('message.reminder_conditions_service')"></div>
                    </div>
                </div>
            </div>
        </div>
    </Transition>
</template>

<script>
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { faXmark, faCopy } from "@fortawesome/free-solid-svg-icons";
library.add(faXmark, faCopy);

export default {
    name: "ShareURL",
    data: () => {
        return {
            copiedURL: null,
        };
    },
    components: {
        FontAwesomeIcon,
    },
    watch: {
        "$store.state.app.shareURL.modal": {
            handler() {
                this.copiedURL = null;
            },
        },
    },
    methods: {
        copyURL() {
            navigator.clipboard.writeText(this.$store.state.app.shareURL.url).then(() => {
                this.copiedURL = this.$t("message.url_copied");
                setTimeout(() => {
                    this.copiedURL = null;
                }, 1000);
            });
        },
        cancel() {
            this.$store.dispatch("app/setShareURL", { modal: false });
        },
    },
};
</script>

<style scoped lang="scss">
#modal-shareurl {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-flow: column;
    max-width: 600px;
    margin: auto;

    @media (max-width: $sm) {
        max-width: 100%;
    }

    > div {
        background-color: #fff;
        background: no-repeat url(@/assets/bg.jpg) center / cover;
        padding: 2rem;
        box-sizing: border-box;
        position: relative;
        width: 100%;

        @media (max-width: $lg) {
            padding: 1rem;
        }

        .status-copied-url {
            position: absolute;
            left: 2rem;
            top: 2rem;
        }

        .close {
            background-color: #000;
            color: #fff;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 40px;
            height: 40px;
            font-size: 2rem;
            position: absolute;
            right: 1rem;
            top: 1rem;
            cursor: pointer;

            @media (max-width: $lg) {
                width: 35px;
                height: 35px;
                font-size: 1.6rem;
            }
            @media (max-width: $md) {
                width: 30px;
                height: 30px;
                font-size: 1.2rem;
            }
        }

        .content {
            margin: 3rem 0 0;
            font-weight: 600;
            font-size: 1rem;

            @media (max-width: $lg) {
                margin: 3rem 0 0;
            }
            @media (max-width: $md) {
                margin: 2.8rem 0 0;
            }

            > div {
                &:first-child {
                    color: $base-color;
                }

                &.url {
                    border: 4px solid #e5e5e5;
                    padding: 0.5rem 1rem;
                    box-sizing: border-box;
                    display: flex;
                    flex-flow: row nowrap;
                    align-items: center;
                    justify-content: space-between;

                    @media (max-width: $lg) {
                        border: 2px solid #e5e5e5;
                    }

                    > input {
                        white-space: nowrap;
                        width: 100%;
                        border: 0;
                        box-shadow: none;
                        padding: 0;
                        outline: none;
                        font-weight: 400;
                    }

                    > span {
                        font-size: 1.4rem;
                        margin-left: 1rem;
                        cursor: pointer;
                        color: #000000;

                        @media (max-width: $xxl) {
                            font-size: 1.2rem;
                        }
                        @media (max-width: $lg) {
                            font-size: 1rem;
                        }

                        &:hover {
                            color: $base-color;
                        }
                    }
                }

                &.active-duration {
                    font-weight: 400;
                    margin-top: 0.2rem;
                    font-size: 0.9rem;
                    margin-left: 1px;
                }

                &.conditions-service {
                    font-style: italic;
                    font-weight: 400;
                    margin-top: 1rem;
                    font-size: 0.9rem;
                    background: #e5e5e5;
                    padding: 1rem;
                }
            }
        }
    }
}
</style>
