<template>
    <div id="frame" :class="{ overflow: $store.state.app.overflow }" ref="frame">
        <div class="col-left">
            <span>{{ $t("message.assessment") }}</span>
        </div>

        <div class="col-center">
            <Spinner color="#ff6b46" width="100" />

            <TermsService v-if="$store.state.app.page.current === 'TERMS_SERVICE'" />
            <PrivacyPolicy v-if="$store.state.app.page.current === 'PRIVACY_POLICY'" />

            <Evaluation v-if="page.current === 'EVALUATION'" />
            <EvaluationResults v-if="page.current === 'EVALUATION_RESULTS'" />

            <ModalAbandon />
            <ModalShareURL />
            <ModalCaution />
            <ModalNotFound />

            <Footer />
        </div>

        <div class="col-right">
            <SwitchLang />
        </div>
    </div>
</template>

<script>
import axios from "axios";
import Spinner from "../components/Spinner.vue";
import Evaluation from "../components/Evaluation.vue";
import EvaluationResults from "../components/EvaluationResults.vue";
import ModalAbandon from "../components/ModalAbandon.vue";
import ModalCaution from "../components/ModalCaution.vue";
import ModalShareURL from "../components/ModalShareURL.vue";
import ModalNotFound from "../components/ModalNotFound.vue";
import PrivacyPolicy from "../components/PrivacyPolicy.vue";
import TermsService from "../components/TermsService.vue";
import Footer from "../components/Footer.vue";
import SwitchLang from "../components/SwitchLang.vue";

export default {
    name: "EvaluationView",
    data: () => {
        return {
            id: null,
            key: null,
        };
    },
    components: {
        Spinner,
        PrivacyPolicy,
        TermsService,
        Evaluation,
        EvaluationResults,
        ModalAbandon,
        ModalShareURL,
        ModalCaution,
        ModalNotFound,
        Footer,
        SwitchLang,
    },
    computed: {
        page: function () {
            return this.$store.state.app.page;
        },
        user: function () {
            return this.$store.state.app.user;
        },
        evaluation: function () {
            return this.$store.state.app.evaluation;
        },
    },
    created() {
        window.addEventListener("scroll", this.handleScroll);
    },
    unmounted() {
        window.removeEventListener("scroll", this.handleScroll);
    },
    beforeMount() {
        this.id = this.$route.params.id;
        this.key = this.$route.params.key;

        if (!this.id || !this.key) this.$router.push({ name: "ErrorView" });

        this.getEvaluation();
    },
    mounted() {
        let frame = document.getElementById("frame");
        if (frame) {
            frame.style.top = "0px";
            frame.style.position = "fixed";
        }
    },
    methods: {
        /**
         * Sauvegarde de la position scrollY de la page pour l'ouverture de la modal
         */
        handleScroll() {
            this.$refs.frame.setAttribute("top", window.scrollY + "px");
        },
        /**
         * Recupère une evaluation
         */
        getEvaluation() {
            axios({
                method: "get",
                url: `${this.$store.state.app.api_platform_url}/api/evaluation/${this.id}/${this.key}`,
            }).then(
                (response) => {
                    if (!response || !response.data) {
                        this.$router.push({ name: "ErrorView" });
                        return;
                    }

                    response.data.user = null;

                    // On met la local à jour
                    this.$i18n.locale = response.data.lang;

                    // L'évaluation est valable pendant 7 jours à partir de sa drae de création
                    let dateNow = new Date();
                    let dateCreated = Date.parse(response.data.createdAt.toString());
                    let diff = dateNow.getTime() - dateCreated;
                    let total_seconds = parseInt(Math.floor(diff / 1000));
                    let total_minutes = parseInt(Math.floor(total_seconds / 60));
                    let total_hours = parseInt(Math.floor(total_minutes / 60));
                    let days = parseInt(Math.floor(total_hours / 24));
                    // Dans le cas ou l'evaluation à plus de 7 jours, on affiche une modale d'information
                    // Et on redirige vers la page d'accueil
                    if (days > 7) {
                        // On masque la modale d'avertissement
                        this.$store.dispatch("app/setCaution", { modal: false });

                        // On masque le spinner
                        this.$store.dispatch("app/setSpinner", false);

                        // On affiche une modale d'information
                        this.$store.dispatch("app/setNotFound", { modal: true });
                    } else {
                        // Mise à jour de l'évaluation
                        this.$store.dispatch("app/setEvaluation", response.data);

                        // Remise à zéro des réponses utilisateurs
                        this.$store.dispatch("app/resetUserReponses");

                        // On passe à la page évaluation
                        this.$store.dispatch("app/setPage", { current: "EVALUATION" });

                        // On masque le spinner
                        this.$store.dispatch("app/setSpinner", false);

                        // Mise à jour de l'URL de partage
                        this.$store.dispatch("app/setShareURL", { url: `${this.$store.state.app.base_url}evaluation/${this.evaluation.id}/${this.evaluation.urlKey}` });

                        // On affiche la modal d'avertissement
                        this.$store.dispatch("app/setCaution", { modal: true });
                    }
                },
                () => {
                    // On masque la modale d'avertissement
                    this.$store.dispatch("app/setCaution", { modal: false });

                    // On masque le spinner
                    this.$store.dispatch("app/setSpinner", false);

                    // On affiche une modale d'information
                    this.$store.dispatch("app/setNotFound", { modal: true });
                }
            );
        },
    },
};
</script>

<style lang="scss"></style>
