<template>
    <div id="frame" :class="{ overflow: $store.state.app.overflow }" ref="frame">
        <div class="col-left">
            <span>{{ $t("message.assessment") }}</span>
        </div>

        <div class="col-center">
            <GameLoader />
            <Spinner color="#ff6b46" width="100" />

            <TermsService v-if="$store.state.app.page.current === 'TERMS_SERVICE'" />
            <PrivacyPolicy v-if="$store.state.app.page.current === 'PRIVACY_POLICY'" />

            <EvaluationSettings v-if="page.current === 'EVALUATION_SETTINGS'" />
            <Evaluation v-if="page.current === 'EVALUATION'" />
            <EvaluationResults v-if="page.current === 'EVALUATION_RESULTS'" />

            <ModalAbandon />
            <ModalShareURL />
            <ModalCaution />
            <ModalModeration />
            <ModalError />

            <Footer />
        </div>

        <div class="col-right">
            <SwitchLang />
        </div>
    </div>
</template>

<script>
import axios from "axios";
import Spinner from "../components/Spinner.vue";
import GameLoader from "../components/GameLoader.vue";
import EvaluationSettings from "../components/EvaluationSettings.vue";
import Evaluation from "../components/Evaluation.vue";
import EvaluationResults from "../components/EvaluationResults.vue";
import ModalAbandon from "../components/ModalAbandon.vue";
import ModalShareURL from "../components/ModalShareURL.vue";
import PrivacyPolicy from "../components/PrivacyPolicy.vue";
import TermsService from "../components/TermsService.vue";
import ModalCaution from "../components/ModalCaution.vue";
import ModalModeration from "../components/ModalModeration.vue";
import ModalError from "../components/ModalError.vue";
import Footer from "../components/Footer.vue";
import SwitchLang from "../components/SwitchLang.vue";

export default {
    name: "SettingsView",
    data: () => {
        return {
            id: null,
            key: null,
        };
    },
    components: {
        GameLoader,
        EvaluationSettings,
        Evaluation,
        EvaluationResults,
        ModalAbandon,
        ModalShareURL,
        ModalCaution,
        ModalModeration,
        ModalError,
        Spinner,
        Footer,
        PrivacyPolicy,
        TermsService,
        SwitchLang,
    },
    computed: {
        // Page
        page: function () {
            return this.$store.state.app.page;
        },
        // User
        user: function () {
            return this.$store.state.app.user;
        },
    },
    created() {
        window.addEventListener("scroll", this.handleScroll);
    },
    unmounted() {
        window.removeEventListener("scroll", this.handleScroll);
    },
    beforeMount() {
        this.getUser();
    },
    methods: {
        /**
         * Sauvegarde de la position scrollY de la page pour l'ouverture de la modal
         */
        handleScroll() {
            this.$refs.frame.setAttribute("top", window.scrollY + "px");
        },
        /**
         * Recupère infos utilisateur
         */
        getUser() {
            let _this = this;

            axios({
                method: "get",
                url: "https://api.ipify.org?format=json",
            }).then(
                (response) => {
                    if (response && response.data && response.data.ip) {
                        this.$store.dispatch("app/setUser", { ip: btoa(response.data.ip) });

                        // Recherche si l'utilisateur existe en base
                        axios({
                            method: "get",
                            url: `${this.$store.state.app.api_platform_url}/api/user/${this.user.ip}`,
                        }).then(
                            (response) => {
                                // Si l'utilisateur est en base, on récupère ses informations
                                if (response && response.data) {
                                    this.$store.dispatch("app/setUser", response.data);
                                    _this.getNumberQuiz();
                                }
                            },
                            () => {
                                // Si l'utilisateur n'est pas en base, on le crée
                                axios({
                                    method: "post",
                                    url: `${this.$store.state.app.api_platform_url}/api/users`,
                                    data: this.user,
                                }).then(
                                    (response) => {
                                        if (response && response.data) this.$store.dispatch("app/setUser", response.data);
                                    },
                                    (error) => {
                                        console.log("error >> ", error);
                                    }
                                );

                                this.$store.dispatch("app/setSpinner", false);
                            }
                        );
                    }
                },
                (error) => {
                    console.log("error >> ", error);
                }
            );
        },
        /**
         * Recupère le nombre de quiz généré dans la journée
         */
        getNumberQuiz() {
            const date = new Date();
            let day = date.getDate();
            let month = date.getMonth() + 1;
            let year = date.getFullYear();
            let currentDate = `${year}-${month < 10 ? "0" + month : month}-${day < 10 ? "0" + day : day}`;

            axios({
                method: "get",
                url: `${this.$store.state.app.api_platform_url}/api/evaluations?user=${this.user.ip}&createdAt=${currentDate}`,
            }).then(
                (response) => {
                    if (response && response.data && response.data["hydra:member"] && response.data["hydra:member"].length >= 10) this.$store.dispatch("app/setLocked", true);
                    this.$store.dispatch("app/setSpinner", false);
                },
                (error) => {
                    console.log("error >> ", error);
                    this.$store.dispatch("app/setSpinner", false);
                }
            );
        },
    },
};
</script>
