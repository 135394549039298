const messages = {
    fr: {
        message: {
            settings: {
                welcome: "Bienvenue sur <strong>quaistion</strong>, le générateur de quiz, qui te permettra de vérifier tes connaissances sur les sujets de ton choix. C'est parti !",
                choose_a_file: "Choisis un fichier ou colle un texte",
                allowed_files: "Seuls les fichiers de type <strong>PDF, Word, txt, odt, rtf</strong> sont acceptés.",
                length_text: "Le texte ne doit pas excéder 50 000 mots",
                file_drag_here: "Choisis un fichier ou glisse-le ici",
                paste_your_text_here: "OU COLLE TON TEXTE ICI",
                define_the_parameters: "Définis les paramètres de ton évaluation",
                number_of_questions: "Nombre de questions",
                question_type: "Type de question",
                lang: "Langue",
                mode: "Mode",
                correction: "Correction",
                mcq: "QCM",
                ucq: "QCU",
                both: "Les deux",
                french: "Français",
                english: "Anglais",
                spanish: "Espagnol",
                training: "Entraînement",
                validation: "Validation",
                after_each_question: "Après chaque question",
                end_evaluation: "En fin d'évaluation",
                score_to_achieve: "Score à atteindre",
                tooltip_training: "Chaque question échouée vous sera posée à nouveau jusqu'à la réussite complète du quiz. Le mode entraînement n'est pas noté !",
                tooltip_validation: "Le quiz est une évaluation classique, avec une note à atteindre.",
                here_we_go: "C'est parti !",
                start_quiz_generation: "Lancer la génération du quiz",
                limit_generation: "Le générateur de quiz est limité à 10 essais par jour",
                error_generation: "Une erreur s'est produite pendant la génération des questions, réessayer",
            },
            close: "Fermer",
            ok: "OK",
            giving_up: "J'abandonne",
            question: "Question",
            select_the_correct_answer: "Sélectionner la bonne réponse",
            multiple_answers_possible: "Sélectionner la bonne réponse (plusieurs réponses possibles)",
            to_validate: "Valider",
            next: "Suivant",
            right_answer: "Bonne réponse",
            bad_answer: "Mauvaise réponse",
            correction: "Correction",
            redo: "Refaire",
            new_quiz_from_the_same_file: "Nouveau quiz à partir du même fichier",
            answer: "Réponse :",
            home: "Accueil",
            msg_success: "Bravo ! Tu as atteint la note requise&nbsp;!",
            msg_fail: "Oh non, tu n'as pas atteint la note minimum&nbsp;!",
            msg_success_training: "Bravo ! Tu as trouvé toutes bonnes réponses&nbsp;!",
            quiz_success: "Quiz réussi",
            quiz_fail: "Quiz échoué",
            exit_preview: "Quitter la prévisualisation",
            create_new_quiz: "Créer un nouveau quiz",
            what_do_you_want: "Que voulez-vous faire ?",
            see_the_correction: "Voir la correction",
            contact_us: "Nous contacter",
            share: "Partager",
            placeholder_pseudo: "Pseudo",
            see_results: "Voir le classement",
            return: "Retour",
            ranking_position: "Position",
            ranking_pseudo: "Pseudo",
            ranking_attemps: "Tentatives",
            ranking_attemped_at: "Date de la dernière tentative",
            ranking_score: "Score",
            active_duration: "Ce lien sera actif 7 jours !",
            url_copied: "URL copié !",
            reminder_conditions_service:
                "<strong>Rappel de conditions de service</strong><br />Vous êtes seul responsable du contenu que vous créez ou partagez via le Service quaistion. Vous acceptez de ne pas créer de contenu qui soit illégal, offensant, diffamatoire ou qui enfreigne les droits de tiers.",
            caution_welcome: "<span>Bienvenue sur ce quiz</span><br /><span style='color: #ff6b46'>qui vient de vous être partagé avec</span>",
            caution_good_evaluation: "Bonne évaluation !",
            caution_warning:
                "<span style='font-weight: 400;'>quaistion</span> ne pourra pas être tenu responsable du contenu créé ou partage via le Service. Les utilisateurs acceptent de ne pas créer de contenu qui soit illégal, offensant, diffamatoire ou qui enfreigne les droits de tiers. Si vous constatez des contenus offensant ou contraire à la loi, vous pouvez le signaler à l'adresse contact&#64;quaistion.com",
            footer_intro: "<strong>quaistion</strong> est une solution développée par haikara.",
            footer_contact: "Contactez-nous !",
            footer_terms_service: "Conditions de service",
            footer_privacy_policy: "Politique de confidentialité",
            privacy_policy: `<h1>Politique de Confidentialité</h1>
                                <ol>
                                    <li>
                                        <span>Introduction</span>
                                        <p>Chez <a href="https://haikara.fr/" target="_blank">haikara</a>, nous accordons une grande importance à la protection de vos données personnelles. Cette politique de confidentialité explique comment nous collectons, utilisons, et protégeons vos informations lorsque vous utilisez notre service en ligne <span class="link-cancel">quaistion</span>.</p>
                                    </li>
                                    <li>
                                        <span>Données Collectées</span>
                                        <ol>
                                            <li>
                                                <span>Données que Vous Fournissez</span>
                                                <p>Lorsque vous utilisez notre Service, nous pouvons collecter des informations que vous nous fournissez directement, telles que votre nom, adresse e-mail, et toute autre information nécessaire pour l'utilisation du Service.</p>
                                            </li>
                                            <li>
                                                <span>Données Collectées Automatiquement</span>
                                                <p>Nous collectons également des données automatiquement, comme votre adresse IP, des informations sur votre appareil, et des données de navigation à travers des cookies et des technologies similaires.</p>
                                            </li>
                                        </ol>
                                    </li>
                                    <li>
                                        <span>Utilisation des Données</span>
                                        <p>Nous utilisons vos données personnelles pour :</p>
                                        <ul>
                                            <li>
                                                Fournir et améliorer notre Service.
                                            </li>
                                            <li>
                                                Vous contacter concernant des mises à jour, des offres ou des informations importantes sur le Service.
                                            </li>
                                            <li>
                                                Respecter nos obligations légales.
                                            </li>
                                        </ul>
                                    </li>
                                    <li>
                                        <span>Partage des Données</span>
                                        <p>Nous ne partageons pas vos données personnelles avec des tiers, sauf si cela est nécessaire pour :</p>
                                        <ul>
                                            <li>
                                                Fournir le Service (par exemple, avec des prestataires de services techniques).
                                            </li>
                                            <li>
                                                Respecter une obligation légale ou une demande gouvernementale.
                                            </li>
                                            <li>
                                                Protéger nos droits ou ceux de nos utilisateurs.
                                            </li>
                                        </ul>
                                    </li>
                                    <li>
                                        <span>Vos Droits</span>
                                        <p>En vertu du RGPD, vous avez le droit de :</p>
                                        <ul>
                                            <li>Accéder à vos données personnelles.</li>
                                            <li>Rectifier des données inexactes.</li>
                                            <li>Demander la suppression de vos données ("droit à l'oubli").</li>
                                            <li>Restreindre ou vous opposer au traitement de vos données.</li>
                                            <li>Demander la portabilité de vos données.</li>
                                        </ul>
                                        <p>
                                            Pour exercer ces droits, veuillez nous contacter à contact&#64;quaistion.com
                                        </p>
                                    </li>
                                    <li>
                                        <span>Sécurité</span>
                                        <p>Nous mettons en œuvre des mesures techniques et organisationnelles pour protéger vos données contre les accès non autorisés, la perte ou l'altération. Cependant, aucune méthode de transmission ou de stockage électronique n'est totalement sécurisée.</p>
                                    </li>
                                    <li>
                                        <span>Conservation des Données</span>
                                        <p>Nous conservons vos données personnelles aussi longtemps que nécessaire pour fournir le Service ou pour nous conformer à nos obligations légales.</p>
                                    </li>
                                    <li>
                                        <span>Modifications de cette Politique</span>
                                        <p>Nous pouvons mettre à jour cette politique de confidentialité de temps à autre. Toute modification sera publiée sur cette page avec une date de mise à jour. Nous vous encourageons à consulter régulièrement cette page pour rester informé des changements.</p>
                                    </li>
                                    <li>
                                        <span>Contact</span>
                                        <p>Pour toute question ou demande concernant cette politique de confidentialité, veuillez nous contacter à contact&#64;quaistion.com</p>
                                    </li>
                                </ol>`,
            terms_service: `<h1>Conditions de Service</h1>
                            <ol>
                                <li>
                                    <span>Acceptation des Conditions</span>
                                    <p>En accédant et en utilisant notre logiciel en ligne de génération de quiz assisté par ChatGPT (le "Service"), vous acceptez d'être lié par les présentes conditions de service (les "Conditions"). Si vous n'acceptez pas ces Conditions, veuillez ne pas utiliser le Service.</p>
                                </li>
                                <li>
                                    <span>Description du Service</span>
                                    <p>Notre Service permet aux utilisateurs de créer des quiz en ligne à l'aide d'un assistant basé sur ChatGPT. Le Service fournit une interface utilisateur où vous pouvez entrer des informations et des paramètres pour générer des questions de quiz automatisées.</p>
                                </li>
                                <li>
                                    <span>Utilisation du Service</span>

                                    <ol>
                                        <li>
                                            <span>Licence d'Utilisation</span>
                                            <p>Nous vous accordons une licence limitée, non exclusive, non transférable et révocable pour accéder et utiliser le Service à des fins personnelles et non commerciales, conformément à ces Conditions.</p>
                                        </li>
                                        <li>
                                            <span>Restrictions</span>
                                            <p>Vous acceptez de ne pas :</p>
                                            <ul>
                                                <li>Utiliser le Service à des fins illégales ou non autorisées.</li>
                                                <li>Reproduire, dupliquer, copier, vendre, revendre ou exploiter toute partie du Service sans autorisation expresse de notre part.</li>
                                                <li>Tenter de contourner ou de compromettre les mesures de sécurité du Service.</li>
                                            </ul>
                                        </li>
                                    </ol>
                                </li>
                                <li>
                                    <span>Propriété Intellectuelle</span>
                                    <p>Tous les contenus, marques, logos et autres propriétés intellectuelles associés au Service sont la propriété exclusive de <a href="https://haikara.fr/" target="_blank">haikara</a>. L'utilisation du Service ne vous confère aucun droit de propriété sur ces éléments.</p>
                                </li>
                                <li>
                                    <span>Contenu Généré par l'Utilisateur</span>
                                    <ol>
                                        <li>
                                            <span>Propriété du Contenu</span>
                                            <p>
                                                Tout contenu que vous créez en utilisant le Service (y compris les quiz, questions, etc.) vous appartient. Toutefois, en créant du contenu via le Service, vous nous accordez une licence mondiale, non exclusive, sans redevance, pour utiliser, reproduire, modifier, adapter
                                                et publier ce contenu dans le cadre du fonctionnement du Service.
                                            </p>
                                        </li>
                                        <li>
                                            <span>Responsabilité du Contenu</span>
                                            <p>Vous êtes seul responsable du contenu que vous créez ou partagez via le Service. Vous acceptez de ne pas créer de contenu qui soit illégal, offensant, diffamatoire ou qui enfreigne les droits de tiers.</p>
                                        </li>
                                    </ol>
                                </li>
                                <li>
                                    <span>Confidentialité</span>
                                    <p>Nous nous engageons à protéger votre vie privée. Veuillez consulter notre <span class="link-privacy-policy">Politique de Confidentialité</span> pour plus d'informations sur la manière dont nous collectons, utilisons et protégeons vos informations personnelles.</p>
                                </li>
                                <li>
                                    <span>Limitation de Responsabilité</span>
                                    <p>
                                        Le Service est fourni "tel quel" et "tel que disponible". Nous ne garantissons pas que le Service sera exempt d'erreurs ou ininterrompu. Dans les limites permises par la loi, nous déclinons toute responsabilité pour tout dommage direct, indirect, accessoire ou consécutif
                                        découlant de l'utilisation ou de l'incapacité à utiliser le Service.
                                    </p>
                                </li>
                                <li>
                                    <span>Modifications des Conditions</span>
                                    <p>Nous nous réservons le droit de modifier ces Conditions à tout moment. Toute modification prendra effet dès sa publication sur cette page. Il est de votre responsabilité de consulter régulièrement ces Conditions pour rester informé de toute mise à jour.</p>
                                </li>
                                <li>
                                    <span>Résiliation</span>
                                    <p>Nous pouvons suspendre ou résilier votre accès au Service si vous enfreignez ces Conditions ou si nous estimons, à notre seule discrétion, que cela est nécessaire pour protéger nos intérêts ou ceux de nos utilisateurs.</p>
                                </li>
                                <li>
                                    <span>Loi Applicable</span>
                                    <p>Ces Conditions sont régies par et interprétées conformément aux lois de [votre pays/région]. Tout litige relatif à ces Conditions sera soumis à la juridiction exclusive des tribunaux de Paris, France.</p>
                                </li>
                                <li>
                                    <span>Contact</span>
                                    <p>Pour toute question concernant ces Conditions de Service, veuillez nous contacter à contact&#64;quaistion.com</p>
                                </li>
                            </ol>`,
            moderation: `La création de votre quiz a été bloquée.<br />Veuillez noter que les propos haineux, discriminatoires ou violents sont strictement interdits.`,
            evaluation_not_found: `Désolé, le quiz demandé n'existe plus !`,
            evaluation_not_found_info: `Les quiz peuvent être partagés pendant une période de 7 jours`,
            evaluation_not_found_btn: `Générer un nouveau quiz`,
            game_generation_quiz: "génération du quiz en cours",
            game_over: "Partie terminée",
            game_quiz_ready: "Quiz prêt !",
            game_capture: "Capturez-en le maximum",
            assessment: "Evaluation",
            header: "Une solution IA développée par Haikara",
            error_ai: "Oups, Quaistion n'a pas réussi à générer votre quiz, merci de re-essayer.",
        },
    },
    en: {
        message: {
            settings: {
                welcome: "Welcome to <strong>quaistion</strong>, the quiz generator, which will allow you to check your knowledge on the subjects of your choice. Let's go!",
                choose_a_file: "Choose a file or paste a text",
                allowed_files: "Only files of type <strong>PDF, Word, txt, odt, rtf</strong> are accepted.",
                length_text: "The text must not exceed 50,000 words",
                file_drag_here: "Choose a file or drag it here",
                paste_your_text_here: "OR PASTE YOUR TEXT HERE",
                define_the_parameters: "Define the parameters of your evaluation",
                number_of_questions: "Number of questions",
                question_type: "Question type",
                lang: "Language",
                mode: "Fashion",
                correction: "Correction",
                mcq: "MCQ",
                ucq: "QCU",
                both: "Both",
                french: "French",
                english: "English",
                spanish: "Spanish",
                training: "Training",
                validation: "Assessment",
                after_each_question: "After each question",
                end_evaluation: "At the end of the evaluation",
                score_to_achieve: "Score to achieve",
                tooltip_training: "Each failed question will be asked again until you completely pass the quiz. Training mode is not rated!",
                tooltip_validation: "The quiz is a classic assessment, with a grade to achieve.",
                here_we_go: "Here we go!",
                start_quiz_generation: "Launch the quiz generation",
                limit_generation: "The quiz generator is limited to 10 attempts per day",
                error_generation: "An error occurred while generating questions, try again",
            },
            close: "Close",
            ok: "OK",
            giving_up: "Give up!",
            question: "Question",
            select_the_correct_answer: "Select the correct answer",
            multiple_answers_possible: "Select the correct answer (several possible answers)",
            to_validate: "Validate",
            next: "Next",
            right_answer: "Right answer",
            bad_answer: "Bad answer",
            correction: "Correction",
            redo: "Do the quiz again",
            new_quiz_from_the_same_file: "Generate a new quiz from the same file",
            answer: "Answer:",
            home: "Home",
            msg_success: "Congratulations! You've achieved the required score!",
            msg_fail: "Oh no, you didn't reach the minimum score!",
            msg_success_training: "Congratulations! You've found all the right answers!",
            quiz_success: "Quiz passed",
            quiz_fail: "Failed quiz",
            exit_preview: "Exit preview",
            create_new_quiz: "Create a new quiz",
            what_do_you_want: "What do you want to do ?",
            see_the_correction: "View answers",
            contact_us: "Contact us",
            share: "Share",
            placeholder_pseudo: "Nickname",
            see_results: "See the ranking",
            return: "Return",
            ranking_position: "Position",
            ranking_pseudo: "Pseudo",
            ranking_attemps: "Attempts",
            ranking_attemped_at: "Date of last attempt",
            ranking_score: "Score",
            active_duration: "This link will be active for 7 days!",
            url_copied: "URL copied!",
            reminder_conditions_service:
                "<strong>Terms of Service Reminder</strong><br />You are solely responsible for the content you create or share through the Quaistion Service. You agree not to create any content that is illegal, offensive, defamatory, or infringes the rights of third parties.",
            caution_welcome: "<span>Welcome to this quiz</span><br /><span style='color: #ff6b46'>which has just been shared with you</span>",
            caution_good_evaluation: "Good review!",
            caution_warning:
                "<span style='font-weight: 400;'>quaistion</span> cannot be held responsible for content created or shared via the Service. Users agree not to create content that is illegal, offensive, defamatory or infringes the rights of third parties. If you notice content that is offensive or contrary to the law, you can report it to contact&#64;quaistion.com",
            footer_intro: "<strong>quaistion</strong> is a solution developed by haikara.",
            footer_contact: "Contact us!",
            footer_terms_service: "Terms of Service",
            footer_privacy_policy: "Privacy Policy",
            privacy_policy: `<h1>Privacy Policy</h1>
                                <ol>
                                    <li>
                                        <span>Introduction</span>
                                        <p>At <a href="https://haikara.fr/" target="_blank">haikara</a>, we attach great importance to the protection of your personal data. This privacy policy explains how we collect, use, and protect your information when you use our online service <span class="link-cancel">quaistion</span>.</p>
                                    </li>
                                    <li>
                                        <span>Data Collected</span>
                                        <ol>
                                            <li>
                                                <span>Data You Provide</span>
                                                <p>When you use our Service, we may collect information that you provide directly to us, such as your name, email address, and any other information necessary for use of the Service.</p>
                                            </li>
                                            <li>
                                                <span>Data Collected Automatically</span>
                                                <p>We also collect data automatically, such as your IP address, device information, and browsing data through cookies and similar technologies.</p>
                                            </li>
                                        </ol>
                                    </li>
                                    <li>
                                        <span>Use of Data</span>
                                        <p>We use your personal data to:</p>
                                        <ul>
                                            <li>
                                                To provide and improve our Service.
                                            </li>
                                            <li>
                                                Contact you regarding updates, offers or important information on the Service.
                                            </li>
                                            <li>
                                                Comply with our legal obligations.
                                            </li>
                                        </ul>
                                    </li>
                                    <li>
                                        <span>Data Sharing</span>
                                        <p>We do not share your personal data with third parties, unless it is necessary to:</p>
                                        <ul>
                                            <li>
                                                Providing the Service (for example, with technical service providers).
                                            </li>
                                            <li>
                                                Comply with a legal obligation or government request.
                                            </li>
                                            <li>
                                                Protect our rights or those of our users.
                                            </li>
                                        </ul>
                                    </li>
                                    <li>
                                        <span>Your Rights</span>
                                        <p>Under the GDPR, you have the right to:</p>
                                        <ul>
                                            <li>Access your personal data.</li>
                                            <li>Rectify inaccurate data.</li>
                                            <li>Request deletion of your data (“right to be forgotten”).</li>
                                            <li>Restrict or object to the processing of your data.</li>
                                            <li>Request portability of your data.</li>
                                        </ul>
                                        <p>
                                            To exercise these rights, please contact us at contact&#64;quaistion.com
                                        </p>
                                    </li>
                                    <li>
                                        <span>Security</span>
                                        <p>We implement technical and organizational measures to protect your data against unauthorized access, loss or alteration. However, no method of electronic transmission or storage is completely secure.</p>
                                    </li>
                                    <li>
                                        <span>Data Retention</span>
                                        <p>We retain your personal data for as long as necessary to provide the Service or to comply with our legal obligations.</p>
                                    </li>
                                    <li>
                                        <span>Changes to this Policy</span>
                                        <p>We may update this privacy policy from time to time. Any changes will be posted on this page with an updated date. We encourage you to check this page regularly to stay informed of changes.</p>
                                    </li>
                                    <li>
                                        <span>Contact</span>
                                        <p>For any questions or requests regarding this privacy policy, please contact us at contact&#64;quaistion.com</p>
                                    </li>
                                </ol>`,
            terms_service: `<h1>Terms of Service</h1>
                            <ol>
                                <li>
                                    <span>Acceptance of the Conditions</span>
                                    <p>By accessing and using our ChatGPT-assisted online quiz generation software (the “Service”), you agree to be bound by these terms of service (the “Terms”). If you do not agree to these Terms, please do not use the Service.</p>
                                </li>
                                <li>
                                    <span>Service Description</span>
                                    <p>Our Service allows users to create online quizzes using a ChatGPT-based wizard. The Service provides a user interface where you can enter information and parameters to generate automated quiz questions.</p>
                                </li>
                                <li>
                                    <span>Use of the Service</span>

                                    <ol>
                                        <li>
                                            <span>User License</span>
                                            <p>We grant you a limited, non-exclusive, non-transferable, revocable license to access and use the Service for personal, non-commercial purposes in accordance with these Terms.</p>
                                        </li>
                                        <li>
                                            <span>Restrictions</span>
                                            <p>You agree not to:</p>
                                            <ul>
                                                <li>Use the Service for any illegal or unauthorized purpose.</li>
                                                <li>Reproduce, duplicate, copy, sell, resell or exploit any part of the Service without express permission from us.</li>
                                                <li>Attempt to circumvent or compromise the security measures of the Service.</li>
                                            </ul>
                                        </li>
                                    </ol>
                                </li>
                                <li>
                                    <span>Intellectual Property</span>
                                    <p>All content, brands, logos and other intellectual property associated with the Service are the exclusive property of <a href="https://haikara.fr/" target="_blank">haikara</a>. Use of the Service does not give you any ownership rights over these elements.</p>
                                </li>
                                <li>
                                    <span>User Generated Content</span>
                                    <ol>
                                        <li>
                                            <span>Ownership of Content</span>
                                            <p>
                                                Any content you create using the Service (including quizzes, questions, etc.) is owned by you. However, by creating content via the Service, you grant us a worldwide, non-exclusive, royalty-free license to use, reproduce, modify, adapt
                                                and publish this content as part of the operation of the Service.
                                            </p>
                                        </li>
                                        <li>
                                            <span>Content Responsibility</span>
                                            <p>You are solely responsible for the content you create or share via the Service. You agree not to create content that is illegal, offensive, defamatory or infringes the rights of third parties.</p>
                                        </li>
                                    </ol>
                                </li>
                                <li>
                                    <span>Confidentiality</span>
                                    <p>We are committed to protecting your privacy. Please see our <span class="link-privacy-policy">Privacy Policy</span> for more information on how we collect, use and protect your personal information.</p>
                                </li>
                                <li>
                                    <span>Limitation of Liability</span>
                                    <p>
                                        The Service is provided on an “as is” and “as available” basis. We do not guarantee that the Service will be error-free or uninterrupted. To the maximum extent permitted by law, we assume no liability for any direct, indirect, incidental or consequential damages.
                                        arising from the use of or inability to use the Service.
                                    </p>
                                </li>
                                <li>
                                    <span>Changes to the Conditions</span>
                                    <p>We reserve the right to modify these Terms at any time. Any changes will take effect immediately upon posting on this page. It is your responsibility to review these Terms regularly to stay informed of any updates.</p>
                                </li>
                                <li>
                                    <span>Termination</span>
                                    <p>We may suspend or terminate your access to the Service if you violate these Terms or if we believe, in our sole discretion, that it is necessary to protect our interests or those of our users.</p>
                                </li>
                                <li>
                                    <span>Applicable Law</span>
                                    <p>These Terms are governed by and construed in accordance with the laws of [your country/region]. Any dispute relating to these Conditions will be subject to the exclusive jurisdiction of the courts of Paris, France.</p>
                                </li>
                                <li>
                                    <span>Contact</span>
                                    <p>If you have any questions regarding these Terms of Service, please contact us at contact&#64;quaistion.com</p>
                                </li>
                            </ol>`,
            moderation: `The creation of your quiz has been blocked.<br />Please note that hateful, discriminatory or violent comments are strictly prohibited.`,
            evaluation_not_found: `Sorry, the requested quiz no longer exists!`,
            evaluation_not_found_info: `Quizzes can be shared for a period of 7 days`,
            evaluation_not_found_btn: `Generate a new quiz`,
            game_generation_quiz: "generating quiz",
            game_over: "Game over",
            game_quiz_ready: "Quiz ready!",
            game_capture: "Capture as many as possible",
            assessment: "Assessment",
            header: "An AI solution developed by Haikara",
            error_ai: "Oops, Quaistion failed to generate your quiz, please try again.",
        },
    },
    es: {
        message: {
            settings: {
                welcome: "Bienvenido a <strong>quaistion</strong>, el generador de cuestionarios que te permitirá comprobar tus conocimientos sobre los temas que elijas. ¡Vamos!",
                choose_a_file: "Elige un archivo o pega un texto",
                allowed_files: "Sólo se aceptan archivos de tipo <strong>PDF, Word, txt, odt, rtf</strong>.",
                length_text: "El texto no debe exceder las 50.000 palabras",
                file_drag_here: "Elija un archivo o arrástrelo aquí",
                paste_your_text_here: "O PEGA TU TEXTO AQUÍ",
                define_the_parameters: "Define los parámetros de tu evaluación",
                number_of_questions: "Número de preguntas",
                question_type: "Tipo de pregunta",
                lang: "Idioma",
                mode: "Moda",
                correction: "Corrección",
                mcq: "MCQ",
                ucq: "CUC",
                both: "Ambos",
                french: "Francés",
                english: "Inglés",
                spanish: "Español",
                training: "Capacitación",
                validation: "Validación",
                after_each_question: "Después de cada pregunta",
                end_evaluation: "Al final de la evaluación",
                score_to_achieve: "Puntuación para lograr",
                tooltip_training: "Cada pregunta fallida se volverá a formular hasta que apruebe completamente el cuestionario. ¡El modo de entrenamiento no está clasificado!",
                tooltip_validation: "El cuestionario es una evaluación clásica, con una puntuación que alcanzar.",
                here_we_go: "¡Aquí vamos!",
                start_quiz_generation: "Iniciar la generación de cuestionarios",
                limit_generation: "El generador de cuestionarios está limitado a 10 intentos por día",
                error_generation: "Se produjo un error al generar preguntas, inténtelo nuevamente",
            },
            close: "Cerca",
            ok: "OK",
            giving_up: "Tiro la toalla",
            question: "Pregunta",
            select_the_correct_answer: "Selecciona la respuesta correcta",
            multiple_answers_possible: "Seleccione la respuesta correcta (varias respuestas posibles)",
            to_validate: "Validar",
            next: "Siguiente",
            right_answer: "Respuesta correcta",
            bad_answer: "Mala respuesta",
            correction: "Corrección",
            redo: "Rehacer",
            new_quiz_from_the_same_file: "Nuevo cuestionario del mismo archivo",
            answer: "Respuesta:",
            home: "Página principal",
            msg_success: "Bien hecho ! ¡Has alcanzado la puntuación requerida!",
            msg_fail: "¡Oh no, no alcanzaste la marca mínima!",
            msg_success_training: "Bien hecho ! ¡Encontraste todas las respuestas correctas!",
            quiz_success: "Prueba aprobada",
            quiz_fail: "Prueba fallida",
            exit_preview: "Salir de la vista previa",
            create_new_quiz: "Crear una nueva prueba",
            what_do_you_want: "Que quereis hacer ?",
            see_the_correction: "Ver corrección",
            contact_us: "Contáctenos",
            share: "Compartir",
            placeholder_pseudo: "Apodo",
            see_results: "Ver la clasificación",
            return: "Atrás",
            ranking_position: "Posición",
            ranking_pseudo: "Seudo",
            ranking_attemps: "Intentos",
            ranking_attemped_at: "Fecha del último intento",
            ranking_score: "Puntaje",
            active_duration: "¡Este enlace estará activo durante 7 días!",
            url_copied: "¡URL copiada!",
            reminder_conditions_service:
                "<strong>Recordatorio de términos de servicio</strong><br />Usted es el único responsable del contenido que crea o comparte a través del Servicio quaistion. Te comprometes a no crear contenido que sea ilegal, ofensivo, difamatorio o que infrinja los derechos de terceros.",
            caution_welcome: "<span>Bienvenido a este cuestionario</span><by /><span style='color: #ff6b46'>que acabamos de compartir contigo</span>",
            caution_good_evaluation: "Buena reseña!",
            caution_warning:
                "<span style='font-weight: 400;'>quaistion</span> no se hace responsable del contenido creado o compartido a través del Servicio. Los usuarios se comprometen a no crear contenidos que sean ilegales, ofensivos, difamatorios o que infrinjan los derechos de terceros. Si observa contenido ofensivo o contrario a la ley, puede informarlo contactando a&#64;quaistion.com",
            footer_intro: "<strong>quaistion</strong> es una solución desarrollada por haikara.",
            footer_contact: "¡Contáctanos!",
            footer_terms_service: "Términos de servicio",
            footer_privacy_policy: "Política de privacidad",
            privacy_policy: `<h1>Política de privacidad</h1>
                                <ol>
                                    <li>
                                        <span>Introducción</span>
                                        <p>En <a href="https://haikara.fr/" target="_blank">haikara</a>, damos gran importancia a la protección de sus datos personales. Esta política de privacidad explica cómo recopilamos, utilizamos y protegemos su información cuando utiliza nuestro servicio en línea <span class="link-cancel">quaistion</span>.</p>
                                    </li>
                                    <li>
                                        <span>Datos recopilados</span>
                                        <ol>
                                            <li>
                                                <span>Datos que usted proporciona</span>
                                                <p>Cuando utiliza nuestro Servicio, podemos recopilar información que nos proporciona directamente, como su nombre, dirección de correo electrónico y cualquier otra información necesaria para el uso del Servicio.</p>
                                            </li>
                                            <li>
                                                <span>Datos recopilados automáticamente</span>
                                                <p>También recopilamos datos automáticamente, como su dirección IP, información del dispositivo y datos de navegación a través de cookies y tecnologías similares.</p>
                                            </li>
                                        </ol>
                                    </li>
                                    <li>
                                        <span>Uso de datos</span>
                                        <p>Utilizamos sus datos personales para:</p>
                                        <ul>
                                            <li>
                                                Para proporcionar y mejorar nuestro Servicio.
                                            </li>
                                            <li>
                                                Contactarlo con respecto a actualizaciones, ofertas o información importante sobre el Servicio.
                                            </li>
                                            <li>
                                                Cumplir con nuestras obligaciones legales.
                                            </li>
                                        </ul>
                                    </li>
                                    <li>
                                        <span>Compartir datos</span>
                                        <p>No compartimos tus datos personales con terceros, a menos que sea necesario para:</p>
                                        <ul>
                                            <li>
                                                Proporcionar el Servicio (por ejemplo, con proveedores de servicios técnicos).
                                            </li>
                                            <li>
                                                Cumplir con una obligación legal o solicitud gubernamental.
                                            </li>
                                            <li>
                                                Proteger nuestros derechos o los de nuestros usuarios.
                                            </li>
                                        </ul>
                                    </li>
                                    <li>
                                        <span>Tus derechos</span>
                                        <p>Según el RGPD, tienes derecho a:</p>
                                        <ul>
                                            <li>Accede a tus datos personales.</li>
                                            <li>Rectificar los datos inexactos.</li>
                                            <li>Solicitar la eliminación de tus datos (“derecho al olvido”).</li>
                                            <li>Restringir u oponerse al tratamiento de sus datos.</li>
                                            <li>Solicita la portabilidad de tus datos.</li>
                                        </ul>
                                        <p>
                                            Para ejercer estos derechos, por favor contáctenos en contact&#64;quaistion.com
                                        </p>
                                    </li>
                                    <li>
                                        <span>Seguridad</span>
                                        <p>Implementamos medidas técnicas y organizativas para proteger sus datos contra el acceso no autorizado, pérdida o alteración. Sin embargo, ningún método de transmisión o almacenamiento electrónico es completamente seguro.</p>
                                    </li>
                                    <li>
                                        <span>Retención de datos</span>
                                        <p>Conservamos sus datos personales durante el tiempo necesario para proporcionar el Servicio o para cumplir con nuestras obligaciones legales.</p>
                                    </li>
                                    <li>
                                        <span>Cambios a esta Política</span>
                                        <p>Podemos actualizar esta política de privacidad de vez en cuando. Cualquier cambio se publicará en esta página con una fecha actualizada. Le recomendamos que consulte esta página con regularidad para mantenerse informado de los cambios.</p>
                                    </li>
                                    <li>
                                        <span>Contacto</span>
                                        <p>Si tiene alguna pregunta o solicitud relacionada con esta política de privacidad, contáctenos en contact&#64;quaistion.com</p>
                                    </li>
                                </ol>`,
            terms_service: `<h1>Términos de servicio</h1>
                            <ol>
                                <li>
                                    <span>Aceptación de las Condiciones</span>
                                    <p>Al acceder y utilizar nuestro software de generación de cuestionarios en línea asistido por ChatGPT (el "Servicio"), usted acepta estar sujeto a estos términos de servicio (los "Términos"). Si no está de acuerdo con estos Términos, no utilice el Servicio.</p>
                                </li>
                                <li>
                                    <span>Descripción del servicio</span>
                                    <p>Nuestro servicio permite a los usuarios crear cuestionarios en línea utilizando un asistente basado en ChatGPT. El Servicio proporciona una interfaz de usuario donde puede ingresar información y parámetros para generar preguntas de prueba automatizadas.</p>
                                </li>
                                <li>
                                    <span>Uso del Servicio</span>

                                    <ol>
                                        <li>
                                            <span>Licencia de usuario</span>
                                            <p>Le otorgamos una licencia limitada, no exclusiva, intransferible y revocable para acceder y utilizar el Servicio con fines personales y no comerciales de acuerdo con estos Términos.</p>
                                        </li>
                                        <li>
                                            <span>Restricciones</span>
                                            <p>Usted acepta no:</p>
                                            <ul>
                                                <li>Usar el Servicio para cualquier propósito ilegal o no autorizado.</li>
                                                <li>Reproducir, duplicar, copiar, vender, revender o explotar cualquier parte del Servicio sin nuestro permiso expreso.</li>
                                                <li>Intentar eludir o comprometer las medidas de seguridad del Servicio.</li>
                                            </ul>
                                        </li>
                                    </ol>
                                </li>
                               <li>
                                    <span>Propiedad intelectual</span>
                                    <p>Todo el contenido, marcas, logotipos y otra propiedad intelectual asociada con el Servicio son propiedad exclusiva de <a href="https://haikara.fr/" target="_blank">haikara</a>. El uso del Servicio no le otorga ningún derecho de propiedad sobre estos elementos.</p>
                                </li>
                                <li>
                                    <span>Contenido generado por el usuario</span>
                                    <ol>
                                        <li>
                                            <span>Propiedad del contenido</span>
                                            <p>
                                                Cualquier contenido que cree utilizando el Servicio (incluidos cuestionarios, preguntas, etc.) es de su propiedad. Sin embargo, al crear contenido a través del Servicio, nos otorga una licencia mundial, no exclusiva y libre de regalías para usar, reproducir, modificar, adaptar.
                                                y publicar este contenido como parte del funcionamiento del Servicio.
                                            </p>
                                        </li>
                                        <li>
                                            <span>Responsabilidad del contenido</span>
                                            <p>Usted es el único responsable del contenido que crea o comparte a través del Servicio. Aceptas no crear contenido que sea ilegal, ofensivo, difamatorio o que infrinja los derechos de terceros.</p>
                                        </li>
                                    </ol>
                                </li>
                                <li>
                                    <span>Confidencialidad</span>
                                    <p>Estamos comprometidos a proteger su privacidad. Consulte nuestra <span class="link-privacy-policy">Política de Privacidad</span> para obtener más información sobre cómo recopilamos, utilizamos y protegemos su información personal.</p>
                                </li>
                                <li>
                                    <span>Limitación de responsabilidad</span>
                                    <p>
                                        El Servicio se proporciona "tal cual" y "según disponibilidad". No garantizamos que el Servicio estará libre de errores o será ininterrumpido. En la medida máxima permitida por la ley, no asumimos ninguna responsabilidad por daños directos, indirectos, incidentales o consecuentes.
                                        que surjan del uso o la imposibilidad de usar el Servicio.
                                    </p>
                                </li>
                                <li>
                                    <span>Cambios en las Condiciones</span>
                                    <p>Nos reservamos el derecho de modificar estos Términos en cualquier momento. Cualquier cambio entrará en vigor inmediatamente después de su publicación en esta página. Es su responsabilidad revisar estos Términos periódicamente para mantenerse informado sobre cualquier actualización.</p>
                                </li>
                                <li>
                                    <span>Terminación</span>
                                    <p>Podemos suspender o cancelar su acceso al Servicio si viola estos Términos o si creemos, a nuestro exclusivo criterio, que es necesario para proteger nuestros intereses o los de nuestros usuarios.</p>
                                </li>
                                <li>
                                    <span>Ley aplicable</span>
                                    <p>Estos Términos se rigen e interpretan de acuerdo con las leyes de [su país/región]. Cualquier disputa relacionada con estas Condiciones estará sujeta a la jurisdicción exclusiva de los tribunales de París, Francia.</p>
                                </li>
                                <li>
                                    <span>Contacto</span>
                                    <p>Si tiene alguna pregunta sobre estos Términos de servicio, contáctenos en contact&#64;quaistion.com</p>
                                </li>
                            </ol>`,
            moderation: `La creación de tu cuestionario ha sido bloqueada.<br />Tenga en cuenta que los comentarios que inciten al odio, discriminatorios o violentos están estrictamente prohibidos.`,
            evaluation_not_found: `¡Lo sentimos, el cuestionario solicitado ya no existe!`,
            evaluation_not_found_info: `Los cuestionarios se pueden compartir por un período de 7 días`,
            evaluation_not_found_btn: `Generar un nuevo cuestionario`,
            game_generation_quiz: "generación de cuestionarios actual",
            game_over: "Juego terminado",
            game_quiz_ready: "¡Prueba lista!",
            game_capture: "Captura tanto como sea posible",
            assessment: "Evaluación",
            header: "Una solución de IA desarrollada por Haikara",
            error_ai: "Vaya, Quaistion no pudo generar su cuestionario. Inténtelo de nuevo.",
        },
    },
};

export default messages;
