<template>
    <ul id="switch-lang" :class="{ 'hide-sm': hiddenPages }">
        <li @click="setLang('fr')" :class="{ active: locale === 'fr' }" :title="$t('message.settings.french')">
            <IconFrench />
        </li>
        <li @click="setLang('en')" :class="{ active: locale === 'en' }" :title="$t('message.settings.english')">
            <IconEnglish />
        </li>
        <li @click="setLang('es')" :class="{ active: locale === 'es' }" :title="$t('message.settings.spanish')">
            <IconSpanish />
        </li>
    </ul>
</template>

<script>
import IconFrench from "../components/SVG/flags/IconFrench.vue";
import IconEnglish from "../components/SVG/flags/IconEnglish.vue";
import IconSpanish from "../components/SVG/flags/IconSpanish.vue";

export default {
    name: "SwitchLang",
    data: () => {
        return {
            locale: "fr",
        };
    },
    components: {
        IconFrench,
        IconEnglish,
        IconSpanish,
    },
    computed: {
        // Pages sur lesquelles le selecteur de langue est masqué
        hiddenPages: function () {
            return ["TERMS_SERVICE", "PRIVACY_POLICY"].indexOf(this.$store.state.app.page.current) !== -1;
        },
    },
    watch: {
        "$i18n.locale": {
            handler(locale) {
                this.locale = locale;
            },
        },
    },
    mounted() {
        this.locale = this.$i18n.locale;
    },
    methods: {
        setLang(locale) {
            this.locale = locale;
            this.$i18n.locale = locale;
            this.$store.dispatch("app/setLang", locale);
        },
    },
};
</script>

<style scoped lang="scss">
#switch-lang {
    margin: 0;
    padding: 1rem 0;
    list-style: none;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-flow: row nowrap;
    font-size: 0.5rem;

    @media (max-width: $xxl) {
        flex-flow: column;
    }
    @media (max-width: $md) {
        flex-flow: row nowrap;
        position: absolute;
        right: calc(3vw + 1rem);

        &.hide-sm {
            display: none;
        }
    }
    @media (max-width: $sm) {
        right: calc(1vw + 1rem);
    }

    > li {
        list-style: none;
        cursor: pointer;
        margin: 0 0.1rem;
        opacity: 0.4;

        &:first-child {
            margin-left: 0;
        }

        &:last-child {
            margin-right: 0;
        }

        @media (max-width: $xxl) {
            margin: 0;
        }
        @media (max-width: $md) {
            margin: 0 0.1rem;
        }

        svg {
            height: auto;
            width: 30px;
        }

        &:hover,
        &.active {
            opacity: 1;
        }
    }
}
</style>
