<template>
    <div class="overlay" v-if="$store.state.app.caution.modal">
        <div id="modal-caution">
            <div>
                <span @click="cancel" class="close"><FontAwesomeIcon icon="xmark" /></span>
                <div class="content">
                    <div class="intro" v-html="$t('message.caution_welcome')"></div>
                    <Logo />
                    <div class="good-eval" v-html="$t('message.caution_good_evaluation')"></div>
                    <div class="warning" v-html="$t('message.caution_warning')"></div>
                    <div>
                        <button @click="cancel">{{ $t("message.ok") }}</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Logo from "../components/SVG/Logo.vue";
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { faXmark, faCopy } from "@fortawesome/free-solid-svg-icons";
library.add(faXmark, faCopy);

export default {
    name: "ModalCaution",
    components: {
        FontAwesomeIcon,
        Logo,
    },
    methods: {
        cancel() {
            this.$store.dispatch("app/setCaution", { modal: false });
        },
    },
};
</script>

<style scoped lang="scss">
#modal-caution {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-flow: column;
    width: 600px;
    margin: auto;

    @media (max-width: $lg) {
        width: 500px;
    }
    @media (max-width: $sm) {
        width: 100%;
    }

    > div {
        background-color: #fff;
        background: no-repeat url(@/assets/bg.jpg) center / cover;
        padding: 2rem;
        box-sizing: border-box;
        position: relative;

        @media (max-width: $lg) {
            padding: 1rem;
        }

        .close {
            background-color: #000;
            color: #fff;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 40px;
            height: 40px;
            font-size: 2rem;
            position: absolute;
            right: 1rem;
            top: 1rem;
            cursor: pointer;

            @media (max-width: $lg) {
                width: 35px;
                height: 35px;
                font-size: 1.6rem;
            }
            @media (max-width: $md) {
                width: 30px;
                height: 30px;
                font-size: 1.2rem;
            }
        }

        .content {
            margin: 0;
            text-align: center;

            @media (max-width: $sm) {
                margin: 1rem 0 0 0;
            }

            .intro {
                font-size: 1.4rem;
                font-weight: 600;

                @media (max-width: $xxl) {
                    font-size: 1.2rem;
                }
                @media (max-width: $lg) {
                    font-size: 1rem;
                }
            }

            .logo-svg {
                display: block;
                max-width: 220px;
                width: 100%;
                margin: 2rem auto;
                
                @media (max-width: $xxl) {
                    max-width: 200px;
                }
                @media (max-width: $lg) {
                    max-width: 170px;
                    margin: 1rem auto;
                }
            }

            .good-eval {
                font-size: 1.4rem;
                font-weight: 600;
                color: $base_color;
                margin-bottom: 2rem;

                @media (max-width: $xxl) {
                    font-size: 1.2rem;
                }
                @media (max-width: $lg) {
                    font-size: 1rem;
                    margin-bottom: 1rem;
                }
            }

            .warning {
                font-size: 1rem;
                font-weight: 300;
            }

            > div:last-child {
                display: flex;
                justify-content: center;
                padding: 2rem 0 0;

                button {
                    padding: 0.5rem 2rem;
                }
            }
        }
    }
}
</style>
