<template>
    <Transition>
        <div class="overlay" v-if="$store.state.app.abandon.modal">
            <div id="modal-abandon">
                <div>
                    <img src="@/assets/modal.png" alt="" />
                </div>
                <div>
                    <span @click="cancel" class="close"><FontAwesomeIcon icon="xmark" /></span>
                    <div class="content">{{ $t("message.what_do_you_want", evaluation.lang) }}</div>
                    <div class="actions">
                        <button @click="runCorrection">{{ $t("message.see_the_correction", evaluation.lang) }}</button>
                        <button @click="reset">{{ $t("message.create_new_quiz", evaluation.lang) }}</button>
                    </div>
                </div>
            </div>
        </div>
    </Transition>
</template>

<script>
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
library.add(faXmark);

export default {
    name: "ModalAbandon",
    components: {
        FontAwesomeIcon,
    },
    computed: {
        evaluation: function () {
            return this.$store.state.app.evaluation;
        },
    },
    methods: {
        runCorrection() {
            this.$store.dispatch("app/setPage", { current: "EVALUATION_RESULTS" });
            this.$store.dispatch("app/setAbandon", { status: true });
        },
        cancel() {
            this.$store.dispatch("app/setAbandon", { modal: false });
        },
        reset() {
            this.$store.dispatch("app/resetApp");
        },
    },
};
</script>

<style scoped lang="scss">
#modal-abandon {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-flow: column;
    top: -30px;
    position: relative;
    width: 500px;
    margin: auto;

    @media (max-width: $xl) {
        width: 450px;
    }
    @media (max-width: $md) {
        position: static;
    }
    @media (max-width: $sm) {
        width: 100%;
    }

    > div {
        &:first-child {
            position: relative;
            top: 30px;
            z-index: 1;
            text-align: center;

            @media (max-width: $md) {
                display: none;
            }

            img {
                width: 100%;

                @media (max-width: $xxl) {
                    width: 90%;
                }
                @media (max-width: $xl) {
                    width: 85%;
                }
                @media (max-width: $lg) {
                    width: 60%;
                }
            }
        }

        &:last-child {
            background-color: #fff;
            background: no-repeat url(@/assets/bg.jpg) center / cover;
            padding: 2rem;
            box-sizing: border-box;
            position: relative;
            width: 100%;

            @media (max-width: $lg) {
                padding: 1rem;
            }

            .close {
                background-color: #000;
                color: #fff;
                display: flex;
                justify-content: center;
                align-items: center;
                width: 40px;
                height: 40px;
                font-size: 2rem;
                position: absolute;
                right: 1rem;
                top: 1rem;
                cursor: pointer;

                @media (max-width: $lg) {
                    width: 35px;
                    height: 35px;
                    font-size: 1.6rem;
                }
                @media (max-width: $md) {
                    width: 30px;
                    height: 30px;
                    font-size: 1.2rem;
                }
            }

            .content {
                margin: 2rem 0;
                text-transform: uppercase;
                text-align: center;
                font-weight: 600;
                font-size: 1.4rem;

                @media (max-width: $xxl) {
                    font-size: 1.2rem;
                    margin: 1.6rem 0;
                }
                @media (max-width: $lg) {
                    font-size: 1rem;
                    margin: 1rem 0;
                }
                @media (max-width: $md) {
                    margin: 0.5rem 0 1rem 0;
                }
            }
        }
    }

    .actions {
        box-sizing: border-box;
        display: flex;
        flex-flow: column;
        align-items: stretch;
        justify-content: center;

        button {
            margin-bottom: 0.5rem;
            font-size: 1rem;
            padding: 0.5rem 3rem;
            flex: 1;
            max-width: 100%;
            box-sizing: border-box;

            &:last-child {
                margin-bottom: 0;
            }

            @media (max-width: $md) {
                padding: 0.5rem 0rem;
            }

            @media (max-width: $sm) {
                margin-bottom: 0.4rem;
            }
        }
    }
}
</style>
