<template>
    <div class="overlay" v-if="$store.state.app.error.modal">
        <div id="modal-error">
            <div>
                <div>
                    <img src="@/assets/bad.png" alt="" />
                </div>
                <div v-html="$t('message.error_ai', 'fr')"></div>
                <div>
                    <button @click="cancel">{{ $t("message.close", "fr") }}</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: "ModalError",
    methods: {
        cancel() {
            this.$store.dispatch("app/setError", { modal: false });
        },
    },
};
</script>

<style scoped lang="scss">
#modal-error {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-flow: column;
    max-width: 600px;
    margin: auto;

    @media (max-width: $xl) {
        width: 500px;
    }
    @media (max-width: $sm) {
        width: 100%;
    }

    > div {
        background-color: #fff;
        background: no-repeat url(@/assets/bg.jpg) center / cover;
        position: relative;

        > div {
            text-align: center;

            &:first-child {
                padding: 2rem 2rem 1rem;
                box-sizing: border-box;

                @media (max-width: $lg) {
                    padding: 1rem;
                }

                img {
                    max-width:150px;
                    display: block;
                    margin: auto;
                }
            }

            &:nth-child(2) {
                padding: 0rem 2rem 0;
                box-sizing: border-box;
                font-weight: 400;
                font-size: 1.4rem;

                @media (max-width: $lg) {
                    padding: 1rem 1rem 0;
                }
            }

            &:last-child {
                padding: 1rem 2rem 2rem;
                box-sizing: border-box;

                @media (max-width: $lg) {
                    padding: 1rem;
                }

                button {
                    margin: auto;
                }
            }
        }
    }
}
</style>
