<template>
    <div id="spinner" v-if="$store.state.app.spinner">
        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" :width="width" :height="width" viewBox="0 0 32 32">
            <path
                :fill="color"
                d="M16 0c-8.711 0-15.796 6.961-15.995 15.624 0.185-7.558 5.932-13.624 12.995-13.624 7.18 0 13 6.268 13 14 0 1.657 1.343 3 3 3s3-1.343 3-3c0-8.837-7.163-16-16-16zM16 32c8.711 0 15.796-6.961 15.995-15.624-0.185 7.558-5.932 13.624-12.995 13.624-7.18 0-13-6.268-13-14 0-1.657-1.343-3-3-3s-3 1.343-3 3c0 8.837 7.163 16 16 16z"
            ></path>
        </svg>
    </div>
</template>

<script>
export default {
    name: "Spinner",
    props: ["color", "width"],
};
</script>

<style scoped lang="scss">
#spinner {
    position: fixed;
    top: 0;
    right: 8vw;
    left: 8vw;
    bottom: 0;
    background-color: #fff;
    background: no-repeat url(@/assets/bg.jpg) center / cover;
    z-index: 999;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    overflow: hidden;

    @media (max-width: $md) {
        right: 3vw;
        left: 3vw;
    }
    @media (max-width: $sm) {
        right: 1vw;
        left: 1vw;
    }

    svg {
        animation: rotating 2s linear infinite;
    }
}

@keyframes rotating {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}
</style>
