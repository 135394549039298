<template>
    <div id="evaluation">
        <div class="banner-top">
            <div>
                <button @click="runconfirmAbandon" class="btn-black">{{ $t("message.giving_up", evaluation.lang) }}</button>
            </div>

            <div>
                <Logo />
            </div>
        </div>

        <div v-for="(q, idxQ) in questions" :key="idxQ" v-show="idxQuestion === idxQ">
            <!-- Header Question -->
            <div class="question">
                <div>
                    <div>
                        <span class="question-idx">{{ $t("message.question", evaluation.lang) }} {{ idxQuestion + 1 }}/{{ questions.length }}</span>
                        <span class="question-title">{{ q.question }}</span>
                        <span class="question-info">{{ q.type === "QCM" ? $t("message.multiple_answers_possible", evaluation.lang) : $t("message.select_the_correct_answer", evaluation.lang) }}</span>
                    </div>
                    <div>
                        <span
                            v-for="(n, k) in questions.length"
                            :key="n"
                            :class="{
                                active: idxQuestion + 1 === n,
                                good: mode === 'training' && userResponses[k] && userResponses[k].status && (questionValidated || idxQuestion + 1 !== n),
                            }"
                        >
                        </span>
                    </div>
                </div>
            </div>
            <!-- End : Header Question -->

            <!-- Propositions QCM -->
            <template v-if="q.type === 'QCM'">
                <div class="propositions">
                    <div
                        v-for="(p, idxP) in q.propositions"
                        :key="idxP"
                        :class="{
                            good: q.answers.indexOf(p) !== -1 && questionValidated && correction === 'now' && userResponses[idxQ] !== undefined && userResponses[idxQ].responses.indexOf(p) !== -1,
                            bad: q.answers.indexOf(p) === -1 && questionValidated && correction === 'now' && userResponses[idxQ] !== undefined && userResponses[idxQ].responses.indexOf(p) !== -1,
                            validate: questionValidated,
                        }"
                    >
                        <span class="caret caret-right" v-if="q.answers.indexOf(p) !== -1 && questionValidated && correction === 'now'"><FontAwesomeIcon icon="caret-right" /></span>
                        <div>
                            <input type="checkbox" :checked="q.answers.indexOf(p) !== -1" v-if="questionValidated" disabled />
                            <input type="checkbox" :id="`question-${idxQ}-${idxP}`" :checked="userResponses[idxQ] !== undefined && 'responses' in userResponses[idxQ] && userResponses[idxQ].responses.indexOf(p) !== -1" :value="p" @change="onChange($event, idxQ, q.type)" v-if="!questionValidated" />
                            <label :for="`question-${idxQ}-${idxP}`">
                                {{ p }}
                                <span class="check" v-if="q.answers.indexOf(p) === -1 && questionValidated && correction === 'now' && userResponses[idxQ] !== undefined && userResponses[idxQ].responses.indexOf(p) !== -1">
                                    <FontAwesomeIcon icon="xmark" />
                                </span>
                                <span class="check" v-if="q.answers.indexOf(p) !== -1 && questionValidated && correction === 'now'">
                                    <FontAwesomeIcon icon="check" />
                                </span>
                            </label>
                        </div>
                        <span class="caret caret-left" v-if="q.answers.indexOf(p) !== -1 && questionValidated && correction === 'now'"><FontAwesomeIcon icon="caret-left" /></span>
                    </div>
                </div>
            </template>
            <!-- End : Propositions QCM -->

            <!-- Propositions QCU -->
            <template v-if="q.type === 'QCU'">
                <div class="propositions">
                    <div
                        v-for="(p, idxP) in q.propositions"
                        :key="idxP"
                        :class="{
                            good: q.answers.indexOf(p) !== -1 && questionValidated && correction === 'now' && userResponses[idxQ] !== undefined && userResponses[idxQ].responses.indexOf(p) !== -1,
                            bad: q.answers.indexOf(p) === -1 && questionValidated && correction === 'now' && userResponses[idxQ] !== undefined && userResponses[idxQ].responses.indexOf(p) !== -1,
                            validate: questionValidated,
                        }"
                    >
                        <span class="caret caret-right" v-if="q.answers.indexOf(p) !== -1 && questionValidated && correction === 'now'">
                            <FontAwesomeIcon icon="caret-right" />
                        </span>
                        <div>
                            <input type="radio" :checked="q.answers.indexOf(p) !== -1" v-if="questionValidated" disabled />
                            <input
                                type="radio"
                                :id="`question-${idxQ}-${idxP}`"
                                :checked="userResponses[idxQ] !== undefined && 'responses' in userResponses[idxQ] && userResponses[idxQ].responses.indexOf(p) !== -1"
                                :value="p"
                                :name="`question-${idxQ}`"
                                @change="onChange($event, idxQ, q.type)"
                                v-if="!questionValidated"
                            />
                            <label :for="`question-${idxQ}-${idxP}`">
                                {{ p }}
                                <span class="check" v-if="q.answers.indexOf(p) === -1 && questionValidated && correction === 'now' && userResponses[idxQ] !== undefined && userResponses[idxQ].responses.indexOf(p) !== -1">
                                    <FontAwesomeIcon icon="xmark" />
                                </span>
                                <span class="check" v-if="q.answers.indexOf(p) !== -1 && questionValidated && correction === 'now'">
                                    <FontAwesomeIcon icon="check" />
                                </span>
                            </label>
                        </div>
                        <span class="caret caret-left" v-if="q.answers.indexOf(p) !== -1 && questionValidated && correction === 'now'"><FontAwesomeIcon icon="caret-left" /></span>
                    </div>
                </div>
            </template>
            <!-- End : Propositions QCU -->

            <!-- Info -->
            <div v-if="questionValidated && correction === 'now'" class="info">
                <span class="result good" v-if="userResponses[idxQuestion] !== undefined && 'status' in userResponses[idxQuestion] && userResponses[idxQuestion].status === true">{{ $t("message.right_answer") }}</span>
                <span class="result bad" v-if="userResponses[idxQuestion] !== undefined && 'status' in userResponses[idxQuestion] && userResponses[idxQuestion].status === false">{{ $t("message.bad_answer") }}</span>
                <span class="content">{{ q.info }}</span>
            </div>
            <!-- End : Info -->

            <!-- Boutons -->
            <div class="btns">
                <button type="button" @click="onValidated" v-if="!questionValidated && correction === 'now'" :disabled="btnDisabled">{{ $t("message.to_validate", evaluation.lang) }}</button>
                <button type="button" @click="nextPage" v-if="questionValidated || correction !== 'now'" :disabled="btnDisabled">{{ $t("message.next", evaluation.lang) }}</button>
            </div>
            <!-- End : Boutons -->
        </div>
    </div>
</template>

<script>
import Logo from "../components/SVG/Logo.vue";
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { faCheck, faXmark, faCaretRight, faCaretLeft } from "@fortawesome/free-solid-svg-icons";
library.add(faCheck, faXmark, faCaretRight, faCaretLeft);

export default {
    name: "Evaluation",
    data: () => {
        return {
            uResponses: [],
            answerStatus: null,
        };
    },
    components: {
        FontAwesomeIcon,
        Logo,
    },
    computed: {
        // Evaluation
        evaluation: function () {
            return this.$store.state.app.evaluation;
        },
        // Questions
        questions: function () {
            return this.$store.state.app.evaluation.questions;
        },
        // Index questions
        idxQuestion: function () {
            return this.$store.state.app.idxQuestion;
        },
        // Question validée
        questionValidated: function () {
            return this.$store.state.app.questionValidated;
        },
        // Correction
        correction: function () {
            return this.$store.state.app.evaluation.correction;
        },
        // Mode (entrainement ou validation)
        mode: function () {
            return this.$store.state.app.evaluation.mode;
        },
        // Réponses utilisateur
        userResponses: function () {
            return this.$store.state.app.userResponses;
        },
        // Nombre de questions
        numberOfQuestions: function () {
            return this.$store.state.app.evaluation.numberOfQuestions;
        },
        // Status de l'attribut "disable" des boutons "Suivant" et "Valider"
        btnDisabled: function () {
            return this.userResponses[this.idxQuestion] === undefined || (this.userResponses[this.idxQuestion] !== undefined && this.userResponses[this.idxQuestion].responses && this.userResponses[this.idxQuestion].responses.length === 0);
        },
    },
    watch: {
        "$store.state.app.idxQuestion": {
            handler() {
                if (this.correction === "now") this.$store.dispatch("app/setQuestionValidated", false); // remise a false, seulement dans le cas d'une correction après chaque question
            },
        },
        "$store.state.app.questionValidated": {
            handler(questionValidated) {
                if (questionValidated) {
                    let userResponses = this.userResponses;
                    if (userResponses[this.idxQuestion] !== undefined && "status" in userResponses[this.idxQuestion]) this.answerStatus = this.userResponses[this.idxQuestion].status;
                }
            },
        },
    },
    methods: {
        /**
         * Ouvrir la modal d'abandon
         */
        runconfirmAbandon() {
            this.$store.dispatch("app/setAbandon", { modal: true });
        },
        /**
         * Page suivante
         */
        nextPage() {
            // On incrémente de 1 l'index question
            let idxQuestion = this.idxQuestion;
            this.$store.dispatch("app/setIdxQuestion", ++idxQuestion);

            switch (this.mode) {
                case "training":
                    this.nextPageTraining(idxQuestion);
                    break;
                case "validation":
                    this.nextPageValidation(idxQuestion);
                    break;
            }
        },
        /**
         * Page suivante en mode entrainement
         * On réaffiche les questions mal répondues
         */
        nextPageTraining(idxQuestion) {
            let loop = false;
            this.uResponses = this.userResponses;
            let valid = true;

            if (idxQuestion === this.numberOfQuestions) idxQuestion = 0;

            if (this.uResponses && this.uResponses.length > 0) {
                for (let i = idxQuestion; i < this.uResponses.length; i++) {
                    if (this.uResponses[i] === undefined || (this.uResponses[i] !== undefined && !("status" in this.uResponses[i])) || (this.uResponses[i] !== undefined && "status" in this.uResponses[i] && this.uResponses[i].status === false)) {
                        this.$store.dispatch("app/setIdxQuestion", i);
                        this.uResponses[i].responses = [];
                        this.$store.dispatch("app/setUserResponses", this.uResponses);
                        valid = false;
                        // Remise à false, seulement dans le cas d'une correction après chaque question
                        if (this.correction === "now") this.$store.dispatch("app/setQuestionValidated", false);
                        return;
                    }
                    // Sur la dernière boucle, on la relance une nouvelle fois afin de contrôller que toutes les réponses soient correctes
                    if (i === this.uResponses.length - 1 && !loop) {
                        loop = true;
                        i = -1;
                    }
                    // Si toutes les questions sont correctes, on passe à la page du résultat
                    if (i === this.uResponses.length - 1 && valid && loop) this.$store.dispatch("app/setPage", { current: "EVALUATION_RESULTS" });
                }
            }
        },
        /*
         * Page suivante en mode validation
         * On affiche la page des résultats après la dernière question
         */
        nextPageValidation(idxQuestion) {
            if (idxQuestion === this.numberOfQuestions) this.$store.dispatch("app/setPage", { current: "EVALUATION_RESULTS" });
        },
        /*
         * Valider une question
         */
        onValidated() {
            this.$store.dispatch("app/setQuestionValidated", true);
        },
        /**
         * Au clic sur une proposition
         */
        onChange(event, idxQuestion, type) {
            this.uResponses = this.userResponses;
            let question = {};
            question.responses = [event.target.value];
            question.status = false;

            // Si QCM
            if (type === "QCM") {
                // Si il y a des réponses enregistrées
                if (this.uResponses[idxQuestion] !== undefined && type === "QCM") {
                    if (event.target.checked) this.uResponses[idxQuestion].responses = [...new Set([...this.uResponses[idxQuestion].responses, ...question.responses])];
                    else {
                        let idxResponse = this.uResponses[idxQuestion].responses.indexOf(event.target.value);
                        if (this.uResponses[idxQuestion].responses[idxResponse] !== undefined) {
                            this.uResponses[idxQuestion].responses.splice(idxResponse, 1);
                            if (this.uResponses[idxQuestion].responses.length === 0) this.uResponses.splice(idxQuestion, 1);
                        }
                    }
                }
                // Si il n'y a pas de réponses enregistrées
                else this.uResponses[idxQuestion] = question;
            }
            // Si QCU
            if (type === "QCU") this.uResponses[idxQuestion] = question;

            // Mise à jour des réponses dans le store
            this.$store.dispatch("app/setUserResponses", this.uResponses);
        },
    },
};
</script>

<style scoped lang="scss">
#evaluation {
    .banner-top {
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
        align-items: center;
        padding: 1rem 2rem;
        box-sizing: border-box;
        border-bottom: 3px solid $base-color;

        @media (max-width: $lg) {
            padding: 1rem;
            border-bottom: 2px solid $base-color;
        }

        .logo-svg {
            max-width: 300px;
            width: 100%;

            @media (max-width: $xl) {
                max-width: 250px;
            }
            @media (max-width: $lg) {
                max-width: 200px;
            }
        }

        @media (max-width: $md) {
            flex-flow: column-reverse;

            > div {
                width: 100%;

                &:first-child {
                    margin-top: 1rem;
                }
            }
        }
    }

    .question {
        &:after {
            content: "";
            display: block;
            width: 20%;
            border-bottom: 3px solid $base-color;

            @media (max-width: $lg) {
                border-bottom: 2px solid $base-color;
            }
        }

        > div {
            display: flex;
            flex-flow: row nowrap;
            align-items: flex-start;
            justify-content: space-between;
            padding: 2rem;
            box-sizing: border-box;

            @media (max-width: $xl) {
                flex-flow: column-reverse;
            }
            @media (max-width: $lg) {
                padding: 1rem;
            }

            > div {
                &:first-child {
                    display: flex;
                    flex-flow: column;
                }

                &:last-child {
                    display: flex;
                    flex-flow: row wrap;
                    align-items: center;
                    justify-content: flex-start;
                    max-width: 400px;
                    padding-left: 2rem;
                    box-sizing: border-box;

                    @media (max-width: $xl) {
                        max-width: 100%;
                        padding-left: 0;
                        margin-bottom: 1rem;
                    }

                    span {
                        width: 25px;
                        height: 25px;
                        background-color: #ffe0d8;
                        margin-right: 0.5rem;
                        margin-bottom: 0.5rem;

                        @media (max-width: $xl) {
                            width: 20px;
                            height: 20px;
                            margin-right: 0.3rem;
                        }
                        @media (max-width: $lg) {
                            width: 15px;
                            height: 15px;
                            margin-right: 0.2rem;
                        }
                        @media (max-width: $sm) {
                            width: 10px;
                            height: 10px;
                        }

                        &:last-child {
                            margin-right: 0;
                        }

                        &.active {
                            background-color: $base-color;
                        }

                        &.good {
                            background-color: #009640;
                        }
                    }
                }
            }

            .question-idx {
                text-transform: uppercase;
                font-weight: 800;
                font-size: 1.4rem;
                margin-bottom: 1rem;

                @media (max-width: $xxl) {
                    font-size: 1.2rem;
                }
                @media (max-width: $lg) {
                    font-size: 1rem;
                    margin-bottom: 0.5rem;
                }
            }

            .question-title {
                font-weight: 600;
                font-size: 1.4rem;

                @media (max-width: $xxl) {
                    font-size: 1.2rem;
                }
                @media (max-width: $lg) {
                    font-size: 1rem;
                }
            }

            .question-info {
                font-size: 1.4rem;
                font-style: italic;

                @media (max-width: $xxl) {
                    font-size: 1.2rem;
                }
                @media (max-width: $lg) {
                    font-size: 1rem;
                }
            }
        }
    }

    .propositions {
        > div {
            display: flex;
            flex-flow: row nowrap;
            align-items: center;
            justify-content: center;
            margin-bottom: 0.5rem;
            position: relative;
            overflow: hidden;

            .caret {
                font-size: 2rem;
                position: absolute;
                color: #009640;

                @media (max-width: $lg) {
                    font-size: 1.8rem;
                }

                &.caret-right {
                    left: -7px;
                }

                &.caret-left {
                    right: -7px;
                }
            }

            > div {
                padding: 0 2rem;
                box-sizing: border-box;
                flex: 1;

                @media (max-width: $lg) {
                    padding: 0 1rem;
                }
            }

            &:first-child {
                margin-top: 2rem;
            }

            &:last-child {
                margin-bottom: 0;
            }

            label {
                border: 4px solid $base-color;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 1.4rem;
                cursor: pointer;
                padding: 0.5rem 1rem;
                box-sizing: border-box;
                transition: all 0.3s;
                position: relative;
                text-align: center;

                @media (max-width: $lg) {
                    border: 2px solid $base-color;
                }

                .check {
                    position: absolute;
                    right: 1rem;
                    border-radius: 50px;
                    height: 40px;
                    width: 40px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    color: #fff;
                    box-shadow: 0 0 4px 0px rgb(0 0 0 / 28%);

                    @media (max-width: $lg) {
                        height: 30px;
                        width: 30px;
                    }
                    @media (max-width: $sm) {
                        height: 25px;
                        width: 25px;
                    }
                }

                @media (max-width: $xxl) {
                    font-size: 1.2rem;
                }
                @media (max-width: $lg) {
                    font-size: 1rem;
                }
            }

            input {
                opacity: 0;
                position: absolute;
                left: -99999px;

                &:checked + label {
                    background-color: $base-color;
                    color: #fff;
                }
            }

            &.good {
                input + label {
                    border: 4px solid #009640;
                    background-color: #009640;
                    color: #fff;

                    .check {
                        background: rgb(150, 223, 140);
                        background: linear-gradient(127deg, rgba(150, 223, 140, 1) 0%, rgba(22, 174, 68, 1) 100%);
                    }
                }

                @media (max-width: $lg) {
                    input + label {
                        border: 2px solid #009640;
                    }
                }
            }

            &.bad {
                input + label {
                    border: 4px solid #e30613;
                    background-color: #e30613;
                    color: #fff;

                    .check {
                        background: rgb(251, 91, 85);
                        background: linear-gradient(127deg, rgba(251, 91, 85, 1) 0%, rgba(231, 19, 29, 1) 100%);
                    }
                }

                @media (max-width: $lg) {
                    input + label {
                        border: 2px solid #e30613;
                    }
                }
            }

            &.validate {
                label {
                    cursor: default;
                    background-color: transparent;
                    border: 4px solid #e5e5e5;
                    color: #a5a6a6;

                    @media (max-width: $lg) {
                        border: 2px solid #e5e5e5;
                    }
                }
            }

            &.validate:not(.bad):not(.good) {
                input:checked + label {
                    background-color: transparent;
                    border: 4px solid #009640;
                    color: #009640;

                    .check {
                        background: rgb(150, 223, 140);
                        background: linear-gradient(127deg, rgba(150, 223, 140, 1) 0%, rgba(22, 174, 68, 1) 100%);
                    }
                }

                @media (max-width: $lg) {
                    input:checked + label {
                        border: 2px solid #009640;
                    }
                }
            }
        }
    }

    .info {
        padding: 0 2rem;
        margin-top: 2rem;
        box-sizing: border-box;
        display: flex;
        flex-flow: column;

        @media (max-width: $lg) {
            padding: 0 1rem;
        }

        .result {
            text-transform: uppercase;
            font-size: 1.4rem;
            font-weight: 800;

            @media (max-width: $xxl) {
                font-size: 1.2rem;
            }
            @media (max-width: $lg) {
                font-size: 1rem;
            }

            &.good {
                color: #009640;
            }

            &.bad {
                color: #e30613;
            }
        }

        .content {
            font-size: 1.4rem;

            @media (max-width: $xxl) {
                font-size: 1.2rem;
            }
            @media (max-width: $lg) {
                font-size: 1rem;
            }
        }
    }

    .btns {
        margin-top: 2rem;
        display: flex;
        justify-content: center;

        button {
            padding: 0.5rem 6rem;
        }

        @media (max-width: $sm) {
            padding: 0 1rem;

            button {
                width: 100%;
            }
        }
    }
}
</style>
