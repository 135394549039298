<template>
    <svg version="1.1" id="Calque_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 1080 294" style="enable-background: new 0 0 1080 294" xml:space="preserve">
        <linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="282.8444" y1="277.1" x2="704.7156" y2="277.1" gradientTransform="matrix(1 0 0 -1 0 296)">
            <stop offset="0" style="stop-color: #d70000" />
            <stop offset="1" style="stop-color: #f18e24" />
        </linearGradient>
        <rect x="358.5" y="15" class="st0" width="9.6" height="7.8" />
        <linearGradient id="SVGID_00000050632995396763971510000005193563980333484936_" gradientUnits="userSpaceOnUse" x1="282.8444" y1="277.1" x2="704.7156" y2="277.1" gradientTransform="matrix(1 0 0 -1 0 296)">
            <stop offset="0" style="stop-color: #d70000" />
            <stop offset="1" style="stop-color: #f18e24" />
        </linearGradient>
        <rect x="406.3" y="15" style="fill: url(#SVGID_00000050632995396763971510000005193563980333484936_)" width="9.6" height="7.8" />
        <linearGradient id="SVGID_00000088851580464498792490000008826858353387706768_" gradientUnits="userSpaceOnUse" x1="282.8444" y1="269.3" x2="704.7156" y2="269.3" gradientTransform="matrix(1 0 0 -1 0 296)">
            <stop offset="0" style="stop-color: #d70000" />
            <stop offset="1" style="stop-color: #f18e24" />
        </linearGradient>
        <rect x="368.1" y="22.8" style="fill: url(#SVGID_00000088851580464498792490000008826858353387706768_)" width="9.6" height="7.8" />
        <linearGradient id="SVGID_00000137845258636402073290000000391813963548670634_" gradientUnits="userSpaceOnUse" x1="282.8444" y1="269.3" x2="704.7156" y2="269.3" gradientTransform="matrix(1 0 0 -1 0 296)">
            <stop offset="0" style="stop-color: #d70000" />
            <stop offset="1" style="stop-color: #f18e24" />
        </linearGradient>
        <rect x="377.7" y="22.8" style="fill: url(#SVGID_00000137845258636402073290000000391813963548670634_)" width="9.6" height="7.8" />
        <linearGradient id="SVGID_00000147932220739504363220000011924874254472430778_" gradientUnits="userSpaceOnUse" x1="282.8444" y1="269.3" x2="704.7156" y2="269.3" gradientTransform="matrix(1 0 0 -1 0 296)">
            <stop offset="0" style="stop-color: #d70000" />
            <stop offset="1" style="stop-color: #f18e24" />
        </linearGradient>
        <rect x="387.2" y="22.8" style="fill: url(#SVGID_00000147932220739504363220000011924874254472430778_)" width="9.6" height="7.8" />
        <linearGradient id="SVGID_00000136374252318521368680000010739650904163967670_" gradientUnits="userSpaceOnUse" x1="282.8444" y1="269.3" x2="704.7156" y2="269.3" gradientTransform="matrix(1 0 0 -1 0 296)">
            <stop offset="0" style="stop-color: #d70000" />
            <stop offset="1" style="stop-color: #f18e24" />
        </linearGradient>
        <rect x="396.8" y="22.8" style="fill: url(#SVGID_00000136374252318521368680000010739650904163967670_)" width="9.5" height="7.8" />
        <linearGradient id="SVGID_00000111164666513404074940000012391025353035822484_" gradientUnits="userSpaceOnUse" x1="282.8444" y1="261.45" x2="704.7156" y2="261.45" gradientTransform="matrix(1 0 0 -1 0 296)">
            <stop offset="0" style="stop-color: #d70000" />
            <stop offset="1" style="stop-color: #f18e24" />
        </linearGradient>
        <rect x="358.5" y="30.6" style="fill: url(#SVGID_00000111164666513404074940000012391025353035822484_)" width="9.6" height="7.9" />
        <linearGradient id="SVGID_00000083791484855849331130000010479915778718235819_" gradientUnits="userSpaceOnUse" x1="282.8444" y1="261.45" x2="704.7156" y2="261.45" gradientTransform="matrix(1 0 0 -1 0 296)">
            <stop offset="0" style="stop-color: #d70000" />
            <stop offset="1" style="stop-color: #f18e24" />
        </linearGradient>
        <rect x="377.7" y="30.6" style="fill: url(#SVGID_00000083791484855849331130000010479915778718235819_)" width="9.6" height="7.9" />
        <linearGradient id="SVGID_00000147214402902388389650000003479842875980720301_" gradientUnits="userSpaceOnUse" x1="282.8444" y1="261.45" x2="704.7156" y2="261.45" gradientTransform="matrix(1 0 0 -1 0 296)">
            <stop offset="0" style="stop-color: #d70000" />
            <stop offset="1" style="stop-color: #f18e24" />
        </linearGradient>
        <rect x="387.2" y="30.6" style="fill: url(#SVGID_00000147214402902388389650000003479842875980720301_)" width="9.6" height="7.9" />
        <linearGradient id="SVGID_00000137107218557230763810000000501873425356686267_" gradientUnits="userSpaceOnUse" x1="282.8444" y1="261.45" x2="704.7156" y2="261.45" gradientTransform="matrix(1 0 0 -1 0 296)">
            <stop offset="0" style="stop-color: #d70000" />
            <stop offset="1" style="stop-color: #f18e24" />
        </linearGradient>
        <rect x="406.3" y="30.6" style="fill: url(#SVGID_00000137107218557230763810000000501873425356686267_)" width="9.6" height="7.9" />
        <linearGradient id="SVGID_00000089552599320511373010000004623873630659058877_" gradientUnits="userSpaceOnUse" x1="282.8444" y1="253.7" x2="704.7156" y2="253.7" gradientTransform="matrix(1 0 0 -1 0 296)">
            <stop offset="0" style="stop-color: #d70000" />
            <stop offset="1" style="stop-color: #f18e24" />
        </linearGradient>
        <rect x="358.5" y="38.4" style="fill: url(#SVGID_00000089552599320511373010000004623873630659058877_)" width="9.6" height="7.8" />
        <linearGradient id="SVGID_00000157269712004335178130000001469719406531453348_" gradientUnits="userSpaceOnUse" x1="282.8444" y1="253.7" x2="704.7156" y2="253.7" gradientTransform="matrix(1 0 0 -1 0 296)">
            <stop offset="0" style="stop-color: #d70000" />
            <stop offset="1" style="stop-color: #f18e24" />
        </linearGradient>
        <rect x="368.1" y="38.4" style="fill: url(#SVGID_00000157269712004335178130000001469719406531453348_)" width="9.6" height="7.8" />
        <linearGradient id="SVGID_00000177457855095827261160000007874411623160705463_" gradientUnits="userSpaceOnUse" x1="282.8444" y1="253.7" x2="704.7156" y2="253.7" gradientTransform="matrix(1 0 0 -1 0 296)">
            <stop offset="0" style="stop-color: #d70000" />
            <stop offset="1" style="stop-color: #f18e24" />
        </linearGradient>
        <rect x="377.7" y="38.4" style="fill: url(#SVGID_00000177457855095827261160000007874411623160705463_)" width="9.6" height="7.8" />
        <linearGradient id="SVGID_00000129193285709035007780000012395016355070118586_" gradientUnits="userSpaceOnUse" x1="282.8444" y1="253.7" x2="704.7156" y2="253.7" gradientTransform="matrix(1 0 0 -1 0 296)">
            <stop offset="0" style="stop-color: #d70000" />
            <stop offset="1" style="stop-color: #f18e24" />
        </linearGradient>
        <rect x="387.2" y="38.4" style="fill: url(#SVGID_00000129193285709035007780000012395016355070118586_)" width="9.6" height="7.8" />
        <linearGradient id="SVGID_00000142135958814955149320000011436436184845443775_" gradientUnits="userSpaceOnUse" x1="282.8444" y1="253.7" x2="704.7156" y2="253.7" gradientTransform="matrix(1 0 0 -1 0 296)">
            <stop offset="0" style="stop-color: #d70000" />
            <stop offset="1" style="stop-color: #f18e24" />
        </linearGradient>
        <rect x="396.8" y="38.4" style="fill: url(#SVGID_00000142135958814955149320000011436436184845443775_)" width="9.5" height="7.8" />
        <linearGradient id="SVGID_00000142856494767959027860000001285263467989640371_" gradientUnits="userSpaceOnUse" x1="282.8444" y1="253.7" x2="704.7156" y2="253.7" gradientTransform="matrix(1 0 0 -1 0 296)">
            <stop offset="0" style="stop-color: #d70000" />
            <stop offset="1" style="stop-color: #f18e24" />
        </linearGradient>
        <rect x="406.3" y="38.4" style="fill: url(#SVGID_00000142856494767959027860000001285263467989640371_)" width="9.6" height="7.8" />
        <linearGradient id="SVGID_00000085946652171673515980000016009866774469353107_" gradientUnits="userSpaceOnUse" x1="282.8444" y1="245.9" x2="704.7156" y2="245.9" gradientTransform="matrix(1 0 0 -1 0 296)">
            <stop offset="0" style="stop-color: #d70000" />
            <stop offset="1" style="stop-color: #f18e24" />
        </linearGradient>
        <rect x="368.1" y="46.2" style="fill: url(#SVGID_00000085946652171673515980000016009866774469353107_)" width="9.6" height="7.8" />
        <linearGradient id="SVGID_00000089567234205865263220000006495438534411156624_" gradientUnits="userSpaceOnUse" x1="282.8444" y1="245.9" x2="704.7156" y2="245.9" gradientTransform="matrix(1 0 0 -1 0 296)">
            <stop offset="0" style="stop-color: #d70000" />
            <stop offset="1" style="stop-color: #f18e24" />
        </linearGradient>
        <rect x="396.8" y="46.2" style="fill: url(#SVGID_00000089567234205865263220000006495438534411156624_)" width="9.5" height="7.8" />
        <linearGradient id="SVGID_00000082341628806152670370000000550722852301589174_" gradientUnits="userSpaceOnUse" x1="1159.8177" y1="195.6" x2="-158.5459" y2="195.6" gradientTransform="matrix(1 0 0 -1 0 296)">
            <stop offset="0" style="stop-color: #d70000" />
            <stop offset="1" style="stop-color: #f18e24" />
        </linearGradient>
        <path
            style="fill: url(#SVGID_00000082341628806152670370000000550722852301589174_)"
            d="M13,15.3h45.2v69.8h0.5
	c8.2-16.4,23.8-21.2,48.1-21.2c28,0,67.2,1.1,67.2,53v68.6h-45.2v-68.6c0-11.8-9.4-17.7-29.2-17.7H89.1c-19.3,0-30.9,8.9-30.9,26.1
	v60.2H13V15.3z"
        />
        <linearGradient id="SVGID_00000055700091149793461320000011230173174038265734_" gradientUnits="userSpaceOnUse" x1="1159.8177" y1="170.2" x2="-158.5459" y2="170.2" gradientTransform="matrix(1 0 0 -1 0 296)">
            <stop offset="0" style="stop-color: #d70000" />
            <stop offset="1" style="stop-color: #f18e24" />
        </linearGradient>
        <path
            style="fill: url(#SVGID_00000055700091149793461320000011230173174038265734_)"
            d="M185.7,143.7c0-32.8,22.6-34.6,66.4-34.6
	c24.6,0,38.4,0.7,47.2,9.7v-10.6c0-11.8-3.5-16.4-21.2-16.4h-26.6c-13.2,0-17,1.2-17.4,9.7h-43.8c0-32.8,16.4-37.9,54.2-37.9H297
	c31.1,0,47.6,14.8,47.6,43.2v78.9h-42.9v-13.3c-12.4,12.9-17.2,15.6-39.8,15.6H231c-27.5,0-45.2-5.9-45.2-34.2L185.7,143.7z
	M266.3,159.6c31.1,0,33.9-4.8,33.9-12.8s-2.6-12.3-27.6-12.3h-20.1c-19.1,0-23.1,4.3-23.1,14.2c0,8.2,6.1,10.9,23.8,10.9
	L266.3,159.6L266.3,159.6z"
        />
        <linearGradient id="SVGID_00000111191428669097879470000001822028374367933071_" gradientUnits="userSpaceOnUse" x1="1159.8177" y1="170.35" x2="-158.5459" y2="170.35" gradientTransform="matrix(1 0 0 -1 0 296)">
            <stop offset="0" style="stop-color: #d70000" />
            <stop offset="1" style="stop-color: #f18e24" />
        </linearGradient>
        <path style="fill: url(#SVGID_00000111191428669097879470000001822028374367933071_)" d="M409.5,185.5h-45.2V65.8h45.2V185.5z" />
        <linearGradient id="SVGID_00000122691574388575042910000016672098067179120540_" gradientUnits="userSpaceOnUse" x1="1159.8177" y1="195.35" x2="-158.5459" y2="195.35" gradientTransform="matrix(1 0 0 -1 0 296)">
            <stop offset="0" style="stop-color: #d70000" />
            <stop offset="1" style="stop-color: #f18e24" />
        </linearGradient>
        <path
            style="fill: url(#SVGID_00000122691574388575042910000016672098067179120540_)"
            d="M431.5,15.3h45.2v90.1h8.2l39.8-39.6h59.4
	l-60.4,56.3l67.6,63.9H534l-48.3-48h-9v47.5h-45.2V15.3z"
        />
        <linearGradient id="SVGID_00000017494397699711216540000016716368887195952049_" gradientUnits="userSpaceOnUse" x1="1159.8177" y1="170.2" x2="-158.5459" y2="170.2" gradientTransform="matrix(1 0 0 -1 0 296)">
            <stop offset="0" style="stop-color: #d70000" />
            <stop offset="1" style="stop-color: #f18e24" />
        </linearGradient>
        <path
            style="fill: url(#SVGID_00000017494397699711216540000016716368887195952049_)"
            d="M592.4,143.7c0-32.8,22.6-34.6,66.4-34.6
	c24.6,0,38.4,0.7,47.1,9.7v-10.6c0-11.8-3.5-16.4-21.2-16.4h-26.6c-13.2,0-17,1.2-17.4,9.7h-43.8c0-32.8,16.4-37.9,54.2-37.9h52.5
	c31.1,0,47.6,14.8,47.6,43.2v78.9h-42.9v-13.3c-12.5,12.9-17.2,15.6-39.8,15.6h-31c-27.6,0-45.3-5.9-45.3-34.2L592.4,143.7z
	M673,159.6c31.1,0,33.9-4.8,33.9-12.8s-2.6-12.3-27.6-12.3h-20.1c-19.1,0-23.1,4.3-23.1,14.2c0,8.2,6.1,10.9,23.8,10.9L673,159.6
	L673,159.6z"
        />
        <linearGradient id="SVGID_00000003074500349734711930000004746437803465393550_" gradientUnits="userSpaceOnUse" x1="1159.8177" y1="171.2" x2="-158.5459" y2="171.2" gradientTransform="matrix(1 0 0 -1 0 296)">
            <stop offset="0" style="stop-color: #d70000" />
            <stop offset="1" style="stop-color: #f18e24" />
        </linearGradient>
        <path
            style="fill: url(#SVGID_00000003074500349734711930000004746437803465393550_)"
            d="M768.4,65.8h42.4v22.9h0.5
	c5.2-19.1,23.6-24.6,44.1-24.6c32.8,0,45.9,14.2,45.9,47.3c0,3,0,6.4-0.5,10.6h-39.1c0-15.3-3.5-22.6-21.7-22.6
	c-15.6,0-26.4,5.9-26.4,20.2v65.9h-45.2V65.8z"
        />
        <linearGradient id="SVGID_00000067932495107582497020000014447511039854212245_" gradientUnits="userSpaceOnUse" x1="1159.8177" y1="170.2" x2="-158.5459" y2="170.2" gradientTransform="matrix(1 0 0 -1 0 296)">
            <stop offset="0" style="stop-color: #d70000" />
            <stop offset="1" style="stop-color: #f18e24" />
        </linearGradient>
        <path
            style="fill: url(#SVGID_00000067932495107582497020000014447511039854212245_)"
            d="M906.3,143.7c0-32.8,22.6-34.6,66.4-34.6
	c24.6,0,38.4,0.7,47.2,9.7v-10.6c0-11.8-3.5-16.4-21.2-16.4H972c-13.2,0-17,1.2-17.4,9.7h-43.8c0-32.8,16.4-37.9,54.2-37.9h52.6
	c31.1,0,47.6,14.8,47.6,43.2v78.9h-42.8v-13.3c-12.4,12.9-17.2,15.6-39.8,15.6h-30.9c-27.6,0-45.2-5.9-45.2-34.2L906.3,143.7z
	M986.8,159.6c31.1,0,33.9-4.8,33.9-12.8s-2.6-12.3-27.5-12.3h-20.1c-19.1,0-23.1,4.3-23.1,14.2c0,8.2,6.1,10.9,23.8,10.9
	L986.8,159.6L986.8,159.6z"
        />
        <g class="st25">
            <path
                d="M196.2,261.3v-51.2h25.2c7.2,0,12.2,1.6,15,4.7s4.3,8.6,4.3,16.4c0,12.8-1.2,21-3.5,24.6c-2.3,3.6-7.5,5.5-15.6,5.5H196.2z
		M205.9,253.1h13.8c4.7,0,7.7-1.1,9-3.2c1.4-2.1,2-6.9,2-14.2c0-7.6-0.6-12.4-1.8-14.4s-4.1-3.1-8.6-3.1h-14.4V253.1z"
            />
            <path d="M256.1,210.1v7.2h-8.6v-7.2H256.1z M256.1,225.4v35.9h-8.6v-35.9H256.1z" />
            <path
                d="M296.6,225.4V262c0,5.9-1.2,9.9-3.5,12c-2.4,2.2-6.7,3.3-13.1,3.3c-6.2,0-10.3-0.8-12.3-2.5c-2.1-1.7-3.1-4.9-3.1-9.8h8.3
		c0,2.3,0.4,3.8,1.3,4.5c0.9,0.7,2.8,1,5.9,1c5.3,0,8-2.2,8-6.6v-8.1l-0.2,0c-1.5,3.8-5,5.7-10.5,5.7c-5.4,0-9.1-1.3-11.1-3.9
		c-2-2.6-3-7.5-3-14.7c0-6.8,1-11.4,3-14s5.6-3.9,10.8-3.9c5.7,0,9.4,2.1,11.2,6.3h0.2l-0.4-5.9H296.6z M279.7,231.8
		c-3.3,0-5.4,0.7-6.4,2c-0.9,1.3-1.4,4.4-1.4,9.1c0,5.1,0.5,8.3,1.4,9.7c0.9,1.4,3.1,2.1,6.5,2.1s5.6-0.7,6.6-2.2
		c1-1.4,1.5-4.6,1.5-9.5c0-4.7-0.5-7.8-1.5-9.2C285.4,232.5,283.1,231.8,279.7,231.8z"
            />
            <path d="M313.7,210.1v7.2h-8.6v-7.2H313.7z M313.7,225.4v35.9h-8.6v-35.9H313.7z" />
            <path
                d="M345.2,225.4v6.5h-13.8v18c0,3.3,1.3,5,3.8,5c2.8,0,4.2-2,4.2-6v-1.4h7.3v1.8c0,1.7-0.1,3.1-0.1,4.2
		c-0.5,5.5-4.6,8.2-12.2,8.2c-7.7,0-11.5-3.5-11.5-10.5v-19.2h-4.7v-6.5h4.7v-8h8.6v8H345.2z"
            />
            <path
                d="M360.4,235.8h-8.8c0-4.3,1-7.2,3-8.6c2-1.5,6-2.2,11.9-2.2c6.5,0,10.8,0.9,13.1,2.7c2.3,1.8,3.4,5.2,3.4,10.2v23.5h-8.6
		l0.4-4.9l-0.2,0c-1.7,3.6-5.5,5.4-11.4,5.4c-8.7,0-13-3.7-13-11.1c0-7.5,4.4-11.2,13.3-11.2c5.9,0,9.5,1.4,10.8,4.1h0.1v-5.8
		c0-2.8-0.5-4.7-1.5-5.6s-3-1.4-6-1.4C362.6,230.8,360.4,232.5,360.4,235.8z M366.1,245.2c-4.9,0-7.3,1.7-7.3,5
		c0,2.3,0.5,3.9,1.5,4.6c1,0.7,3.1,1.1,6.3,1.1c5.2,0,7.8-1.8,7.8-5.3C374.5,247,371.7,245.2,366.1,245.2z"
            />
            <path d="M400.1,210.1v51.2h-8.6v-51.2H400.1z" />
            <path d="M437.8,210.1v42.5h24v8.7h-33.7v-51.2H437.8z" />
            <path
                d="M489.6,249.8h8.6v1.4c0,7-5.3,10.5-15.8,10.5c-7.1,0-11.8-1.2-14-3.6s-3.3-7.6-3.3-15.4c0-7,1.2-11.7,3.5-14.1
		c2.3-2.4,6.8-3.6,13.5-3.6c6.4,0,10.7,1.2,12.9,3.5c2.2,2.3,3.3,6.9,3.3,13.8v2.6h-24.3c-0.1,0.8-0.1,1.3-0.1,1.6
		c0,3.5,0.5,5.9,1.6,7.1c1.1,1.2,3.3,1.8,6.5,1.8c3.2,0,5.2-0.3,6.2-1C489.1,253.6,489.6,252.1,489.6,249.8z M489.6,239.6l0-1.4
		c0-2.9-0.5-4.7-1.5-5.6c-1-0.8-3.1-1.3-6.4-1.3c-3.2,0-5.3,0.5-6.2,1.5c-1,1-1.4,3.3-1.4,6.7H489.6z"
            />
            <path
                d="M514.3,235.8h-8.8c0-4.3,1-7.2,3-8.6c2-1.5,6-2.2,11.9-2.2c6.5,0,10.8,0.9,13.1,2.7c2.3,1.8,3.4,5.2,3.4,10.2v23.5h-8.6
		l0.4-4.9l-0.2,0c-1.7,3.6-5.5,5.4-11.4,5.4c-8.7,0-13-3.7-13-11.1c0-7.5,4.4-11.2,13.3-11.2c5.9,0,9.5,1.4,10.8,4.1h0.1v-5.8
		c0-2.8-0.5-4.7-1.5-5.6s-3-1.4-6-1.4C516.5,230.8,514.3,232.5,514.3,235.8z M520,245.2c-4.9,0-7.3,1.7-7.3,5c0,2.3,0.5,3.9,1.5,4.6
		c1,0.7,3.1,1.1,6.3,1.1c5.2,0,7.8-1.8,7.8-5.3C528.4,247,525.6,245.2,520,245.2z"
            />
            <path
                d="M545.7,225.4h8.6l-0.5,5l0.2,0c2.1-3.8,5.3-5.6,9.7-5.6c6.3,0,9.5,4,9.5,11.9v2.5H565c0.1-1,0.2-1.6,0.2-1.9
		c0-3.8-1.5-5.7-4.5-5.7c-4.3,0-6.4,2.8-6.4,8.5v21.1h-8.6V225.4z"
            />
            <path
                d="M577.8,225.4h8.5l-0.3,6l0.2,0c1.7-4.3,5.4-6.5,11.3-6.5c8.5,0,12.7,4,12.7,11.9v24.4h-8.6v-21.6v-1.4l-0.2-2.5
		c-0.4-2.7-2.5-4-6.3-4c-5.8,0-8.7,2.7-8.7,8.2v21.3h-8.6V225.4z"
            />
            <path d="M627.2,210.1v7.2h-8.6v-7.2H627.2z M627.2,225.4v35.9h-8.6v-35.9H627.2z" />
            <path
                d="M635.6,225.4h8.5l-0.3,6l0.2,0c1.7-4.3,5.4-6.5,11.3-6.5c8.5,0,12.7,4,12.7,11.9v24.4h-8.6v-21.6v-1.4l-0.2-2.5
		c-0.4-2.7-2.5-4-6.3-4c-5.8,0-8.7,2.7-8.7,8.2v21.3h-8.6V225.4z"
            />
            <path
                d="M708.5,225.4V262c0,5.9-1.2,9.9-3.5,12c-2.4,2.2-6.7,3.3-13.1,3.3c-6.2,0-10.3-0.8-12.3-2.5c-2.1-1.7-3.1-4.9-3.1-9.8h8.3
		c0,2.3,0.4,3.8,1.3,4.5c0.9,0.7,2.8,1,5.9,1c5.3,0,8-2.2,8-6.6v-8.1l-0.2,0c-1.5,3.8-5,5.7-10.5,5.7c-5.4,0-9.1-1.3-11.1-3.9
		c-2-2.6-3-7.5-3-14.7c0-6.8,1-11.4,3-14c2-2.6,5.6-3.9,10.8-3.9c5.7,0,9.4,2.1,11.2,6.3h0.2l-0.4-5.9H708.5z M691.5,231.8
		c-3.3,0-5.4,0.7-6.4,2c-0.9,1.3-1.4,4.4-1.4,9.1c0,5.1,0.5,8.3,1.4,9.7c0.9,1.4,3.1,2.1,6.5,2.1s5.6-0.7,6.6-2.2
		c1-1.4,1.5-4.6,1.5-9.5c0-4.7-0.5-7.8-1.5-9.2C697.2,232.5,695,231.8,691.5,231.8z"
            />
            <path
                d="M736.4,261.3v-51.2h25.2c7.2,0,12.2,1.6,15,4.7c2.9,3.1,4.3,8.6,4.3,16.4c0,12.8-1.2,21-3.5,24.6s-7.5,5.5-15.6,5.5H736.4z
		M746.1,253.1h13.8c4.7,0,7.7-1.1,9-3.2c1.4-2.1,2-6.9,2-14.2c0-7.6-0.6-12.4-1.8-14.4s-4.1-3.1-8.6-3.1h-14.4V253.1z"
            />
            <path
                d="M810.8,249.8h8.6v1.4c0,7-5.3,10.5-15.8,10.5c-7.1,0-11.8-1.2-14-3.6s-3.3-7.6-3.3-15.4c0-7,1.2-11.7,3.5-14.1
		c2.3-2.4,6.8-3.6,13.5-3.6c6.4,0,10.7,1.2,12.9,3.5c2.2,2.3,3.3,6.9,3.3,13.8v2.6h-24.3c-0.1,0.8-0.1,1.3-0.1,1.6
		c0,3.5,0.5,5.9,1.6,7.1c1.1,1.2,3.3,1.8,6.5,1.8c3.2,0,5.2-0.3,6.2-1C810.3,253.6,810.8,252.1,810.8,249.8z M810.9,239.6l0-1.4
		c0-2.9-0.5-4.7-1.5-5.6c-1-0.8-3.1-1.3-6.4-1.3c-3.2,0-5.3,0.5-6.2,1.5c-1,1-1.4,3.3-1.4,6.7H810.9z"
            />
            <path
                d="M856.6,235.3h-8.4c0-0.3-0.1-0.5-0.1-0.7c-0.2-1.7-0.7-2.8-1.5-3.2c-0.8-0.4-2.8-0.6-5.9-0.6c-4.5,0-6.7,1.5-6.7,4.4
		c0,2,0.4,3.2,1.2,3.5c0.8,0.4,3.5,0.7,8,0.9c6.1,0.3,10.1,1.1,12,2.4c1.9,1.3,2.8,4,2.8,8.1c0,4.4-1.2,7.4-3.7,9.1
		s-6.9,2.5-13.3,2.5c-6.1,0-10.3-0.8-12.6-2.3c-2.3-1.5-3.4-4.4-3.4-8.6V250h8.9c-0.1,0.5-0.2,0.9-0.2,1.2c-0.4,3.2,2.1,4.8,7.4,4.8
		c5.4,0,8.1-1.6,8.1-4.7c0-3-1.7-4.5-5.1-4.5c-7.7,0-12.7-0.7-15.2-2.2c-2.4-1.5-3.7-4.5-3.7-9c0-4.1,1.1-6.9,3.3-8.3
		c2.2-1.5,6.5-2.2,12.8-2.2c6,0,10,0.7,12.1,2.1C855.5,228.5,856.6,231.2,856.6,235.3z"
            />
            <path d="M873.2,210.1v7.2h-8.6v-7.2H873.2z M873.2,225.4v35.9h-8.6v-35.9H873.2z" />
            <path
                d="M913.7,225.4V262c0,5.9-1.2,9.9-3.5,12c-2.4,2.2-6.7,3.3-13.1,3.3c-6.2,0-10.3-0.8-12.3-2.5c-2.1-1.7-3.1-4.9-3.1-9.8h8.3
		c0,2.3,0.4,3.8,1.3,4.5s2.8,1,5.9,1c5.3,0,8-2.2,8-6.6v-8.1l-0.2,0c-1.5,3.8-5,5.7-10.5,5.7c-5.4,0-9.1-1.3-11.1-3.9
		c-2-2.6-3-7.5-3-14.7c0-6.8,1-11.4,3-14c2-2.6,5.6-3.9,10.8-3.9c5.7,0,9.4,2.1,11.2,6.3h0.2l-0.4-5.9H913.7z M896.8,231.8
		c-3.3,0-5.4,0.7-6.4,2c-0.9,1.3-1.4,4.4-1.4,9.1c0,5.1,0.5,8.3,1.4,9.7c0.9,1.4,3.1,2.1,6.5,2.1s5.6-0.7,6.6-2.2
		c1-1.4,1.5-4.6,1.5-9.5c0-4.7-0.5-7.8-1.5-9.2C902.5,232.5,900.3,231.8,896.8,231.8z"
            />
            <path
                d="M922.2,225.4h8.5l-0.3,6l0.2,0c1.7-4.3,5.4-6.5,11.3-6.5c8.5,0,12.7,4,12.7,11.9v24.4h-8.6v-21.6v-1.4l-0.2-2.5
		c-0.4-2.7-2.5-4-6.3-4c-5.8,0-8.7,2.7-8.7,8.2v21.3h-8.6V225.4z"
            />
            <path
                d="M986.1,249.8h8.6v1.4c0,7-5.3,10.5-15.8,10.5c-7.1,0-11.8-1.2-14-3.6s-3.3-7.6-3.3-15.4c0-7,1.2-11.7,3.5-14.1
		c2.3-2.4,6.8-3.6,13.5-3.6c6.4,0,10.7,1.2,12.9,3.5c2.2,2.3,3.3,6.9,3.3,13.8v2.6h-24.3c-0.1,0.8-0.1,1.3-0.1,1.6
		c0,3.5,0.5,5.9,1.6,7.1c1.1,1.2,3.3,1.8,6.5,1.8c3.2,0,5.2-0.3,6.2-1C985.6,253.6,986.1,252.1,986.1,249.8z M986.1,239.6l0-1.4
		c0-2.9-0.5-4.7-1.5-5.6c-1-0.8-3.1-1.3-6.4-1.3c-3.2,0-5.3,0.5-6.2,1.5c-1,1-1.4,3.3-1.4,6.7H986.1z"
            />
            <path
                d="M1002.1,225.4h8.6l-0.5,5l0.2,0c2.1-3.8,5.3-5.6,9.7-5.6c6.3,0,9.5,4,9.5,11.9v2.5h-8.1c0.1-1,0.1-1.6,0.1-1.9
		c0-3.8-1.5-5.7-4.5-5.7c-4.3,0-6.4,2.8-6.4,8.5v21.1h-8.6V225.4z"
            />
            <path
                d="M1064.2,235.3h-8.4c0-0.3-0.1-0.5-0.1-0.7c-0.2-1.7-0.7-2.8-1.5-3.2c-0.8-0.4-2.8-0.6-5.9-0.6c-4.5,0-6.7,1.5-6.7,4.4
		c0,2,0.4,3.2,1.2,3.5c0.8,0.4,3.5,0.7,8,0.9c6.1,0.3,10.1,1.1,12,2.4c1.9,1.3,2.8,4,2.8,8.1c0,4.4-1.2,7.4-3.7,9.1
		s-6.9,2.5-13.3,2.5c-6.1,0-10.3-0.8-12.6-2.3c-2.3-1.5-3.4-4.4-3.4-8.6V250h8.9c-0.1,0.5-0.2,0.9-0.2,1.2c-0.4,3.2,2.1,4.8,7.4,4.8
		c5.4,0,8.1-1.6,8.1-4.7c0-3-1.7-4.5-5.1-4.5c-7.7,0-12.7-0.7-15.2-2.2c-2.4-1.5-3.7-4.5-3.7-9c0-4.1,1.1-6.9,3.3-8.3
		c2.2-1.5,6.5-2.2,12.8-2.2c6,0,10,0.7,12.1,2.1C1063.1,228.5,1064.2,231.2,1064.2,235.3z"
            />
        </g>
    </svg>
</template>

<script>
export default {
    name: "LogoHaikara",
};
</script>

<style scoped>
.st0 {
    fill: url(#SVGID_1_);
}
.st1 {
    fill: url(#SVGID_00000061439251480190467290000013535181130673027244_);
}
.st2 {
    fill: url(#SVGID_00000031193344651605669920000004769083068034263733_);
}
.st3 {
    fill: url(#SVGID_00000027602494974799465710000000707564147004562585_);
}
.st4 {
    fill: url(#SVGID_00000047745385917981705580000003931701611771811774_);
}
.st5 {
    fill: url(#SVGID_00000057870535871893986960000013216996008206680716_);
}
.st6 {
    fill: url(#SVGID_00000071518766927240476520000003195348729406309036_);
}
.st7 {
    fill: url(#SVGID_00000179624666305260150670000009808622472264931248_);
}
.st8 {
    fill: url(#SVGID_00000013899090066227852570000013354307401964108680_);
}
.st9 {
    fill: url(#SVGID_00000103248576877106367090000008652747098973412746_);
}
.st10 {
    fill: url(#SVGID_00000012431619596703073340000004498535729134917012_);
}
.st11 {
    fill: url(#SVGID_00000086660206945992810500000016369795703866049933_);
}
.st12 {
    fill: url(#SVGID_00000117666892791617289170000007571783582788204428_);
}
.st13 {
    fill: url(#SVGID_00000117651205655151754950000006174925115989563805_);
}
.st14 {
    fill: url(#SVGID_00000008848832817318216130000005469402550760211129_);
}
.st15 {
    fill: url(#SVGID_00000034086816529968683240000008850551401175942038_);
}
.st16 {
    fill: url(#SVGID_00000048498784668630170040000004915926689255489956_);
}
.st17 {
    fill: url(#SVGID_00000059280607422607791850000001566400953346803072_);
}
.st18 {
    fill: url(#SVGID_00000012463131644298168210000015723545818596699062_);
}
.st19 {
    fill: url(#SVGID_00000170259103693949024360000012598167925156507048_);
}
.st20 {
    fill: url(#SVGID_00000034070015910792153320000018306777021278012328_);
}
.st21 {
    fill: url(#SVGID_00000026867556521804593650000004318254591054137220_);
}
.st22 {
    fill: url(#SVGID_00000118360363021233627190000007043110071174159281_);
}
.st23 {
    fill: url(#SVGID_00000085212506108650908590000016222288347809459114_);
}
.st24 {
    fill: url(#SVGID_00000131330925499804553450000011551323400855036310_);
}
.st25 {
    enable-background: new;
}
</style>
