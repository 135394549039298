<template>
    <div class="logo-svg">
        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" viewBox="0 0 474 140" v-if="$i18n.locale === 'fr'">
            <defs>
                <linearGradient id="Dégradé_sans_nom" data-name="Dégradé sans nom" x1="147.23" y1="-834" x2="-7.07" y2="-834" gradientTransform="translate(0 -764) scale(1 -1)" gradientUnits="userSpaceOnUse">
                    <stop offset="0" stop-color="#f7941e" />
                    <stop offset="1" stop-color="#ec008c" />
                </linearGradient>
                <linearGradient id="Dégradé_sans_nom_2" data-name="Dégradé sans nom 2" x1="129.26" y1="-811.42" x2="151.15" y2="-779.63" gradientTransform="translate(0 -764) scale(1 -1)" gradientUnits="userSpaceOnUse">
                    <stop offset="0" stop-color="#f7941e" />
                    <stop offset="1" stop-color="#ec008c" />
                </linearGradient>
                <linearGradient id="Dégradé_sans_nom_3" data-name="Dégradé sans nom 3" x1="98" y1="-763.38" x2="79.5" y2="-791.57" gradientTransform="translate(0 -764) scale(1 -1)" gradientUnits="userSpaceOnUse">
                    <stop offset="0" stop-color="#fff" />
                    <stop offset=".02" stop-color="#f8f8f8" />
                    <stop offset=".37" stop-color="#8f8f8f" />
                    <stop offset=".65" stop-color="#424242" />
                    <stop offset=".84" stop-color="#121212" />
                    <stop offset=".94" stop-color="#000" />
                </linearGradient>
                <linearGradient id="Dégradé_sans_nom_4" data-name="Dégradé sans nom 4" x1="325.82" y1="-780.78" x2="257.5" y2="-844.08" gradientTransform="translate(0 -764) scale(1 -1)" gradientUnits="userSpaceOnUse">
                    <stop offset="0" stop-color="#f7941e" />
                    <stop offset="1" stop-color="#ec008c" />
                </linearGradient>
                <linearGradient id="Dégradé_sans_nom_5" data-name="Dégradé sans nom 5" x1="315.76" y1="-769.93" x2="247.43" y2="-833.24" gradientTransform="translate(0 -764) scale(1 -1)" gradientUnits="userSpaceOnUse">
                    <stop offset="0" stop-color="#f7941e" />
                    <stop offset="1" stop-color="#ec008c" />
                </linearGradient>
            </defs>
            <g>
                <g id="Calque_1">
                    <g id="Calque_1-2" data-name="Calque_1">
                        <g id="Calque_1-2">
                            <g class="cls-4">
                                <g>
                                    <g>
                                        <g>
                                            <path
                                                class="cls-6"
                                                d="M134.65,80.3c-6.28,12.91-18.03,21.59-29.92,22.1-11.37.46-18.38-3.82-22.24-7.53-6.32-6.06-9.5-15.05-9.5-26.76,0-8.6-7-15.62-15.61-15.62-9.54,0-17.11,8.62-15.35,18.47,1.09,6.12,5.9,11.14,11.97,12.42,1.91.4,3.83.46,5.67.19,4.4-.65,8.69,1.92,10.58,5.95.03.07.06.13.09.2,3.07,6.44-.91,14.08-7.96,15.05-4.33.58-8.82.41-13.29-.58-14.93-3.34-26.38-15.98-28.34-31.17-2.91-22.56,14.63-41.88,36.62-41.88,20.36,0,36.94,16.58,36.94,36.97,0,5.41,1.07,9.56,2.93,11.34,1.26,1.2,3.47,1.74,6.59,1.61,2.76-.12,7.48-1.45,12.59-9.56,5.89-9.35,8.8-43.23-21.29-62.7C80.91-.4,63.02-2.53,47.04,3.09,18.37,13.17-1.82,41.33.14,73.91c2.09,34.99,30.29,63.5,65.21,65.91,41.1,2.84,75.32-29.71,75.32-70.26,0-2.49-.13-4.94-.38-7.37-1.18,8.23-3.86,14.39-5.66,18.09h.02v.02h0Z"
                                            />
                                            <ellipse class="cls-3" cx="138.8" cy="33.56" rx="13.32" ry="13.34" />
                                        </g>
                                        <path
                                            class="cls-5"
                                            d="M80.44,26.42c19.62,9.23,20.23,26.65,17.23,39.78.03.63.05,1.26.05,1.9,0,5.6,1.23,8.25,1.87,8.87.12.11.85.69,3.32.69.25,0,.5,0,.77-.02,4.1-.17,7.62-4.43,9.85-7.96,2.12-3.37,4.65-13.17,2.39-25.08-1.81-9.51-7.21-22.92-22.64-32.92-8.35-5.39-18.05-8.26-28.08-8.26-5.84,0-11.57.98-17.03,2.89-13.42,4.72-25.22,13.83-33.24,25.67,0,0,31.45-21.58,65.51-5.56h.01-.01Z"
                                        />
                                    </g>
                                    <g>
                                        <g>
                                            <path class="cls-1" d="M291.84,68.85v-36.79h8.77v36.79h-8.77ZM296.33,17.53c2.99,0,5.34,2.35,5.34,5.34,0,3.2-2.35,5.56-5.34,5.56-3.21,0-5.56-2.35-5.56-5.56,0-2.99,2.35-5.34,5.56-5.34Z" />
                                            <path
                                                class="cls-7"
                                                d="M401.51,48.54c0,3.64,1.07,6.42,2.99,8.56,1.93,1.93,4.5,2.99,7.91,2.99,3.21,0,5.78-1.07,7.91-3.2,1.93-2.14,2.78-4.92,2.78-8.56,0-3.2-.86-5.78-2.99-7.91-2.14-2.14-4.71-3.2-7.7-3.2-3.21,0-5.78,1.07-7.91,3.2-1.93,2.14-2.99,4.92-2.99,8.12ZM392.3,48.54c0-2.57.42-5.13,1.5-7.49,1.07-2.57,2.35-4.71,4.27-6.63,1.93-1.93,4.06-3.42,6.42-4.27,2.56-1.07,4.92-1.71,7.7-1.71,2.56,0,5.13.65,7.49,1.5,2.35.86,4.5,2.35,6.42,4.06,2.14,1.93,3.63,4.06,4.71,6.63,1.07,2.57,1.71,5.13,1.71,7.7,0,2.99-.65,5.78-1.5,8.13-.86,2.35-2.36,4.5-4.28,6.42-1.93,2.14-4.06,3.64-6.42,4.5-2.56,1.07-5.13,1.5-8.13,1.5-2.78,0-5.35-.43-7.7-1.5-2.36-1.07-4.71-2.57-6.63-4.5-1.71-2.14-3.21-4.28-4.06-6.63-1.08-2.35-1.5-4.92-1.5-7.7h0Z"
                                            />
                                            <path class="cls-7" d="M350.22,57.74V18.17h8.98v12.41h5.78v7.91h-5.78v19.25c0,2.14.86,3.2,3.21,3.2h2.57v7.91h-3.43c-5.99,0-11.34-3.43-11.34-11.13h.01v.02h0Z" />
                                            <path
                                                class="cls-2"
                                                d="M263.36,29.08c-10.91,0-19.84,8.98-19.84,19.89s9.01,19.89,19.92,19.89c4.06,0,8.3-1.28,11.15-3.42v3.25h8.55v-19.71c0-10.91-8.87-19.89-19.78-19.89h0ZM263.47,60.1c-6.2,0-11.12-4.92-11.12-11.13s4.92-11.12,11.12-11.12,11.12,4.92,11.12,11.12-4.92,11.13-11.12,11.13Z"
                                            />
                                            <path class="cls-7" d="M448.24,68.85v-22.46c0-4.27,3.64-7.91,7.91-7.91h0c4.5,0,8.14,3.64,8.14,7.91v22.46h8.77v-22.24c0-9.19-7.49-16.89-16.9-16.89h0c-9.19,0-16.9,7.7-16.9,16.89v22.24h8.98-.01,0Z" />
                                            <path
                                                class="cls-7"
                                                d="M180.76,29.08c-10.91,0-19.86,8.98-19.86,19.89s8.94,20.11,19.85,20.11c4.27,0,8.44-1.28,11.28-3.64v17.92h8.55v-34.39c0-10.91-8.7-19.89-19.82-19.89h0ZM180.83,60.31c-6.2,0-11.12-5.13-11.12-11.34s4.92-11.12,11.12-11.12,11.33,5.13,11.33,11.12-5.13,11.34-11.33,11.34Z"
                                            />
                                            <path class="cls-7" d="M213.78,29.93v22.24c0,4.5,3.64,8.12,7.91,8.12h0c4.5,0,8.14-3.64,8.14-8.12v-22.24h8.77v22.03c0,9.41-7.49,16.89-16.9,16.89h0c-9.19,0-16.9-7.49-16.9-16.89v-22.03h8.98,0Z" />
                                            <path
                                                class="cls-7"
                                                d="M309.36,56.24c0,8.35,8.56,12.41,17.12,12.62,11.33,0,16.47-5.78,16.68-11.55.21-8.98-8.35-11.34-16.05-12.41-5.13-.86-7.7-1.28-7.49-3.85,0-2.57,4.06-3.2,6.42-3.2,2.78,0,6.85.86,6.85,4.28h8.77c-.21-7.49-6.42-12.41-15.4-12.62-8.98,0-15.18,4.27-15.62,11.12-.86,10.27,11.34,11.97,15.18,12.62,5.13.65,7.91,1.71,8.35,3.43.43,2.78-2.57,3.85-7.49,3.85-6.42,0-8.14-2.99-8.56-4.28h-8.77.01,0Z"
                                            />
                                            <path class="cls-7" d="M374.19,68.85v-36.79h8.77v36.79h-8.77ZM378.68,17.53c2.99,0,5.34,2.35,5.34,5.34,0,3.2-2.35,5.56-5.34,5.56-3.21,0-5.56-2.35-5.56-5.56,0-2.99,2.35-5.34,5.56-5.34Z" />
                                        </g>
                                        <g>
                                            <path
                                                class="cls-7"
                                                d="M179.17,103.61c-.79-1.22-1.75-2.14-2.89-2.76-1.15-.62-2.44-.94-3.87-.94-2.4,0-4.36.81-5.86,2.43-1.51,1.62-2.26,3.72-2.26,6.31,0,1.16.2,2.25.6,3.27.4,1.02.99,1.94,1.75,2.72.78.79,1.64,1.37,2.6,1.76s2.02.58,3.16.58c2.17,0,3.93-.63,5.29-1.91s2.06-2.94,2.1-5.01h-6.67v-1.52h8.61v.21c0,3.08-.84,5.5-2.52,7.25s-4,2.62-6.96,2.62-5.31-.94-7.13-2.8c-1.81-1.86-2.72-4.31-2.72-7.35,0-1.39.23-2.71.69-3.95s1.12-2.32,1.99-3.24c.94-1,1.99-1.74,3.17-2.24s2.49-.74,3.93-.74c1.97,0,3.65.35,5.05,1.07,1.4.71,2.48,1.75,3.24,3.12l-1.28,1.11h-.02Z"
                                            />
                                            <path class="cls-7" d="M187.86,118.09v-19.35h9.59v1.65h-7.82v6.06h7.82v1.65h-7.82v8.35h7.82v1.65h-9.59ZM190.85,96.83l2.81-4.63h2.17l-3.74,4.63h-1.24,0Z" />
                                            <path class="cls-7" d="M204.2,118.09v-20.17l13.71,16.2v-15.37h1.64v19.93l-13.7-16.22v15.65h-1.65v-.02h0Z" />
                                            <path class="cls-7" d="M226.81,118.09v-19.35h9.59v1.65h-7.82v6.06h7.82v1.65h-7.82v8.35h7.82v1.65h-9.59ZM229.8,96.83l2.81-4.63h2.17l-3.74,4.63h-1.24Z" />
                                            <path
                                                class="cls-7"
                                                d="M254.9,118.09h-2.14l-6.79-9.22h-1.06v9.22h-1.75v-19.35h3.56c2.1,0,3.67.43,4.72,1.31s1.56,2.2,1.56,3.96c0,1.51-.44,2.69-1.34,3.56s-2.13,1.3-3.7,1.3l6.96,9.22h-.02,0ZM244.9,100.27v7.16h1.77c1.64,0,2.78-.26,3.43-.79.65-.52.97-1.42.97-2.68,0-1.33-.33-2.28-1.01-2.84s-1.8-.85-3.4-.85h-1.77.01Z"
                                            />
                                            <path class="cls-7" d="M257.23,118.09l8.5-20.12,8.15,20.12h-1.92l-2.47-6.56h-7.9l-2.56,6.56h-1.8ZM262.18,110.03h6.74l-3.31-8.42-3.44,8.42h.01Z" />
                                            <path class="cls-7" d="M280.43,118.09v-17.7h-5.33v-1.65h12.43v1.65h-5.34v17.7h-1.76Z" />
                                            <path class="cls-7" d="M292.89,118.09v-19.35h9.59v1.65h-7.82v6.06h7.82v1.65h-7.82v8.35h7.82v1.65h-9.59Z" />
                                            <path
                                                class="cls-7"
                                                d="M309.14,98.74h1.73v12.64c0,1.91.43,3.34,1.31,4.26s2.21,1.39,4,1.39,3.09-.46,3.96-1.39c.88-.93,1.31-2.35,1.31-4.26v-12.64h1.75v12.06c0,2.62-.58,4.58-1.74,5.87s-2.92,1.94-5.29,1.94-4.13-.65-5.3-1.94c-1.16-1.29-1.74-3.25-1.74-5.87v-12.06h.01,0Z"
                                            />
                                            <path
                                                class="cls-7"
                                                d="M342.16,118.09h-2.14l-6.79-9.22h-1.06v9.22h-1.75v-19.35h3.56c2.1,0,3.67.43,4.72,1.31s1.56,2.2,1.56,3.96c0,1.51-.44,2.69-1.34,3.56s-2.13,1.3-3.7,1.3l6.96,9.22h-.02,0ZM332.16,100.27v7.16h1.77c1.64,0,2.78-.26,3.43-.79.65-.52.97-1.42.97-2.68,0-1.33-.33-2.28-1.01-2.84s-1.8-.85-3.4-.85h-1.77.01Z"
                                            />
                                            <path
                                                class="cls-7"
                                                d="M357.37,118.09v-19.35h3.01c2.69,0,4.6.13,5.73.39s2.1.72,2.91,1.36c1.08.86,1.9,1.96,2.46,3.31s.85,2.89.85,4.63-.28,3.28-.85,4.64c-.56,1.35-1.37,2.44-2.43,3.25-.84.65-1.79,1.1-2.87,1.37s-2.81.41-5.19.41h-3.62ZM359.13,116.57h2.47c2,0,3.42-.11,4.25-.33s1.54-.6,2.13-1.14c.81-.76,1.42-1.7,1.83-2.83s.63-2.42.63-3.86-.21-2.78-.63-3.91-1.04-2.06-1.85-2.77c-.61-.55-1.37-.95-2.26-1.18s-2.45-.34-4.67-.34h-1.91v16.38h0v-.02h.01Z"
                                            />
                                            <path class="cls-7" d="M378.67,118.09v-19.35h9.59v1.65h-7.82v6.06h7.82v1.65h-7.82v8.35h7.82v1.65h-9.59Z" />
                                            <path
                                                class="cls-7"
                                                d="M425.22,119.44h-2.15l-2.36-2.72c-.94.59-1.91,1.05-2.92,1.34s-2.11.44-3.28.44c-1.37,0-2.66-.25-3.89-.75-1.23-.49-2.32-1.21-3.27-2.15-.99-.98-1.74-2.08-2.26-3.3-.51-1.22-.78-2.51-.78-3.88s.26-2.67.78-3.9c.51-1.23,1.27-2.32,2.26-3.28.97-.95,2.08-1.68,3.34-2.19,1.25-.5,2.58-.76,3.98-.76s2.73.25,3.99.76,2.38,1.24,3.35,2.19c.99.96,1.74,2.06,2.26,3.28s.78,2.53.78,3.9-.26,2.71-.78,3.91c-.51,1.2-1.29,2.3-2.34,3.3l3.28,3.81h.01,0ZM415.09,110.16h2.17l3.6,4.19c.78-.84,1.36-1.75,1.74-2.74s.58-2.06.58-3.17-.21-2.26-.65-3.3c-.42-1.04-1.04-1.96-1.84-2.77-.81-.81-1.72-1.43-2.76-1.86-1.04-.43-2.13-.65-3.27-.65s-2.21.21-3.23.65c-1.03.42-1.95,1.05-2.76,1.87-.81.82-1.41,1.74-1.83,2.77s-.63,2.13-.63,3.3.21,2.28.63,3.31,1.03,1.96,1.83,2.76c.82.81,1.74,1.43,2.76,1.85,1.03.42,2.12.63,3.29.63.94,0,1.82-.14,2.67-.41.85-.28,1.57-.68,2.19-1.18l-4.5-5.22v-.03h0Z"
                                            />
                                            <path
                                                class="cls-7"
                                                d="M431.3,98.74h1.73v12.64c0,1.91.43,3.34,1.31,4.26s2.21,1.39,4,1.39,3.09-.46,3.96-1.39c.88-.93,1.31-2.35,1.31-4.26v-12.64h1.75v12.06c0,2.62-.58,4.58-1.74,5.87s-2.92,1.94-5.29,1.94-4.13-.65-5.3-1.94c-1.16-1.29-1.74-3.25-1.74-5.87v-12.06h.01,0Z"
                                            />
                                            <path class="cls-7" d="M452.55,118.09v-19.35h1.75v19.35h-1.75Z" />
                                            <path class="cls-7" d="M459.49,118.09l11.5-17.75h-9.82v-1.6h12.82l-11.45,17.73h10.28v1.62h-13.34.01Z" />
                                        </g>
                                    </g>
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </svg>

        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 470.4 138.87" v-if="$i18n.locale === 'en'">
            <defs>
                <linearGradient id="Dégradé_sans_nom_100" x1="146.23" y1="69.43" x2="-7.03" y2="69.43" gradientUnits="userSpaceOnUse">
                    <stop offset="0" stop-color="#f7941e" />
                    <stop offset="1" stop-color="#ec008c" />
                </linearGradient>
                <linearGradient id="Dégradé_sans_nom_100-2" x1="128.39" y1="47.03" x2="150.12" y2="15.5" xlink:href="#Dégradé_sans_nom_100" />
                <linearGradient id="linear-gradient" x1="97.31" y1="-.66" x2="78.94" y2="27.34" gradientUnits="userSpaceOnUse">
                    <stop offset="0" stop-color="#fff" />
                    <stop offset=".02" stop-color="#f8f8f8" />
                    <stop offset=".37" stop-color="#8f8f8f" />
                    <stop offset=".65" stop-color="#424242" />
                    <stop offset=".84" stop-color="#121212" />
                    <stop offset=".94" stop-color="#000" />
                </linearGradient>
                <linearGradient id="Dégradé_sans_nom_100-3" x1="322.7" y1="16.62" x2="254.91" y2="79.43" xlink:href="#Dégradé_sans_nom_100" />
                <linearGradient id="Dégradé_sans_nom_100-4" x1="312.71" y1="5.85" x2="244.93" y2="68.66" xlink:href="#Dégradé_sans_nom_100" />
            </defs>
            <g class="cls_en-7">
                <g id="Calque_2">
                    <g id="english">
                        <path
                            class="cls_en-4"
                            d="M133.73,79.64c-6.24,12.81-17.91,21.42-29.72,21.92-11.29.46-18.26-3.79-22.09-7.47-6.28-6.01-9.44-14.93-9.44-26.54,0-8.53-6.95-15.49-15.51-15.49-9.48,0-17,8.55-15.25,18.32,1.08,6.07,5.86,11.05,11.89,12.32,1.9.4,3.8.46,5.63.19,4.37-.64,8.63,1.9,10.51,5.9.03.07.06.13.09.2,3.05,6.39-.9,13.97-7.91,14.93-4.3.58-8.76.41-13.2-.58-14.83-3.31-26.2-15.85-28.15-30.92-2.89-22.38,14.53-41.54,36.38-41.54,20.22,0,36.69,16.45,36.69,36.67,0,5.37,1.06,9.48,2.91,11.25,1.25,1.19,3.45,1.73,6.55,1.6,2.74-.12,7.43-1.44,12.51-9.48,5.85-9.27,8.74-42.88-21.15-62.19C80.36-.4,62.59-2.51,46.72,3.06,18.23,13.06-1.81,40.99.13,73.31c2.08,34.71,30.09,62.99,64.78,65.38,40.83,2.82,74.82-29.47,74.82-69.69,0-2.47-.13-4.9-.38-7.31-1.17,8.16-3.83,14.27-5.62,17.94Z"
                        />
                        <circle class="cls_en-1" cx="137.87" cy="33.28" r="13.23" />
                        <path
                            class="cls_en-6"
                            d="M79.89,26.2c19.49,9.16,20.1,26.44,17.12,39.46.03.62.05,1.25.05,1.88,0,5.55,1.22,8.18,1.86,8.8.12.11.84.68,3.3.68.25,0,.5,0,.76-.02,4.07-.17,7.57-4.39,9.78-7.9,2.11-3.34,4.62-13.06,2.37-24.88-1.8-9.43-7.16-22.74-22.49-32.65-8.29-5.35-17.93-8.19-27.89-8.19-5.8,0-11.49.97-16.92,2.87-13.33,4.68-25.05,13.72-33.02,25.46,0,0,31.24-21.41,65.07-5.52Z"
                        />
                        <path class="cls_en-2" d="M288.98,68.3V31.8h8.7v36.5h-8.7ZM293.43,17.37c2.97,0,5.3,2.33,5.3,5.3,0,3.18-2.33,5.52-5.3,5.52-3.18,0-5.52-2.33-5.52-5.52,0-2.97,2.33-5.3,5.52-5.3Z" />
                        <path
                            class="cls_en-3"
                            d="M397.76,48.14c0,3.61,1.06,6.37,2.97,8.49,1.91,1.91,4.46,2.97,7.85,2.97,3.18,0,5.73-1.06,7.85-3.18,1.91-2.12,2.76-4.88,2.76-8.49,0-3.18-.85-5.73-2.97-7.85-2.12-2.12-4.67-3.18-7.64-3.18-3.18,0-5.73,1.06-7.85,3.18-1.91,2.12-2.97,4.88-2.97,8.06ZM388.64,48.14c0-2.55.42-5.09,1.49-7.43,1.06-2.55,2.33-4.67,4.24-6.58,1.91-1.91,4.03-3.39,6.37-4.24,2.54-1.06,4.88-1.7,7.64-1.7,2.54,0,5.09.64,7.43,1.49,2.33.85,4.46,2.33,6.37,4.03,2.12,1.91,3.6,4.03,4.67,6.58,1.06,2.55,1.7,5.09,1.7,7.64,0,2.97-.64,5.73-1.49,8.07-.85,2.33-2.34,4.46-4.25,6.37-1.91,2.12-4.03,3.61-6.37,4.46-2.54,1.06-5.09,1.49-8.06,1.49-2.76,0-5.31-.43-7.64-1.49-2.34-1.06-4.67-2.55-6.58-4.46-1.7-2.12-3.18-4.25-4.03-6.58-1.07-2.33-1.49-4.88-1.49-7.64Z"
                        />
                        <path class="cls_en-3" d="M346.9,57.26V18.01h8.91v12.31h5.73v7.85h-5.73v19.1c0,2.12.85,3.18,3.18,3.18h2.55v7.85h-3.4c-5.94,0-11.25-3.4-11.25-11.04Z" />
                        <path
                            class="cls_en-5"
                            d="M260.73,28.83c-10.82,0-19.68,8.91-19.68,19.73s8.94,19.73,19.76,19.73c4.03,0,8.23-1.27,11.06-3.39v3.22h8.48v-19.56c0-10.82-8.8-19.73-19.62-19.73ZM260.84,59.6c-6.15,0-11.03-4.88-11.03-11.04s4.88-11.03,11.03-11.03,11.03,4.88,11.03,11.03-4.88,11.04-11.03,11.04Z"
                        />
                        <path class="cls_en-3" d="M444.13,68.3v-22.28c0-4.24,3.61-7.85,7.85-7.85h0c4.46,0,8.07,3.61,8.07,7.85v22.28h8.7v-22.07c0-9.12-7.43-16.76-16.76-16.76h0c-9.12,0-16.76,7.64-16.76,16.76v22.07h8.91Z" />
                        <path
                            class="cls_en-3"
                            d="M178.79,28.83c-10.82,0-19.7,8.91-19.7,19.73s8.87,19.95,19.69,19.95c4.24,0,8.37-1.27,11.19-3.61v17.78h8.48v-34.12c0-10.82-8.63-19.73-19.66-19.73ZM178.87,59.81c-6.15,0-11.03-5.09-11.03-11.25s4.88-11.03,11.03-11.03,11.24,5.09,11.24,11.03-5.09,11.25-11.24,11.25Z"
                        />
                        <path class="cls_en-3" d="M211.54,29.68v22.07c0,4.46,3.61,8.06,7.85,8.06h0c4.46,0,8.07-3.61,8.07-8.06v-22.07h8.7v21.86c0,9.34-7.43,16.76-16.76,16.76h0c-9.12,0-16.76-7.43-16.76-16.76v-21.86h8.91Z" />
                        <path
                            class="cls_en-3"
                            d="M306.35,55.78c0,8.28,8.49,12.31,16.98,12.52,11.24,0,16.34-5.73,16.55-11.46.21-8.91-8.28-11.25-15.92-12.31-5.09-.85-7.64-1.27-7.43-3.82,0-2.55,4.03-3.18,6.37-3.18,2.76,0,6.79.85,6.79,4.25h8.7c-.21-7.43-6.37-12.31-15.28-12.52-8.91,0-15.06,4.24-15.49,11.03-.85,10.19,11.25,11.88,15.06,12.52,5.09.64,7.85,1.7,8.28,3.4.43,2.76-2.55,3.82-7.43,3.82-6.37,0-8.07-2.97-8.49-4.25h-8.7Z"
                        />
                        <path class="cls_en-3" d="M370.67,68.3V31.8h8.7v36.5h-8.7ZM375.12,17.37c2.97,0,5.3,2.33,5.3,5.3,0,3.18-2.33,5.52-5.3,5.52-3.18,0-5.52-2.33-5.52-5.52,0-2.97,2.33-5.3,5.52-5.3Z" />
                        <path
                            class="cls_en-3"
                            d="M180.96,118.44h-2.13l-2.34-2.7c-.93.59-1.89,1.04-2.9,1.33s-2.09.44-3.25.44c-1.36,0-2.64-.25-3.86-.74-1.22-.49-2.3-1.2-3.24-2.13-.98-.97-1.73-2.06-2.24-3.27-.51-1.21-.77-2.49-.77-3.85s.26-2.65.77-3.87c.51-1.22,1.26-2.3,2.24-3.25.96-.94,2.06-1.67,3.31-2.17,1.24-.5,2.56-.75,3.95-.75s2.71.25,3.96.75c1.25.5,2.36,1.23,3.32,2.17.98.95,1.73,2.04,2.24,3.25s.77,2.5.77,3.87-.26,2.69-.77,3.88c-.51,1.18-1.28,2.28-2.32,3.27l3.25,3.78ZM170.91,109.23h2.15l3.57,4.16c.77-.83,1.35-1.74,1.73-2.72.38-.98.58-2.04.58-3.15s-.21-2.25-.64-3.27-1.03-1.94-1.83-2.75c-.8-.8-1.71-1.42-2.74-1.85-1.03-.43-2.11-.64-3.24-.64s-2.19.21-3.2.64c-1.02.42-1.93,1.04-2.74,1.86-.8.81-1.4,1.73-1.82,2.75-.42,1.02-.62,2.11-.62,3.27s.21,2.26.62,3.28c.42,1.02,1.02,1.94,1.82,2.74.81.8,1.73,1.42,2.74,1.84s2.1.63,3.26.63c.93,0,1.81-.14,2.65-.41.84-.28,1.56-.67,2.17-1.17l-4.46-5.18Z"
                        />
                        <path class="cls_en-3" d="M191.69,97.9h1.72v12.54c0,1.9.43,3.31,1.3,4.23.87.92,2.19,1.38,3.97,1.38s3.07-.46,3.93-1.38,1.3-2.33,1.3-4.23v-12.54h1.74v11.97c0,2.6-.58,4.54-1.73,5.82-1.15,1.28-2.9,1.92-5.25,1.92s-4.1-.64-5.26-1.92c-1.15-1.28-1.73-3.22-1.73-5.82v-11.97Z" />
                        <path class="cls_en-3" d="M217.47,117.1v-19.2h1.74v19.2h-1.74Z" />
                        <path class="cls_en-3" d="M229.06,117.1l11.41-17.61h-9.74v-1.59h12.72l-11.36,17.59h10.2v1.61h-13.23Z" />
                        <path
                            class="cls_en-3"
                            d="M283.88,102.72c-.78-1.21-1.74-2.12-2.87-2.74-1.14-.62-2.42-.93-3.84-.93-2.38,0-4.32.8-5.81,2.41-1.5,1.61-2.24,3.69-2.24,6.26,0,1.15.2,2.22.6,3.24s.98,1.92,1.74,2.7c.77.78,1.63,1.36,2.58,1.75.95.39,2,.58,3.13.58,2.15,0,3.9-.63,5.25-1.9,1.35-1.27,2.04-2.92,2.08-4.97h-6.62v-1.51h8.54v.21c0,3.06-.83,5.46-2.5,7.19-1.67,1.73-3.97,2.6-6.9,2.6s-5.27-.93-7.07-2.78c-1.8-1.85-2.7-4.28-2.7-7.29,0-1.38.23-2.69.68-3.92s1.11-2.3,1.97-3.21c.93-.99,1.97-1.73,3.14-2.22,1.17-.49,2.47-.73,3.9-.73,1.95,0,3.62.35,5.01,1.05,1.39.71,2.46,1.74,3.21,3.1l-1.27,1.1Z"
                        />
                        <path class="cls_en-3" d="M297.2,117.1v-19.2h9.51v1.64h-7.76v6.01h7.76v1.64h-7.76v8.28h7.76v1.64h-9.51Z" />
                        <path class="cls_en-3" d="M318.1,117.1v-20.01l13.6,16.07v-15.25h1.63v19.77l-13.59-16.09v15.53h-1.64Z" />
                        <path class="cls_en-3" d="M345.23,117.1v-19.2h9.51v1.64h-7.76v6.01h7.76v1.64h-7.76v8.28h7.76v1.64h-9.51Z" />
                        <path
                            class="cls_en-3"
                            d="M377.79,117.1h-2.12l-6.74-9.15h-1.05v9.15h-1.74v-19.2h3.53c2.08,0,3.64.43,4.68,1.3s1.55,2.18,1.55,3.94c0,1.49-.44,2.67-1.33,3.53-.89.86-2.11,1.29-3.67,1.29l6.9,9.15ZM367.88,99.42v7.1h1.76c1.63,0,2.76-.26,3.4-.78.64-.52.96-1.41.96-2.66,0-1.32-.33-2.26-1-2.82s-1.79-.84-3.37-.84h-1.76Z"
                        />
                        <path class="cls_en-3" d="M384.81,117.1l8.43-19.96,8.08,19.96h-1.9l-2.45-6.5h-7.84l-2.54,6.5h-1.77ZM389.71,109.1h6.69l-3.28-8.35-3.41,8.35Z" />
                        <path class="cls_en-3" d="M412.53,117.1v-17.56h-5.29v-1.64h12.33v1.64h-5.3v17.56h-1.74Z" />
                        <path
                            class="cls_en-3"
                            d="M447.77,107.54c0,1.36-.26,2.64-.77,3.86-.52,1.22-1.26,2.31-2.23,3.26-.99.96-2.1,1.7-3.35,2.2-1.24.5-2.56.75-3.94.75s-2.69-.25-3.93-.75c-1.24-.5-2.35-1.23-3.33-2.2-.98-.97-1.73-2.06-2.24-3.27-.51-1.21-.77-2.49-.77-3.85s.26-2.67.77-3.89c.51-1.22,1.26-2.31,2.24-3.29.96-.95,2.06-1.67,3.29-2.16,1.23-.49,2.56-.73,3.96-.73s2.74.25,3.98.74c1.24.49,2.34,1.21,3.3,2.16.98.97,1.73,2.07,2.24,3.29s.77,2.52.77,3.89ZM437.48,116.05c1.14,0,2.21-.21,3.23-.63,1.02-.42,1.93-1.03,2.74-1.84.8-.81,1.41-1.74,1.83-2.77.42-1.04.64-2.13.64-3.27s-.21-2.25-.63-3.27c-.42-1.03-1.03-1.95-1.84-2.77-.8-.8-1.7-1.42-2.72-1.84-1.02-.42-2.1-.63-3.25-.63s-2.21.21-3.22.63c-1.01.42-1.92,1.03-2.73,1.84-.8.83-1.42,1.76-1.84,2.77-.42,1.02-.63,2.11-.63,3.27s.21,2.24.63,3.27c.42,1.03,1.03,1.95,1.84,2.77.81.81,1.71,1.43,2.7,1.84,1,.42,2.08.62,3.24.62Z"
                        />
                        <path
                            class="cls_en-3"
                            d="M470.4,117.1h-2.12l-6.74-9.15h-1.05v9.15h-1.74v-19.2h3.53c2.08,0,3.64.43,4.68,1.3s1.55,2.18,1.55,3.94c0,1.49-.44,2.67-1.33,3.53-.89.86-2.11,1.29-3.67,1.29l6.9,9.15ZM460.48,99.42v7.1h1.76c1.63,0,2.76-.26,3.4-.78.64-.52.96-1.41.96-2.66,0-1.32-.33-2.26-1-2.82s-1.79-.84-3.37-.84h-1.76Z"
                        />
                    </g>
                </g>
            </g>
        </svg>

        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 468.74 138.87" v-if="$i18n.locale === 'es'">
            <defs>
                <linearGradient id="Dégradé_sans_nom_100" x1="146.23" y1="69.43" x2="-7.03" y2="69.43" gradientUnits="userSpaceOnUse">
                    <stop offset="0" stop-color="#f7941e" />
                    <stop offset="1" stop-color="#ec008c" />
                </linearGradient>
                <linearGradient id="Dégradé_sans_nom_100-2" x1="128.39" y1="47.03" x2="150.12" y2="15.5" xlink:href="#Dégradé_sans_nom_100" />
                <linearGradient id="linear-gradient" x1="97.31" y1="-.66" x2="78.94" y2="27.34" gradientUnits="userSpaceOnUse">
                    <stop offset="0" stop-color="#fff" />
                    <stop offset=".02" stop-color="#f8f8f8" />
                    <stop offset=".37" stop-color="#8f8f8f" />
                    <stop offset=".65" stop-color="#424242" />
                    <stop offset=".84" stop-color="#121212" />
                    <stop offset=".94" stop-color="#000" />
                </linearGradient>
                <linearGradient id="Dégradé_sans_nom_100-3" x1="322.7" y1="16.62" x2="254.91" y2="79.43" xlink:href="#Dégradé_sans_nom_100" />
                <linearGradient id="Dégradé_sans_nom_100-4" x1="312.71" y1="5.85" x2="244.93" y2="68.66" xlink:href="#Dégradé_sans_nom_100" />
            </defs>
            <g class="cls_es-7">
                <g id="Calque_2">
                    <g id="spanish">
                        <path
                            class="cls_es-4"
                            d="M133.73,79.64c-6.24,12.81-17.91,21.42-29.72,21.92-11.29.46-18.26-3.79-22.09-7.47-6.28-6.01-9.44-14.93-9.44-26.54,0-8.53-6.95-15.49-15.51-15.49-9.48,0-17,8.55-15.25,18.32,1.08,6.07,5.86,11.05,11.89,12.32,1.9.4,3.8.46,5.63.19,4.37-.64,8.63,1.9,10.51,5.9.03.07.06.13.09.2,3.05,6.39-.9,13.97-7.91,14.93-4.3.58-8.76.41-13.2-.58-14.83-3.31-26.2-15.85-28.15-30.92-2.89-22.38,14.53-41.54,36.38-41.54,20.22,0,36.69,16.45,36.69,36.67,0,5.37,1.06,9.48,2.91,11.25,1.25,1.19,3.45,1.73,6.55,1.6,2.74-.12,7.43-1.44,12.51-9.48,5.85-9.27,8.74-42.88-21.15-62.19C80.36-.4,62.59-2.51,46.72,3.06,18.23,13.06-1.81,40.99.13,73.31c2.08,34.71,30.09,62.99,64.78,65.38,40.83,2.82,74.82-29.47,74.82-69.69,0-2.47-.13-4.9-.38-7.31-1.17,8.16-3.83,14.27-5.62,17.94Z"
                        />
                        <circle class="cls_es-1" cx="137.87" cy="33.28" r="13.23" />
                        <path
                            class="cls_es-6"
                            d="M79.89,26.2c19.49,9.16,20.1,26.44,17.12,39.46.03.62.05,1.25.05,1.88,0,5.55,1.22,8.18,1.86,8.8.12.11.84.68,3.3.68.25,0,.5,0,.76-.02,4.07-.17,7.57-4.39,9.78-7.9,2.11-3.34,4.62-13.06,2.37-24.88-1.8-9.43-7.16-22.74-22.49-32.65-8.29-5.35-17.93-8.19-27.89-8.19-5.8,0-11.49.97-16.92,2.87-13.33,4.68-25.05,13.72-33.02,25.46,0,0,31.24-21.41,65.07-5.52Z"
                        />
                        <path class="cls_es-2" d="M288.98,68.3V31.8h8.7v36.5h-8.7ZM293.43,17.37c2.97,0,5.3,2.33,5.3,5.3,0,3.18-2.33,5.52-5.3,5.52-3.18,0-5.52-2.33-5.52-5.52,0-2.97,2.33-5.3,5.52-5.3Z" />
                        <path
                            class="cls_es-3"
                            d="M397.76,48.14c0,3.61,1.06,6.37,2.97,8.49,1.91,1.91,4.46,2.97,7.85,2.97,3.18,0,5.73-1.06,7.85-3.18,1.91-2.12,2.76-4.88,2.76-8.49,0-3.18-.85-5.73-2.97-7.85-2.12-2.12-4.67-3.18-7.64-3.18-3.18,0-5.73,1.06-7.85,3.18-1.91,2.12-2.97,4.88-2.97,8.06ZM388.64,48.14c0-2.55.42-5.09,1.49-7.43,1.06-2.55,2.33-4.67,4.24-6.58,1.91-1.91,4.03-3.39,6.37-4.24,2.54-1.06,4.88-1.7,7.64-1.7,2.54,0,5.09.64,7.43,1.49,2.33.85,4.46,2.33,6.37,4.03,2.12,1.91,3.6,4.03,4.67,6.58,1.06,2.55,1.7,5.09,1.7,7.64,0,2.97-.64,5.73-1.49,8.07-.85,2.33-2.34,4.46-4.25,6.37-1.91,2.12-4.03,3.61-6.37,4.46-2.54,1.06-5.09,1.49-8.06,1.49-2.76,0-5.31-.43-7.64-1.49-2.34-1.06-4.67-2.55-6.58-4.46-1.7-2.12-3.18-4.25-4.03-6.58-1.07-2.33-1.49-4.88-1.49-7.64Z"
                        />
                        <path class="cls_es-3" d="M346.9,57.26V18.01h8.91v12.31h5.73v7.85h-5.73v19.1c0,2.12.85,3.18,3.18,3.18h2.55v7.85h-3.4c-5.94,0-11.25-3.4-11.25-11.04Z" />
                        <path
                            class="cls_es-5"
                            d="M260.73,28.83c-10.82,0-19.68,8.91-19.68,19.73s8.94,19.73,19.76,19.73c4.03,0,8.23-1.27,11.06-3.39v3.22h8.48v-19.56c0-10.82-8.8-19.73-19.62-19.73ZM260.84,59.6c-6.15,0-11.03-4.88-11.03-11.04s4.88-11.03,11.03-11.03,11.03,4.88,11.03,11.03-4.88,11.04-11.03,11.04Z"
                        />
                        <path class="cls_es-3" d="M444.13,68.3v-22.28c0-4.24,3.61-7.85,7.85-7.85h0c4.46,0,8.07,3.61,8.07,7.85v22.28h8.7v-22.07c0-9.12-7.43-16.76-16.76-16.76h0c-9.12,0-16.76,7.64-16.76,16.76v22.07h8.91Z" />
                        <path
                            class="cls_es-3"
                            d="M178.79,28.83c-10.82,0-19.7,8.91-19.7,19.73s8.87,19.95,19.69,19.95c4.24,0,8.37-1.27,11.19-3.61v17.78h8.48v-34.12c0-10.82-8.63-19.73-19.66-19.73ZM178.87,59.81c-6.15,0-11.03-5.09-11.03-11.25s4.88-11.03,11.03-11.03,11.24,5.09,11.24,11.03-5.09,11.25-11.24,11.25Z"
                        />
                        <path class="cls_es-3" d="M211.54,29.68v22.07c0,4.46,3.61,8.06,7.85,8.06h0c4.46,0,8.07-3.61,8.07-8.06v-22.07h8.7v21.86c0,9.34-7.43,16.76-16.76,16.76h0c-9.12,0-16.76-7.43-16.76-16.76v-21.86h8.91Z" />
                        <path
                            class="cls_es-3"
                            d="M306.35,55.78c0,8.28,8.49,12.31,16.98,12.52,11.24,0,16.34-5.73,16.55-11.46.21-8.91-8.28-11.25-15.92-12.31-5.09-.85-7.64-1.27-7.43-3.82,0-2.55,4.03-3.18,6.37-3.18,2.76,0,6.79.85,6.79,4.25h8.7c-.21-7.43-6.37-12.31-15.28-12.52-8.91,0-15.06,4.24-15.49,11.03-.85,10.19,11.25,11.88,15.06,12.52,5.09.64,7.85,1.7,8.28,3.4.43,2.76-2.55,3.82-7.43,3.82-6.37,0-8.07-2.97-8.49-4.25h-8.7Z"
                        />
                        <path class="cls_es-3" d="M370.67,68.3V31.8h8.7v36.5h-8.7ZM375.12,17.37c2.97,0,5.3,2.33,5.3,5.3,0,3.18-2.33,5.52-5.3,5.52-3.18,0-5.52-2.33-5.52-5.52,0-2.97,2.33-5.3,5.52-5.3Z" />
                        <path
                            class="cls_es-3"
                            d="M177.3,102.8c-.78-1.21-1.74-2.12-2.87-2.74-1.14-.62-2.42-.93-3.84-.93-2.38,0-4.32.8-5.81,2.41-1.5,1.61-2.24,3.69-2.24,6.26,0,1.15.2,2.22.6,3.24s.98,1.92,1.74,2.7c.77.78,1.63,1.36,2.58,1.75.95.39,2,.58,3.13.58,2.15,0,3.9-.63,5.25-1.9,1.35-1.27,2.04-2.92,2.08-4.97h-6.62v-1.51h8.54v.21c0,3.06-.83,5.46-2.5,7.19-1.67,1.73-3.97,2.6-6.9,2.6s-5.27-.93-7.07-2.78-2.7-4.28-2.7-7.29c0-1.38.23-2.69.68-3.92s1.11-2.3,1.97-3.21c.93-.99,1.97-1.73,3.14-2.22,1.17-.49,2.47-.73,3.9-.73,1.95,0,3.62.35,5.01,1.06,1.39.7,2.46,1.74,3.21,3.1l-1.27,1.1Z"
                        />
                        <path class="cls_es-3" d="M183.63,117.18v-19.2h9.51v1.64h-7.76v6.01h7.76v1.64h-7.76v8.28h7.76v1.64h-9.51Z" />
                        <path class="cls_es-3" d="M197.55,117.18v-20.01l13.6,16.07v-15.25h1.63v19.77l-13.59-16.09v15.53h-1.64Z" />
                        <path class="cls_es-3" d="M217.7,117.18v-19.2h9.51v1.64h-7.76v6.01h7.76v1.64h-7.76v8.28h7.76v1.64h-9.51Z" />
                        <path
                            class="cls_es-3"
                            d="M243.28,117.18h-2.12l-6.74-9.15h-1.05v9.15h-1.74v-19.2h3.53c2.08,0,3.64.43,4.68,1.3s1.55,2.18,1.55,3.93c0,1.5-.44,2.67-1.33,3.53-.89.86-2.11,1.29-3.67,1.29l6.9,9.15ZM233.36,99.5v7.1h1.76c1.63,0,2.76-.26,3.4-.78.64-.52.96-1.41.96-2.66,0-1.32-.33-2.26-1-2.82s-1.79-.84-3.37-.84h-1.76Z"
                        />
                        <path class="cls_es-3" d="M243.31,117.18l8.43-19.96,8.08,19.96h-1.9l-2.45-6.5h-7.84l-2.54,6.5h-1.77ZM248.21,109.18h6.69l-3.28-8.35-3.41,8.35Z" />
                        <path
                            class="cls_es-3"
                            d="M262.11,117.18v-19.2h2.99c2.67,0,4.56.13,5.68.39,1.12.26,2.08.71,2.89,1.35,1.07.85,1.88,1.94,2.44,3.28.56,1.34.84,2.87.84,4.59s-.28,3.25-.84,4.6c-.56,1.34-1.36,2.42-2.41,3.22-.83.64-1.78,1.09-2.85,1.36-1.07.27-2.79.41-5.15.41h-3.58ZM263.85,115.67h2.45c1.98,0,3.39-.11,4.22-.33.83-.22,1.53-.6,2.11-1.13.8-.75,1.41-1.69,1.82-2.81s.62-2.4.62-3.83-.21-2.76-.62-3.88c-.42-1.12-1.03-2.04-1.84-2.75-.61-.55-1.36-.94-2.24-1.17-.88-.23-2.43-.34-4.63-.34h-1.89v16.25Z"
                        />
                        <path
                            class="cls_es-3"
                            d="M300.6,107.62c0,1.36-.26,2.64-.77,3.86-.52,1.22-1.26,2.31-2.23,3.26-.99.96-2.1,1.69-3.35,2.2-1.24.5-2.56.75-3.94.75s-2.69-.25-3.93-.75c-1.24-.5-2.35-1.24-3.33-2.2-.98-.97-1.73-2.06-2.24-3.27-.51-1.21-.77-2.49-.77-3.85s.26-2.67.77-3.89c.51-1.22,1.26-2.32,2.24-3.29.96-.95,2.06-1.67,3.29-2.16,1.23-.49,2.56-.73,3.96-.73s2.74.25,3.98.74c1.24.49,2.34,1.21,3.3,2.16.98.97,1.73,2.07,2.24,3.29s.77,2.52.77,3.89ZM290.31,116.13c1.14,0,2.21-.21,3.23-.63s1.93-1.03,2.74-1.84c.8-.81,1.41-1.74,1.83-2.77s.64-2.13.64-3.27-.21-2.25-.63-3.27c-.42-1.03-1.03-1.95-1.84-2.77-.8-.8-1.7-1.42-2.72-1.84-1.02-.42-2.1-.63-3.25-.63s-2.21.21-3.22.63c-1.01.42-1.92,1.03-2.73,1.84-.8.83-1.42,1.76-1.84,2.77-.42,1.02-.63,2.11-.63,3.27s.21,2.24.63,3.27c.42,1.03,1.03,1.95,1.84,2.77.81.81,1.71,1.43,2.7,1.84,1,.42,2.08.62,3.24.62Z"
                        />
                        <path
                            class="cls_es-3"
                            d="M316.25,117.18h-2.12l-6.74-9.15h-1.05v9.15h-1.74v-19.2h3.53c2.08,0,3.64.43,4.68,1.3s1.55,2.18,1.55,3.93c0,1.5-.44,2.67-1.33,3.53-.89.86-2.11,1.29-3.67,1.29l6.9,9.15ZM306.34,99.5v7.1h1.76c1.63,0,2.76-.26,3.4-.78.64-.52.96-1.41.96-2.66,0-1.32-.33-2.26-1-2.82s-1.79-.84-3.37-.84h-1.76Z"
                        />
                        <path
                            class="cls_es-3"
                            d="M326.78,117.18v-19.2h2.99c2.67,0,4.56.13,5.68.39,1.12.26,2.08.71,2.89,1.35,1.07.85,1.88,1.94,2.44,3.28.56,1.34.84,2.87.84,4.59s-.28,3.25-.84,4.6c-.56,1.34-1.36,2.42-2.41,3.22-.83.64-1.78,1.09-2.85,1.36-1.07.27-2.79.41-5.15.41h-3.58ZM328.52,115.67h2.45c1.98,0,3.39-.11,4.22-.33.83-.22,1.53-.6,2.11-1.13.8-.75,1.41-1.69,1.82-2.81s.62-2.4.62-3.83-.21-2.76-.62-3.88c-.42-1.12-1.03-2.04-1.84-2.75-.61-.55-1.36-.94-2.24-1.17-.88-.23-2.43-.34-4.63-.34h-1.89v16.25Z"
                        />
                        <path class="cls_es-3" d="M345.63,117.18v-19.2h9.51v1.64h-7.76v6.01h7.76v1.64h-7.76v8.28h7.76v1.64h-9.51Z" />
                        <path
                            class="cls_es-3"
                            d="M367.42,117.18v-19.2h3.5c1.38,0,2.4.08,3.05.22s1.22.39,1.7.73c.6.44,1.07,1.04,1.4,1.8.33.76.5,1.6.5,2.53,0,1.83-.51,3.17-1.53,4.02s-2.64,1.28-4.85,1.28h-2.02v8.62h-1.74ZM369.16,107.03h1.19c2.12,0,3.54-.27,4.27-.81.73-.54,1.1-1.48,1.1-2.83,0-.57-.08-1.09-.25-1.57s-.42-.9-.75-1.28c-.34-.38-.8-.66-1.39-.81-.59-.16-1.58-.24-2.98-.24h-1.19v7.53Z"
                        />
                        <path
                            class="cls_es-3"
                            d="M392.64,117.18h-2.12l-6.74-9.15h-1.05v9.15h-1.74v-19.2h3.53c2.08,0,3.64.43,4.68,1.3s1.55,2.18,1.55,3.93c0,1.5-.44,2.67-1.33,3.53-.89.86-2.11,1.29-3.67,1.29l6.9,9.15ZM382.73,99.5v7.1h1.76c1.63,0,2.76-.26,3.4-.78.64-.52.96-1.41.96-2.66,0-1.32-.33-2.26-1-2.82s-1.79-.84-3.37-.84h-1.76Z"
                        />
                        <path class="cls_es-3" d="M395.22,97.98h1.72v12.54c0,1.9.43,3.31,1.3,4.23.87.92,2.19,1.38,3.97,1.38s3.07-.46,3.93-1.38,1.3-2.33,1.3-4.23v-12.54h1.74v11.97c0,2.6-.58,4.54-1.73,5.82-1.15,1.28-2.9,1.92-5.25,1.92s-4.1-.64-5.26-1.92c-1.15-1.28-1.73-3.22-1.73-5.82v-11.97Z" />
                        <path class="cls_es-3" d="M414.01,117.18v-19.2h9.51v1.64h-7.76v6.01h7.76v1.64h-7.76v8.28h7.76v1.64h-9.51Z" />
                        <path
                            class="cls_es-3"
                            d="M427.93,117.18v-19.2h3.33c1.46,0,2.51.07,3.16.21.65.14,1.2.38,1.65.72.64.48,1.12,1.07,1.46,1.76.33.7.5,1.46.5,2.3,0,1.02-.27,1.89-.8,2.61-.53.72-1.27,1.19-2.2,1.42,1.23.18,2.2.68,2.9,1.48s1.05,1.82,1.05,3.06c0,.9-.14,1.7-.41,2.4s-.68,1.3-1.23,1.81c-.56.53-1.23.89-2.02,1.11-.79.21-2.13.32-4.03.32h-3.36ZM429.68,106.51h2.18c1.6,0,2.73-.26,3.39-.79.66-.53.99-1.4.99-2.62,0-1.32-.32-2.25-.96-2.79s-1.78-.81-3.42-.81h-2.18v7.02ZM429.68,115.67h2.23c1.27,0,2.14-.05,2.62-.16.48-.11.89-.28,1.22-.54.41-.31.74-.75.98-1.3.24-.56.37-1.15.37-1.78,0-.77-.14-1.45-.41-2.03s-.65-1.02-1.13-1.3c-.33-.19-.72-.33-1.17-.41s-1.15-.12-2.1-.12h-2.61v7.65Z"
                        />
                        <path class="cls_es-3" d="M440.3,117.18l8.43-19.96,8.08,19.96h-1.9l-2.45-6.5h-7.84l-2.54,6.5h-1.77ZM445.21,109.18h6.69l-3.28-8.35-3.41,8.35Z" />
                        <path
                            class="cls_es-3"
                            d="M457.63,113.95l1.5-.8c.28.94.76,1.68,1.45,2.2s1.51.78,2.47.78c1.21,0,2.16-.36,2.85-1.08.68-.72,1.02-1.71,1.02-2.98,0-.93-.23-1.7-.69-2.31-.46-.61-1.44-1.32-2.96-2.12-1.93-1.02-3.22-1.92-3.86-2.7s-.96-1.71-.96-2.81c0-1.36.46-2.47,1.38-3.33.92-.86,2.12-1.29,3.59-1.29.96,0,1.8.2,2.51.61.71.41,1.29,1.02,1.74,1.83l-1.35.81c-.28-.55-.68-.97-1.19-1.26-.51-.29-1.12-.43-1.82-.43-.94,0-1.7.28-2.26.83-.56.56-.84,1.31-.84,2.25,0,1.35,1.11,2.6,3.33,3.76.4.21.71.37.92.49,1.64.9,2.73,1.75,3.28,2.55.55.8.83,1.84.83,3.12,0,1.72-.5,3.09-1.51,4.1-1.01,1.01-2.37,1.51-4.11,1.51-1.26,0-2.34-.31-3.23-.94-.9-.62-1.6-1.56-2.1-2.8Z"
                        />
                    </g>
                </g>
            </g>
        </svg>
    </div>
</template>

<script>
export default {
    name: "Logo",
};
</script>

<style scoped>
.cls-1 {
    fill: url(#Dégradé_sans_nom_4);
}

.cls-2 {
    fill: url(#Dégradé_sans_nom_5);
}

.cls-3 {
    fill: url(#Dégradé_sans_nom_2);
}

.cls-4,
.cls-5 {
    isolation: isolate;
}

.cls-6 {
    fill: url(#Dégradé_sans_nom);
}

.cls-7 {
    fill: #363b3e;
}

.cls-5 {
    fill: url(#Dégradé_sans_nom_3);
    mix-blend-mode: screen;
}

.cls_en-1 {
    fill: url(#Dégradé_sans_nom_100-2);
}
.cls_en-1,
.cls_en-2,
.cls_en-3,
.cls_en-4,
.cls_en-5,
.cls_en-6 {
    stroke-width: 0px;
}
.cls_en-7 {
    isolation: isolate;
}
.cls_en-2 {
    fill: url(#Dégradé_sans_nom_100-3);
}
.cls_en-3 {
    fill: #363b3e;
}
.cls_en-4 {
    fill: url(#Dégradé_sans_nom_100);
}
.cls_en-5 {
    fill: url(#Dégradé_sans_nom_100-4);
}
.cls_en-6 {
    fill: url(#linear-gradient);
    mix-blend-mode: screen;
}
.cls_es-1 {
    fill: url(#Dégradé_sans_nom_100-2);
}
.cls_es-1,
.cls_es-2,
.cls_es-3,
.cls_es-4,
.cls_es-5,
.cls_es-6 {
    stroke-width: 0px;
}
.cls_es-7 {
    isolation: isolate;
}
.cls_es-2 {
    fill: url(#Dégradé_sans_nom_100-3);
}
.cls_es-3 {
    fill: #363b3e;
}
.cls_es-4 {
    fill: url(#Dégradé_sans_nom_100);
}
.cls_es-5 {
    fill: url(#Dégradé_sans_nom_100-4);
}
.cls_es-6 {
    fill: url(#linear-gradient);
    mix-blend-mode: screen;
}
</style>
