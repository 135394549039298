<template>
    <div id="evaluation-settings">

        <Header />

        <div class="banner-top">
            <div>
                <Logo />
                <p v-html="$t('message.settings.welcome')"></p>
            </div>
            <div>
                <img src="@/assets/illustration.png" alt="" />
            </div>
        </div>

        <div>
            <!-- Paramètres évaluation -->
            <div class="settings">
                <div class="title-part">
                    <span>1/ {{ $t("message.settings.choose_a_file") }}</span>
                </div>

                <!-- Lecture fichier externe -->
                <div class="file no-border">
                    <div id="dropzone" @dragenter="isHover = true" @dragleave="isHover = false" @drop="isHover = false" :class="{ hover: isHover }">
                        <div>
                            <span class="info" v-if="!evaluation.isFile">{{ $t("message.settings.file_drag_here") }}</span>
                            <span class="error" v-if="fileError">{{ fileError }}</span>
                            <div v-if="evaluation.isFile">
                                <span id="filename">{{ evaluation.isFile }}</span>
                                <span id="remove-file" @click="removeFile"><FontAwesomeIcon icon="xmark" /></span>
                            </div>
                            <FontAwesomeIcon icon="download" v-if="!evaluation.isFile" />
                        </div>
                        <input type="file" @change="onFileChanged" ref="file" accept=".docx, .txt, .rtf, .odt, application/pdf" />
                    </div>

                    <p class="info" v-html="$t('message.settings.allowed_files')"></p>
                    <p class="info" :class="{ texttoolong: textTooLong }">{{ $t("message.settings.length_text") }}</p>

                    <textarea v-show="evaluation.isFile" disabled :placeholder="$t('message.settings.paste_your_text_here')"></textarea>
                    <textarea v-show="!evaluation.isFile" v-model="evaluation.textSource" :placeholder="$t('message.settings.paste_your_text_here')"></textarea>
                </div>
                <!-- End: Lecture fichier externe -->

                <div class="title-part">
                    <span>2/ {{ $t("message.settings.define_the_parameters") }}</span>
                </div>

                <!-- Nombre de questions -->
                <div>
                    <label for="questions-number" class="title">{{ $t("message.settings.number_of_questions") }}</label>
                    <div class="input-number">
                        <input
                            type="number"
                            id="questions-number"
                            :disabled="evaluation.numberOfQuestionsMax === 0"
                            v-model="evaluation.numberOfQuestions"
                            min="1"
                            :max="evaluation.numberOfQuestionsMax"
                            @change="evaluation.numberOfQuestions > evaluation.numberOfQuestionsMax ? (evaluation.numberOfQuestions = evaluation.numberOfQuestionsMax) : evaluation.numberOfQuestions < 1 ? (evaluation.numberOfQuestions = 1) : evaluation.numberOfQuestions"
                        />
                        <div>
                            <button type="button" :disabled="evaluation.numberOfQuestionsMax === 0" @click="evaluation.numberOfQuestions < evaluation.numberOfQuestionsMax ? evaluation.numberOfQuestions++ : (evaluation.numberOfQuestions = evaluation.numberOfQuestionsMax)" class="btn-effect">
                                <FontAwesomeIcon icon="chevron-up" />
                            </button>
                            <button type="button" :disabled="evaluation.numberOfQuestionsMax === 0 || evaluation.numberOfQuestions < 2" @click="evaluation.numberOfQuestions > 1 ? evaluation.numberOfQuestions-- : (evaluation.numberOfQuestions = 1)" class="btn-effect">
                                <FontAwesomeIcon icon="chevron-down" />
                            </button>
                        </div>
                    </div>
                </div>
                <!-- End : Nombre de questions -->

                <!-- Type de question -->
                <div>
                    <span class="title">{{ $t("message.settings.question_type") }}</span>

                    <div class="radio">
                        <input type="radio" id="qcm" value="QCM" v-model="evaluation.questionsType" />
                        <label for="qcm">{{ $t("message.settings.mcq") }}</label>
                    </div>

                    <div class="radio">
                        <input type="radio" id="qcu" value="QCU" v-model="evaluation.questionsType" />
                        <label for="qcu">{{ $t("message.settings.ucq") }}</label>
                    </div>

                    <div class="radio">
                        <input type="radio" id="qcu-qcm" value="QCU et QCM" v-model="evaluation.questionsType" />
                        <label for="qcu-qcm">{{ $t("message.settings.both") }}</label>
                    </div>
                </div>
                <!-- End : Type de question -->

                <!-- Langue -->
                <div>
                    <span class="title">{{ $t("message.settings.lang") }}</span>
                    <select v-model="evaluation.lang">
                        <option value="fr">{{ $t("message.settings.french") }}</option>
                        <option value="en">{{ $t("message.settings.english") }}</option>
                        <option value="es">{{ $t("message.settings.spanish") }}</option>
                    </select>
                </div>
                <!-- End : Langue -->

                <!-- Mode Entraînement/validation -->
                <div class="mode">
                    <span class="title">{{ $t("message.settings.mode") }}</span>

                    <div>
                        <div>
                            <div class="radio">
                                <input type="radio" id="training-mode" value="training" v-model="evaluation.mode" />
                                <label for="training-mode">{{ $t("message.settings.training") }}</label>
                            </div>

                            <div
                                class="tooltip"
                                :class="{ active: tooltipsActive[0] }"
                                @click="
                                    tooltipsActive[0] = !tooltipsActive[0];
                                    tooltipsActive[1] = false;
                                "
                            >
                                <FontAwesomeIcon icon="question" />
                                <div class="tooltiptext">
                                    <span><FontAwesomeIcon icon="xmark" /></span>
                                    {{ $t("message.settings.tooltip_training") }}
                                </div>
                            </div>
                        </div>

                        <div>
                            <div class="radio">
                                <input type="radio" id="validation-mode" value="validation" v-model="evaluation.mode" />
                                <label for="validation-mode">{{ $t("message.settings.validation") }}</label>
                            </div>

                            <div
                                class="tooltip"
                                :class="{ active: tooltipsActive[1] }"
                                @click="
                                    tooltipsActive[1] = !tooltipsActive[1];
                                    tooltipsActive[0] = false;
                                "
                            >
                                <FontAwesomeIcon icon="question" />
                                <div class="tooltiptext">
                                    <span><FontAwesomeIcon icon="xmark" /></span>
                                    {{ $t("message.settings.tooltip_validation") }}
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- Score à atteindre -->
                    <Transition name="fade">
                        <div v-if="evaluation.mode === 'validation'" class="score">
                            <label for="score-to-achieve" class="title">{{ $t("message.settings.score_to_achieve") }}</label>
                            <div class="input-number">
                                <input
                                    type="number"
                                    v-model="evaluation.scoreToAchieve"
                                    min="0"
                                    max="100"
                                    id="score-to-achieve"
                                    @change="evaluation.scoreToAchieve > evaluation.scoreToAchieveMax ? (evaluation.scoreToAchieve = evaluation.scoreToAchieveMax) : evaluation.scoreToAchieve < 0 ? (evaluation.scoreToAchieve = 0) : evaluation.scoreToAchieve"
                                />
                                <div>
                                    <button type="button" @click="evaluation.scoreToAchieve < evaluation.scoreToAchieveMax ? evaluation.scoreToAchieve++ : (evaluation.scoreToAchieve = evaluation.scoreToAchieveMax)" class="btn-effect"><FontAwesomeIcon icon="chevron-up" /></button>
                                    <button type="button" @click="evaluation.scoreToAchieve > 0 ? evaluation.scoreToAchieve-- : (evaluation.scoreToAchieve = 0)" class="btn-effect"><FontAwesomeIcon icon="chevron-down" /></button>
                                </div>
                            </div>
                            <span class="percent">%</span>
                        </div>
                    </Transition>
                    <!-- End : Score à atteindre -->
                </div>
                <!-- End : Mode Entraînement/validation -->

                <!-- Correction -->
                <div class="no-border">
                    <span class="title">{{ $t("message.settings.correction") }}</span>

                    <div class="radio">
                        <input type="radio" id="correction-now" value="now" v-model="evaluation.correction" />
                        <label for="correction-now">{{ $t("message.settings.after_each_question") }}</label>
                    </div>

                    <div class="radio">
                        <input type="radio" id="correction-end" value="end" v-model="evaluation.correction" />
                        <label for="correction-end">{{ $t("message.settings.end_evaluation") }}</label>
                    </div>
                </div>
                <!-- End : Correction -->

                <div class="title-part">
                    <span>3/ {{ $t("message.settings.here_we_go") }}</span>
                </div>

                <div class="btns">
                    <div v-if="$store.state.app.locked">{{ $t("message.settings.limit_generation") }}</div>
                    <button type="button" @click="generateQuestions()" :disabled="generateQuestionsDisabled">
                        <span>
                            <SpinnerButton color="#fff" v-if="moderationInProgress" />
                            <span>{{ $t("message.settings.start_quiz_generation") }}</span>
                        </span>
                    </button>
                </div>
            </div>
            <!-- End: Paramètres évaluation -->
        </div>
    </div>
</template>

<script>
import axios from "axios";
import { mapActions } from "vuex";
import Logo from "../components/SVG/Logo.vue";
import SpinnerButton from "../components/SVG/SpinnerButton.vue";
import Header from "../components/Header.vue";
import { rtfToTxt } from "rtf-converter/rtf_converter.js";
import * as JSZip from "jszip";
import mammoth from "mammoth";
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { faChevronUp, faChevronDown, faDownload, faQuestion } from "@fortawesome/free-solid-svg-icons";
import * as pdfjsLib from "pdfjs-dist/build/pdf";

pdfjsLib.GlobalWorkerOptions.workerSrc = "https://cdn.jsdelivr.net/npm/pdfjs-dist@2.7.570/build/pdf.worker.min.js";
library.add(faChevronUp, faChevronDown, faDownload, faQuestion);

export default {
    name: "EvaluationSettings",
    data: () => {
        return {
            tooltipsActive: [false, false],
            lang: "fr",
            filename: null,
            fileError: false,
            isHover: false,
            attempt: 0,
            questionsValidated: [],
            questionsAdded: [], // Questions ajoutées (pour comparer la liste générer et les questions formatées => permet de ne pas ajouter la même question)
            promises: [],
            messages: [],
            textTooLong: false,
            moderationInProgress: false,
        };
    },
    components: {
        Logo,
        Header,
        FontAwesomeIcon,
        SpinnerButton,
    },
    computed: {
        // Evaluation
        evaluation: function () {
            return this.$store.state.app.evaluation;
        },
        generateQuestionsDisabled: function () {
            return !this.evaluation.textSource || this.numberOfQuestions === 0 || this.$store.state.app.locked || this.moderationInProgress;
        },
    },
    watch: {
        "evaluation.textSource": {
            handler() {
                this.setQuestionsNumberMax();
            },
        },
    },
    beforeMount() {
        if (this.$store.state.app.newQuestions) {
            this.attempt = 0;
            this.generateQuestions();
            this.$store.dispatch("app/setNewQuestions", false);
        }
    },
    created() {
        let _this = this;
        window.addEventListener("resize", () => {
            _this.tooltipsActive = [false, false];
        });
    },
    unmounted() {
        let _this = this;
        window.addEventListener("resize", () => {
            _this.tooltipsActive = [false, false];
        });
    },
    methods: {
        ...mapActions({
            setEvaluation: "app/setEvaluation",
            setLoaderGame: "app/setLoaderGame",
        }),
        /**
         * Nombre de questions maximum
         */
        setQuestionsNumberMax() {
            if (!this.evaluation.textSource) {
                this.evaluation.numberOfQuestionsMax = 0;
                this.evaluation.numberOfQuestions = 0;
                return;
            }

            this.textTooLong = false;
            if (this.evaluation.textSource.trim().split(/\s+/).length > 50000) {
                this.textTooLong = true;
                this.removeFile();
                return;
            }

            let numberOfQuestionsMax = Math.round(this.evaluation.textSource.trim().split(/\s+/).length / 15);
            numberOfQuestionsMax = numberOfQuestionsMax > 100 ? 100 : numberOfQuestionsMax;
            this.evaluation.numberOfQuestionsMax = numberOfQuestionsMax < 1 ? 1 : numberOfQuestionsMax;
            if (this.evaluation.numberOfQuestions > this.evaluation.numberOfQuestionsMax) this.evaluation.numberOfQuestions = this.evaluation.numberOfQuestionsMax;
            if (this.evaluation.numberOfQuestions === 0 && this.evaluation.numberOfQuestionsMax > 0) {
                this.evaluation.numberOfQuestions = this.evaluation.numberOfQuestionsMax > 10 ? 10 : this.evaluation.numberOfQuestionsMax;
            }
        },
        /**
         * Gestion du champs input file
         */
        onFileChanged() {
            if (this.$refs.file.files.length === 0) return;

            this.setEvaluation({ textSource: null });
            let extensionsAllowed = ["pdf", "docx", "txt", "rtf", "odt"];

            let file = this.$refs.file.files[0];
            this.filename = file.name;
            let ext = this.filename.split(".").pop().toLowerCase();

            this.fileError = extensionsAllowed.indexOf(ext) === -1 ? "Ce type de fichier n'est pas accepté" : false;

            if (ext === "odt") this.getTextFromOdt(this.$refs.file.files[0]);
            if (ext === "pdf") this.getTextFromPdf(URL.createObjectURL(this.$refs.file.files[0]));
            if (ext === "docx") this.getTextFromDocx(this.$refs.file.files[0]);
            if (ext === "txt") this.getTextFromPlainText(this.$refs.file.files[0]);
            if (ext === "rtf") this.getTextFromRtf(this.$refs.file.files[0]);
        },
        /**
         * Suppression du fichier
         */
        removeFile() {
            this.$refs.file.value = "";
            this.filename = null;
            this.setEvaluation({ textSource: null, isFile: this.filename });
        },

        /**
         * Extrait le texte d'un fichier ODT
         */
        getTextFromOdt(file) {
            if (!file) return;

            let _this = this;
            const reader = new FileReader();
            reader.onload = (event) => {
                var zip = new JSZip();
                zip.loadAsync(event.target.result).then(function (zipFile) {
                    zipFile
                        .file("content.xml")
                        .async("string")
                        .then(function (content) {
                            var parser = new DOMParser();
                            var xmlDoc = parser.parseFromString(content, "application/xml");

                            var paragraphs = xmlDoc.getElementsByTagName("text:p");
                            var plainText = "";

                            for (var i = 0; i < paragraphs.length; i++) plainText += paragraphs[i].textContent + "\n";

                            _this.setEvaluation({ textSource: plainText, isFile: _this.filename });
                        });
                });
            };
            reader.onerror = (e) => {
                console.error(e);
            };
            reader.readAsArrayBuffer(file);
        },
        /**
         * Extrait le texte d'un fichier RTF
         */
        getTextFromRtf(file) {
            if (!file) return;

            let _this = this;
            const reader = new FileReader();
            reader.onload = () => {
                var plainText = rtfToTxt(reader.result);
                _this.setEvaluation({ textSource: plainText, isFile: _this.filename });
            };
            reader.onerror = (e) => {
                console.error(e);
            };
            reader.readAsText(file);
        },
        /**
         * Extrait le texte d'un fichier TEXT
         */
        getTextFromPlainText(file) {
            if (!file) return;

            let _this = this;
            const reader = new FileReader();
            reader.onload = () => {
                _this.setEvaluation({ textSource: reader.result, isFile: _this.filename });
            };
            reader.onerror = (e) => {
                console.error(e);
            };
            reader.readAsText(file);
        },
        /**
         * Extrait le texte d'un fichier PDF
         * https://www.npmjs.com/package/pdfjs-dist
         */
        getTextFromPdf(fileURL) {
            if (!fileURL) return;

            let _this = this;
            if (!fileURL) return;
            let loadingTask = pdfjsLib.getDocument(fileURL);
            loadingTask.promise.then(function (pdf) {
                let maxPages = pdf.numPages;
                let countPromises = [];
                for (let j = 1; j <= maxPages; j++) {
                    let page = pdf.getPage(j);
                    countPromises.push(
                        page.then(function (page) {
                            let textContent = page.getTextContent();
                            return textContent.then(function (text) {
                                return text.items
                                    .map(function (s) {
                                        return s.str;
                                    })
                                    .join("");
                            });
                        })
                    );
                }
                Promise.all(countPromises).then(function (texts) {
                    _this.setEvaluation({ textSource: texts.join(""), isFile: _this.filename });
                });
            });
        },
        /**
         * Extrait le texte d'un fichier DOCX
         */
        getTextFromDocx(file) {
            if (!file) return;

            let _this = this;
            var reader = new FileReader();
            reader.onload = function (event) {
                var arrayBuffer = event.target.result;
                mammoth
                    .extractRawText({ arrayBuffer: arrayBuffer })
                    .then(function (result) {
                        let text = result.value;
                        _this.this.setEvaluation({ textSource: text, isFile: _this.filename });
                    })
                    .catch(function (err) {
                        console.log(err);
                    });
            };

            reader.readAsArrayBuffer(file);
        },
        /**
         * Genère les questions
         */
        generateQuestions(loop = true, error = false) {

            /* ==================================================
            = LOCAL
            ================================================== */
            if (this.$store.state.app.api_platform_url.indexOf("localhost") !== -1) {
                // Affichage du loader (mini jeu)
                this.setLoaderGame(true);
                // Sauvegarde des paramètres de l'évaluation
                this.saveEvaluationParams();
                // Mise en place du prompt
                this.setPrompt();
                // Mise en place des questions
                this.getQuestions(loop, error);
                // Mise à zéro de l'évaluation
                this.updateAnswersUser();
                // Suppression statut modération en cours
                this.moderationInProgress = false;
            }

            /* ==================================================
            = PROD
            ================================================== */
            if (this.$store.state.app.api_platform_url.indexOf("localhost") === -1) {
                // Modération du texte avant envoie du prompt à CHAT GPT
                this.runModeration().then((validContent) => {
                    if (validContent) {
                        window.scrollTo(0, 0);

                        // Affichage du loader (mini jeu)
                        this.setLoaderGame(true);
                        // Sauvegarde des paramètres de l'évaluation
                        this.saveEvaluationParams();
                        // Mise en place du prompt
                        this.setPrompt();
                        // Mise en place des questions
                        this.getQuestions(loop, error);
                        // Mise à zéro de l'évaluation
                        this.updateAnswersUser();
                        // Suppression statut modération en cours
                        this.moderationInProgress = false;
                    } else {
                        // Suppression du fichier et du texte en place
                        this.removeFile();
                        // Suppression statut modération en cours
                        this.moderationInProgress = false;
                    }
                });
            }
        },
        /**
         * Controle du texte contre le contenu haineux, discriminatoires... etc
         */
        runModeration() {
            let _this = this;

            // Ajout du statut modération en cours
            this.moderationInProgress = true;

            return new Promise((resolve) => {
                let promises = [];
                let textSource = this.evaluation.textSource;

                // Longueur du texte
                let lengthText = textSource.length;

                // Longueur du texte en Bytes (PERSPECTIVE API accepte un maximum de 20400)
                const textEncoder = new TextEncoder();
                let lengthTextInBytes = textEncoder.encode(textSource).length;

                // Calcul du nombre de partie du texte
                let numberOfTexts = Math.ceil(lengthTextInBytes / 20400);

                // Longueur de chaque partie
                let lengthPart = Math.floor(lengthText / numberOfTexts);

                let idxText = 0;
                for (let i = 0; i < numberOfTexts; i++) {
                    let text = textSource.slice(idxText, lengthPart);
                    idxText = idxText + lengthPart;
                    lengthPart += lengthPart;

                    promises.push(this.runPerspectibeAPI(text));
                }

                Promise.all(promises).then((responses) => {
                    let validContent = true;

                    for (let j = 0; j < responses.length; j++) {
                        if (responses[j] && responses[j].data && "attributeScores" in responses[j].data) {
                            validContent = false;
                            _this.$store.dispatch("app/setModeration", { modal: true });
                        }
                        if (j === responses.length - 1) resolve(validContent);
                    }
                });
            });
        },
        /**
         * Axios PERSPECTIVE API
         */
        runPerspectibeAPI(text) {
            return new Promise((resolve) => {
                axios({
                    method: "post",
                    proxy: true,
                    url: "./perspective_api.php",
                    data: {
                        comment: { text: text },
                        languages: [this.evaluation.lang],
                        requestedAttributes: {
                            TOXICITY: {
                                scoreType: "PROBABILITY",
                                scoreThreshold: 0.5,
                            },
                        },
                    },
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        "Access-Control-Allow-Origin": "*",
                    },
                }).then(
                    (response) => {
                        resolve(response);
                    },
                    (error) => {
                        resolve(error);
                    }
                );
            });
        },
        /**
         * Mise à zéro de l'évaluation
         */
        updateAnswersUser() {
            // Remise à zéro des réponses utilisateurs
            this.$store.dispatch("app/resetUserReponses");
        },
        /**
         * Sauvegarde des paramètres de l'évaluation
         */
        saveEvaluationParams() {
            this.setEvaluation({
                isGenerated: true,
                correction: this.evaluation.correction,
                scoreToAchieve: this.evaluation.scoreToAchieve,
                mode: this.evaluation.mode,
                lang: this.evaluation.lang,
                questionsType: this.evaluation.questionsType,
                numberOfQuestions: this.evaluation.numberOfQuestions,
            });
        },
        /**
         * Mise en place du prompt
         */
        setPrompt() {
            let prompt = this.$store.getters["app/getPrompt"];
            this.messages.push(prompt);
        },
        /**
         * Mise en place des questions
         */
        getQuestions(loop = true, error = false) {
            let _this = this;

            /* ==================================================
            = LOCAL
            ================================================== */
            if (this.$store.state.app.api_platform_url.indexOf("localhost") !== -1) {
                let questions = [
                    {
                        question: "Quel est le métier du père de Victor Hugo ?",
                        type: "QCU",
                        propositions: ["Professeur", "Général du premier Empire", "Écrivain", "Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore"],
                        answers: ["Général du premier Empire"],
                        info: "Le père de Victor Hugo était un général du premier Empire.",
                    },
                    {
                        question: "En quelle année Victor Hugo publie-t-il son recueil de poèmes 'Odes' ?",
                        type: "QCU",
                        propositions: ["1820", "1821", "1822", "1823"],
                        answers: ["1820"],
                        info: "Victor Hugo publie le recueil 'Odes' en 1822.",
                    },
                ];

                // Mise à jour des questions
                this.setEvaluation({ questions: questions });

                // On passe à la page suivante (évaluation)
                this.$store.dispatch("app/setPage", { current: "EVALUATION" });

                // On masque le loader (mini jeu)
                this.setLoaderGame(false);
            }

            /* ==================================================
            = PROD
            ================================================== */
            if (this.$store.state.app.api_platform_url.indexOf("localhost") === -1) {
                if (loop) {
                    for (let i = 0; i < Math.ceil(_this.evaluation.numberOfQuestions / 10); i++) {
                        _this.promises.push(_this.runOpenAPI());
                    }
                } else _this.promises.push(_this.runOpenAPI());

                Promise.all(_this.promises).then((responses) => {
                    for (let j = 0; j < responses.length; j++) {
                        let response = responses[j];

                        if (error) _this.attempt++;

                        // Si plus de 3 tentatives de recherche (api chatGPT), on arrete le script
                        // et on masque le loader (mini jeu)
                        if (_this.attempt >= 3) {
                            this.setLoaderGame(false);
                            // On affiche la modale d'erreur
                            this.$store.dispatch("app/setError", { modal: true });
                            return;
                        }

                        // Si pas de message, la recherche est relancée
                        if (!response.data.message || !response.data.message.content) {
                            _this.generateQuestions(false, true);
                            return;
                        }
                        let questionsObj = JSON.parse(response.data.message.content);
                        // Si pas de question sous forme de JSON, la recherche est relancée
                        if (!questionsObj) {
                            _this.generateQuestions(false, true);
                            return;
                        }
                        // Si pas de clé "questions" dans questionsObj, la recherche est relancée
                        if (!("questions" in questionsObj)) {
                            _this.generateQuestions(false, true);
                            return;
                        }
                        let questions = questionsObj.questions;
                        // Si "questions" n'est pas un Array, la recherche est relancée
                        if (Object.prototype.toString.call(questions) !== "[object Array]") {
                            _this.generateQuestions(false, true);
                            return;
                        }

                        // On formate le tableau (au cas où le tableau renvoyé par ChatGPT ne serait pas correct)
                        let k = 0;
                        for (const q of questions) {
                            if (_this.questionsAdded.indexOf(q.question) === -1) {
                                // On formate les bonnes réponses
                                if ("answers" in q) q.answers = typeof q.answers === "string" || q.answers instanceof String ? [q.answers] : q.answers;
                                if ("answer" in q) {
                                    q.answers = typeof q.answer === "string" || q.answer instanceof String ? [q.answer] : q.answer;
                                    delete q.answer;
                                }
                                // On formate les propositions
                                if ("proposition" in q) q.propositions = q.proposition;
                                // Si le type n'est ni QCU ni QCM, on ne garde pas la question
                                if (["QCU", "QCM"].indexOf(q.type.toUpperCase()) === -1) continue;
                                // Si il n'y a pas au moins une proposition et une bonne réponse en commun, on ne garde pas la question
                                if (q.answers.filter((value) => q.propositions.includes(value)).length === 0) continue;

                                if (_this.questionsValidated.length < _this.evaluation.numberOfQuestions) {
                                    // On ajoute la question valide au tableau "questionsValidated"
                                    _this.questionsValidated.push(q);
                                    // On ajoute l'intitulé de la question au tableau "questionsAdded" pour comparer la liste générer et les questions formatées => permet de ne pas ajouter la même question
                                    _this.questionsAdded.push(q.question);
                                }
                            }

                            k++;
                            if (j === responses.length - 1 && k === questions.length) {
                                if (_this.questionsValidated.length < _this.evaluation.numberOfQuestions) {
                                    _this.generateQuestions(false);
                                } else {
                                    // Mise à jour des questions
                                    this.setEvaluation({ questions: _this.questionsValidated });

                                    // On passe à la page suivante (évaluation)
                                    _this.$store.dispatch("app/setPage", { current: "EVALUATION" });

                                    // On masque le loader (mini jeu)
                                    this.setLoaderGame(false);
                                }
                            }
                        }
                    }
                });
            }
        },
        /**
         * Axios OPEN API
         */
        runOpenAPI() {
            let _this = this;

            return new Promise((resolve) => {
                axios({
                    method: "post",
                    proxy: true,
                    url: "./openapi.php",
                    data: {
                        messages: _this.messages,
                    },
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        "Access-Control-Allow-Origin": "*",
                    },
                }).then(
                    (response) => {
                        resolve(response);
                    },
                    (error) => {
                        resolve(error);

                        // On masque le loader (mini jeu)
                        _this.setLoaderGame(false);
                    }
                );
            });
        },
    },
};
</script>

<style scoped lang="scss">
#evaluation-settings {
    .banner-top {
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;

        @media (max-width: $xl) {
            flex-flow: column;
        }

        > div {
            &:first-child {
                box-sizing: border-box;
                padding: 1rem 2rem;
                display: flex;
                flex-flow: column;
                align-items: flex-start;
                justify-content: flex-start;

                @media (max-width: $lg) {
                    padding: 1rem;
                }

                .logo-svg {
                    max-width: 300px;
                    width: 100%;

                    @media (max-width: $xl) {
                        max-width: 250px;
                    }
                    @media (max-width: $lg) {
                        max-width: 200px;
                    }
                }

                p {
                    margin: 3rem 0 0;
                    max-width: 600px;
                    font-size: 1.4rem;

                    @media (max-width: $xxl) {
                        font-size: 1.2rem;
                    }
                    @media (max-width: $xl) {
                        margin: 1rem 0 0;
                        max-width: 100%;
                    }
                    @media (max-width: $lg) {
                        font-size: 1rem;
                    }
                }
            }

            &:last-child {
                margin: 2rem 2rem 0 2rem;
                position: relative;
                flex: 1;

                img {
                    max-height: 100%;
                    display: block;
                    position: absolute;
                    top: 0;
                    right: 0;
                }

                @media (max-width: $xl) {
                    margin: 0;
                    padding: 0 2rem 0;
                    box-sizing: border-box;

                    img {
                        max-height: 200px;
                        position: static;
                    }
                }

                @media (max-width: $lg) {
                    padding: 0 1rem 0;

                    img {
                        max-height: 150px;
                    }
                }

                @media (max-width: $sm) {
                    img {
                        max-height: 100px;
                    }
                }
            }
        }
    }

    > div {
        > div {
            &.settings {
                .flex-column {
                    display: flex;
                    flex-flow: column;

                    > div {
                        display: flex;
                        flex-flow: row;
                        align-items: center;

                        &:first-child {
                            margin-bottom: 1rem;
                        }
                    }
                }

                > div {
                    padding: 1.5rem 2rem;
                    box-sizing: border-box;
                    border-bottom: 1px solid #c6d5e3;
                    position: relative;
                    display: flex;
                    align-items: center;

                    @media (max-width: $lg) {
                        padding: 1rem;
                        flex-flow: row wrap;
                    }

                    .percent {
                        display: flex;
                        align-items: center;
                        margin-left: 0.5rem;
                    }

                    &.no-border {
                        border-bottom: 0;
                    }

                    &:not(.no-border):after {
                        content: "";
                        display: block;
                        border-bottom: 3px solid $base-color;
                        position: absolute;
                        bottom: -1px;
                        width: 10%;
                        left: 0;

                        @media (max-width: $lg) {
                            border-bottom: 2px solid $base-color;
                        }
                    }

                    &:last-child {
                        border: 0;

                        &:after {
                            content: none;
                        }
                    }

                    &.mode {
                        > div {
                            display: flex;
                            flex-flow: row nowrap;
                            align-items: center;

                            > div {
                                display: flex;
                                align-items: center;
                            }

                            &:last-of-type {
                                > div {
                                    align-items: stretch;
                                }
                            }

                            &:first-of-type {
                                > div {
                                    align-items: center;

                                    &:first-child {
                                        margin-right: 1rem;
                                    }

                                    &:last-child {
                                        margin-right: 4rem;
                                    }
                                }
                            }
                        }

                        @media (max-width: $xxl) {
                            flex-flow: row wrap;

                            .score {
                                margin-top: 1rem;
                                width: 100%;
                            }
                        }
                        @media (max-width: $md) {
                            .score {
                                flex-flow: row wrap;
                            }
                        }
                        @media (max-width: $sm) {
                            > div:not(.score) {
                                flex-flow: row wrap;
                                width: 100%;

                                &:first-of-type {
                                    > div {
                                        width: 100%;
                                        justify-content: space-between;

                                        &:first-child,
                                        &:last-child {
                                            margin-right: 0;
                                        }

                                        label {
                                            margin-right: 0.5rem;
                                        }
                                    }
                                }
                            }
                        }
                    }

                    &.title-part {
                        border-top: 3px solid $base-color;
                        color: #ff6b46;
                        text-transform: uppercase;
                        font-size: 1.4rem;
                        font-weight: 800;
                        padding: 2rem;

                        @media (max-width: $xxl) {
                            font-size: 1.2rem;
                        }
                        @media (max-width: $lg) {
                            border-top: 2px solid $base-color;
                            font-size: 1rem;
                            font-weight: 600;
                            padding: 1.5rem 1rem;
                        }
                    }

                    &.error {
                        color: red;
                        justify-content: center;
                        text-align: center;
                        font-weight: 600;
                        padding: 1.5rem 1rem 0;
                    }
                }

                .title {
                    text-transform: uppercase;
                    font-weight: 500;
                    width: 250px;
                    font-size: 1rem;

                    @media (max-width: $xl) {
                        width: 220px;
                    }
                    @media (max-width: $lg) {
                        font-weight: 400;
                        width: 100%;
                        margin-bottom: 1rem;
                    }
                }

                .radio {
                    margin-right: 0.5rem;

                    @media (max-width: $sm) {
                        margin-right: 0;
                        margin-bottom: 0.4rem;
                    }

                    &:last-child {
                        margin-right: 0;

                        @media (max-width: $sm) {
                            margin-bottom: 0;
                        }
                    }
                }
            }

            // File
            .file {
                flex: 0 0 40%;
                display: flex;
                flex-flow: column;
                padding: 2rem 2rem 0 0;
                box-sizing: border-box;

                textarea {
                    width: 100%;
                    height: 250px;
                    box-sizing: border-box;
                    padding: 1rem;
                    border: 1px solid #c6d5e3;
                    background-color: transparent;
                    color: #101415;
                    margin-top: 1rem;
                    resize: none;
                    outline: none;
                    font-size: 1rem;

                    &::placeholder {
                        text-align: center;
                        line-height: 200px;
                        font-weight: 600;
                        font-family: "Jost", sans-serif;
                        color: #101415;
                    }

                    &:focus,
                    &:active {
                        border: 1px solid $base-color;
                    }

                    &:disabled {
                        opacity: 0.3;

                        &:focus,
                        &:active {
                            border: 1px solid #c6d5e3;
                        }
                    }
                }

                #dropzone {
                    box-sizing: border-box;
                    text-align: center;
                    padding: 70px 100px;
                    border: 1px solid #c6d5e3;
                    background-color: transparent;
                    cursor: pointer;
                    position: relative;
                    width: 100%;

                    &.hover {
                        color: $base-color;
                        border-color: $base-color;
                    }

                    div {
                        position: absolute;
                        top: 0;
                        right: 0;
                        bottom: 0;
                        left: 0;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        font-weight: 600;
                        flex-flow: column;

                        svg {
                            margin-bottom: 10px;
                            color: #c6d5e3;
                            font-size: 2rem;
                        }

                        .info {
                            margin-top: 15px;
                            text-transform: uppercase;
                        }

                        span#filename {
                            font-weight: 300;
                            height: 20px;
                            display: inline-block;
                            margin-bottom: 0.5rem;
                        }

                        > div {
                            display: flex;
                            flex-flow: row nowrap;

                            #remove-file {
                                position: relative;
                                z-index: 1;

                                svg {
                                    color: red;
                                    margin: 0 0 0 0.2rem;
                                }
                            }
                        }
                    }

                    > input[type="file"] {
                        cursor: pointer;
                        position: absolute;
                        opacity: 0;
                        top: 0;
                        right: 0;
                        bottom: 0;
                        left: 0;
                    }
                }

                > .info {
                    font-style: italic;
                    margin: 0;
                    text-align: center;

                    @media (max-width: $sm) {
                        text-align: left;
                    }

                    &.texttoolong {
                        color: red;
                    }
                }
            }
        }

        .btns {
            margin-top: 2rem;
            display: flex;
            flex-flow: column;
            justify-content: center;

            button {
                padding: 0.5rem 6rem;

                > span {
                    display: flex;
                    flex-flow: row nowrap;
                    justify-content: center;
                    align-items: center;
                    position: relative;

                    svg {
                        position: absolute;
                        left: -30px;
                    }
                }

                @media (max-width: $sm) {
                    padding: 0.5rem 0;
                    width: 100%;
                }
            }
        }
    }

    .tooltip {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #c9c9c9;
        border-radius: 50px;
        height: 20px;
        width: 20px;
        cursor: default;
        font-size: 0.8rem;

        @media (max-width: $md) {
            cursor: pointer;
        }

        path {
            fill: #fff;
        }

        .tooltiptext {
            visibility: hidden;
            max-width: 250px;
            width: 250px;
            padding: 0.5rem;
            box-sizing: border-box;
            font-size: 0.9rem;
            background-color: #000;
            color: #fff;
            text-align: center;
            border-radius: 6px;
            position: absolute;
            left: 36px;
            z-index: 1;
            opacity: 0;
            transition: all 0.2s;

            @media (max-width: $md) {
                display: none;
                padding: 1.5rem 0.5rem;
            }

            > span {
                position: absolute;
                top: 0.5rem;
                right: 0.5rem;
                display: none;
                cursor: pointer;

                @media (max-width: $md) {
                    display: inline-block;
                }
            }

            &:before {
                content: "";
                display: block;
                width: 0px;
                height: 0px;
                border-style: solid;
                border-width: 9px 18px 9px 0;
                border-color: transparent #000 transparent transparent;
                transform: rotate(0deg);
                position: absolute;
                left: -14px;
                top: calc(50% - 9px);

                @media (max-width: $md) {
                    left: calc(50% - 9px);
                    top: auto;
                    bottom: -14px;
                    transform: rotate(-90deg);
                }
                @media (max-width: $sm) {
                    right: -14px;
                    left: auto;
                    bottom: auto;
                    top: calc(50% - 9px);
                    transform: rotate(-180deg);
                }
            }

            @media (max-width: $xl) {
                max-width: 220px;
            }
            @media (max-width: $lg) {
                max-width: 200px;
            }
            @media (max-width: $md) {
                left: -91px;
                bottom: 40px;
            }
            @media (max-width: $sm) {
                left: -218px;
                bottom: auto;
            }
        }

        &:hover .tooltiptext {
            visibility: visible;
            opacity: 1;
        }

        @media (max-width: $md) {
            &.active .tooltiptext {
                visibility: visible;
                opacity: 1;
                display: block;
            }
        }
    }
}
</style>
