import router from "./../router";

let env_local = false;

export default {
    namespaced: true,
    state: {
        base_url: env_local ? "http://localhost:8080/#" : "https://quaistion.com/#", // http://localhost:8080/# ou https://livraisons.haikara.tv/quiz_autonome/test_apprenant/#
        api_platform_url: env_local ? "http://localhost:8000" : "https://www.api.quaistion.com/public", // http://localhost:8000 ou https://livraisons.haikara.tv/quiz_autonome/api_platform/public
        userScore: 0, // Score utilisateur

        lang: "fr",

        page: {
            current: "EVALUATION_SETTINGS",
            prev: "EVALUATION_SETTINGS",
        },

        loaderGame: false, // Loader mini jeux
        overflow: true, // status de la propriété overflow sur l'élémement #frame
        spinner: true,

        locked: false, // Bloquer l'application

        user: {}, // User

        evaluation: {
            isFile: false, // Si un fichier est selectionné comme source
            isGenerated: false, // true : évaluation générée - false : partage de quiz
            correction: "now",
            scoreToAchieve: 70,
            scoreToAchieveMax: 100,
            mode: "training", // training - validation
            lang: "fr",
            questionsType: "QCU et QCM",
            numberOfQuestionsMax: 0,
            numberOfQuestions: 0,
            textSource: "",
            questions: null,
            user: null,
        },

        newQuestions: false, // Si passage à true, recherche de nouvelles questions à partir du même texte
        idxQuestion: 0, // Index de la question en cours
        questionValidated: false, // Si passage à true, la question est validée (seulement dans le cas de la correction après chaque question)

        userResponses: [], // Réponses de l'utilisateur à l'évaluation

        abandon: {
            modal: false,
            status: false,
        },
        shareURL: {
            modal: false,
            url: null,
        },
        caution: {
            modal: false,
        },
        moderation: {
            modal: false,
        },
        notFound: {
            modal: false,
        },
        error: {
            modal: false,
        },
    },
    mutations: {
        SET_QUESTION_VALIDATED(state, payload) {
            state.questionValidated = payload;
        },
        SET_PAGE(state, payload) {
            if (payload && payload.savePrev !== false) state.page.prev = state.page.current;
            state.page.current = payload.current;
            window.scrollTo(0, 0);
        },
        SET_IDX_QUESTION(state, payload) {
            state.idxQuestion = payload;
        },
        SET_USER_RESPONSES(state, payload) {
            state.userResponses = payload;
        },
        SET_EVALUATION(state, payload) {
            state.evaluation = payload;
            if (state.evaluation.questions) state.evaluation.numberOfQuestions = state.evaluation.questions.length;
        },
        SET_LOADER_GAME(state, payload) {
            state.loaderGame = payload;
        },
        SET_USER(state, payload) {
            state.user = payload;
        },
        SET_SHARE_URL(state, payload) {
            state.shareURL = payload;
        },
        SET_ABANDON(state, payload) {
            state.abandon = payload;
        },
        SET_CAUTION(state, payload) {
            state.caution = payload;
        },
        SET_MODERATION(state, payload) {
            state.moderation = payload;
        },
        SET_NOT_FOUND(state, payload) {
            state.notFound = payload;
        },
        SET_ERROR(state, payload) {
            state.error = payload;
        },
        SET_OVERFLOW(state, payload) {
            state.overflow = payload;
        },
        SET_SPINNER(state, payload) {
            state.spinner = payload;
        },
        SET_USER_SCORE(state, payload) {
            state.userScore = payload;
        },
        SET_LOCKED(state, payload) {
            state.locked = payload;
        },
        SET_NEW_QUESTIONS(state, payload) {
            state.newQuestions = payload;
        },
        SET_LANG(state, payload) {
            state.lang = payload;
        },
    },
    actions: {
        /**
         * Changement de page
         */
        setPage(context, payload) {
            context.commit("SET_PAGE", payload);
        },
        /**
         * Mise à jour de l'index question
         */
        setIdxQuestion(context, payload) {
            if (payload < 0 || payload >= context.state.evaluation.numberOfQuestions || !context.state.evaluation.questions) return;
            // Mélange des propositions
            for (let i = 0; i < context.state.evaluation.questions.length; i++) {
                let question = context.state.evaluation.questions[i];
                if (question && question.propositions !== undefined && question.propositions.length > 0) {
                    let currentIndex = question.propositions.length;
                    while (currentIndex != 0) {
                        let randomIndex = Math.floor(Math.random() * currentIndex);
                        currentIndex--;
                        [question.propositions[currentIndex], question.propositions[randomIndex]] = [question.propositions[randomIndex], question.propositions[currentIndex]];
                    }
                }
            }
            context.commit("SET_IDX_QUESTION", payload);
        },
        /**
         * Mise à jour des réponses données au questionnaire
         * Et mise à jour du score en pourcentage de l'utilisateur
         */
        setUserResponses(context, payload) {
            context.commit("SET_USER_RESPONSES", payload);
            // Calcul du score
            if (!context.state.evaluation.questions) return;
            let totalCorrectAnswers = 0; // Total bonne réponse utilisateur
            for (let i = 0; i < context.state.evaluation.questions.length; i++) {
                let question = context.state.evaluation.questions[i];
                if (context.state.userResponses[i] !== undefined && context.state.userResponses[i].responses !== undefined) {
                    context.state.userResponses[i].status = false;
                    if (context.state.userResponses[i].responses.filter((el) => question.answers.includes(el)).length === question.answers.length && question.answers.length === context.state.userResponses[i].responses.length) {
                        totalCorrectAnswers++;
                        context.state.userResponses[i].status = true;
                    }
                }
                if (i === context.state.evaluation.questions.length - 1) {
                    context.commit("SET_USER_SCORE", {
                        totalCorrectAnswers: totalCorrectAnswers,
                        percent: Math.round((totalCorrectAnswers * 100) / context.state.evaluation.questions.length),
                    });
                }
            }
        },
        /**
         * Correction après chaque question ou en fin d'évaluation
         */
        setCorrection(context, payload) {
            context.commit("SET_CORRECTION", payload);
            // Cas ou la correction est àla fin
            if (payload === "end") context.commit("SET_VALIDATE_QUESTION", true);
        },
        /**
         * Validation d'une question (seulement dans le cas de la correction après chaque question)
         */
        setQuestionValidated(context, payload) {
            context.commit("SET_QUESTION_VALIDATED", payload);
        },
        /**
         * Paramètres de l'évaluation
         */
        setEvaluation(context, payload) {
            context.commit("SET_EVALUATION", { ...context.state.evaluation, ...payload });
        },
        /**
         * Affichage du loader (mini jeu)
         */
        setLoaderGame(context, payload) {
            context.commit("SET_OVERFLOW", payload);
            context.commit("SET_LOADER_GAME", payload);
        },
        /**
         * Utilisateur en cours
         */
        setUser(context, payload) {
            context.commit("SET_USER", payload);
            context.commit("SET_EVALUATION", { ...context.state.evaluation, user: payload });
        },
        /**
         * Modal abandon
         */
        setAbandon(context, payload) {
            context.commit("SET_OVERFLOW", payload && payload.modal);
            if (context.state.userResponses[context.state.idxQuestion] !== undefined && !context.state.questionValidated) {
                context.state.userResponses.splice(context.state.idxQuestion, 1);
                context.dispatch("setUserResponses", context.state.userResponses);
            }
            context.commit("SET_ABANDON", { ...context.state.modalAbandon, ...payload });
        },
        /**
         * Modal lien partage de quiz
         */
        setShareURL(context, payload) {
            context.commit("SET_OVERFLOW", payload && payload.modal);
            context.commit("SET_SHARE_URL", { ...context.state.shareURL, ...payload });
        },
        /**
         * Modal avertissement
         */
        setCaution(context, payload) {
            context.commit("SET_OVERFLOW", payload && payload.modal);
            context.commit("SET_CAUTION", { ...context.state.caution, ...payload });
        },
        /**
         * Modal de modération
         */
        setModeration(context, payload) {
            context.commit("SET_OVERFLOW", payload && payload.modal);
            context.commit("SET_MODERATION", { ...context.state.moderation, ...payload });
        },
        /**
         * Modal évaluation introuvable
         */
        setNotFound(context, payload) {
            context.commit("SET_OVERFLOW", payload && payload.modal);
            context.commit("SET_NOT_FOUND", { ...context.state.notFound, ...payload });
        },
        /**
         * Modal error AI
         */
        setError(context, payload) {
            context.commit("SET_OVERFLOW", payload && payload.modal);
            context.commit("SET_ERROR", { ...context.state.error, ...payload });
        },
        /**
         * Activer le spinner (loading)
         */
        setSpinner(context, payload) {
            context.commit("SET_SPINNER", payload);
            context.commit("SET_OVERFLOW", payload);
        },
        /**
         * Bloquer l'application
         */
        setLocked(context, payload) {
            context.commit("SET_LOCKED", payload);
        },
        /**
         * Chargement de nouvelles questions à partir du même texte
         */
        setNewQuestions(context, payload) {
            context.commit("SET_NEW_QUESTIONS", payload);
        },
        /**
         * Mise à jour de la langue
         */
        setLang(context, payload) {
            context.commit("SET_LANG", payload);
        },
        /**
         * Chargement de nouvelles questions à partir du même texte
         */
        resetApp(context) {
            router.push({ name: "SettingsView" });

            context.dispatch("resetUserReponses");
            context.dispatch("setAbandon", { modal: false });
            context.dispatch("setPage", { current: "EVALUATION_SETTINGS" });

            context.dispatch("setEvaluation", {
                isFile: false,
                isGenerated: false,
                correction: "now",
                scoreToAchieve: 70,
                scoreToAchieveMax: 100,
                mode: "training",
                lang: context.state.lang,
                questionsType: "QCU et QCM",
                numberOfQuestionsMax: 0,
                numberOfQuestions: 0,
                textSource: "",
                questions: null,
                user: null,
            });
        },
        /**
         * Remise à zéro des réponses utlisateurs
         */
        resetUserReponses(context) {
            context.dispatch("setUserResponses", []);
            context.dispatch("setQuestionValidated", false);
            context.dispatch("setIdxQuestion", 0);
        },
    },
    getters: {
        getPrompt(state) {
            let lang = "français";
            switch (state.evaluation.lang) {
                case "en":
                    lang = "anglais";
                    break;
                case "es":
                    lang = "espagnol";
                    break;
            }

            let questionsType = state.evaluation.numberOfQuestions === 1 && state.evaluation.questionsType === "QCU et QCM" ? "QCU ou QCM" : state.evaluation.questionsType;

            let prompt = {
                role: "system",
                content: `Les questions de type QCU sont des question à choix unique, et ne devront comporter qu'une seule bonne réponse.
                        Les questions de type QCM sont des questions à choix multiple et devront comporter une ou plusieurs bonnes réponses.
                        Rédiger ${state.evaluation.numberOfQuestions > 9 ? "10" : state.evaluation.numberOfQuestions} ${state.evaluation.numberOfQuestions === 1 ? "question" : "questions"} de type ${questionsType}, en ${lang}, comportant un nombre de 2 à 5 propositions de réponse.
                        Ne redige pas de question sur le type de question.
                        Les propositions ne doivent pas faire référence à d'autres propositions.
                        Chaque question générée devra être formatée en object JSON, avec son libellé dans une propriété nommée "question", rappeler son type dans une propriété nommée "type", les propositions dans une propriété nommée "propositions", les réponses correctes dans une propriété nommée "answers", et une propriété "info" qui donnera une indication ou une information complémentaire à la bonne réponse.
                        Un tableau avec une propriété nommée "questions" devra contenir l'ensemble des objects JSON.
                        Toutes vos questions devront tester les connaissances d'un public sur le texte suivant : ${state.evaluation.textSource}`,
            };

            return prompt;
        },
    },
};
